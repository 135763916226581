import { parcelApi as api } from "./base.api";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    zoneGroupsZonesDelete: build.mutation<
      ZoneGroupsZonesDeleteApiResponse,
      ZoneGroupsZonesDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/zone_groups/${queryArg.zoneGroupId}/zones/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    zoneGroupsZonesShow: build.query<
      ZoneGroupsZonesShowApiResponse,
      ZoneGroupsZonesShowApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/zone_groups/${queryArg.zoneGroupId}/zones/${queryArg.id}`,
      }),
    }),
    zoneGroupsZonesUpdate: build.mutation<
      ZoneGroupsZonesUpdateApiResponse,
      ZoneGroupsZonesUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/zone_groups/${queryArg.zoneGroupId}/zones/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    putV1ZoneGroupsByZoneGroupIdZonesAndId: build.mutation<
      PutV1ZoneGroupsByZoneGroupIdZonesAndIdApiResponse,
      PutV1ZoneGroupsByZoneGroupIdZonesAndIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/zone_groups/${queryArg.zoneGroupId}/zones/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    zoneGroupsZonesBulkCreate: build.mutation<
      ZoneGroupsZonesBulkCreateApiResponse,
      ZoneGroupsZonesBulkCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/zone_groups/${queryArg.zoneGroupId}/zones/bulk`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    peakSurchargesIndex: build.query<
      PeakSurchargesIndexApiResponse,
      PeakSurchargesIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/peak_surcharges`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    peakSurchargesCreate: build.mutation<
      PeakSurchargesCreateApiResponse,
      PeakSurchargesCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/peak_surcharges`,
        method: "POST",
        body: queryArg,
      }),
    }),
    extendedAreaSurchargesDelete: build.mutation<
      ExtendedAreaSurchargesDeleteApiResponse,
      ExtendedAreaSurchargesDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/extended_area_surcharges/${queryArg}`,
        method: "DELETE",
      }),
    }),
    extendedAreaSurchargesShow: build.query<
      ExtendedAreaSurchargesShowApiResponse,
      ExtendedAreaSurchargesShowApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/extended_area_surcharges/${queryArg}`,
      }),
    }),
    extendedAreaSurchargesUpdate: build.mutation<
      ExtendedAreaSurchargesUpdateApiResponse,
      ExtendedAreaSurchargesUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/extended_area_surcharges/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    putV1ExtendedAreaSurchargesById: build.mutation<
      PutV1ExtendedAreaSurchargesByIdApiResponse,
      PutV1ExtendedAreaSurchargesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/extended_area_surcharges/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    carrierAccountsIndex: build.query<
      CarrierAccountsIndexApiResponse,
      CarrierAccountsIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/carrier_accounts`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    carrierAccountCreate: build.mutation<
      CarrierAccountCreateApiResponse,
      CarrierAccountCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/carrier_accounts`,
        method: "POST",
        body: queryArg,
      }),
    }),
    shippersAccessorialsIndex: build.query<
      ShippersAccessorialsIndexApiResponse,
      ShippersAccessorialsIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/shipper_accessorials`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    rateCardsRateRecordsDelete: build.mutation<
      RateCardsRateRecordsDeleteApiResponse,
      RateCardsRateRecordsDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/rate_cards/${queryArg.rateCardId}/rate_records/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    rateCardsRateRecordsShow: build.query<
      RateCardsRateRecordsShowApiResponse,
      RateCardsRateRecordsShowApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/rate_cards/${queryArg.rateCardId}/rate_records/${queryArg.id}`,
      }),
    }),
    rateCardsRateRecordsUpdate: build.mutation<
      RateCardsRateRecordsUpdateApiResponse,
      RateCardsRateRecordsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/rate_cards/${queryArg.rateCardId}/rate_records/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    putV1RateCardsByRateCardIdRateRecordsAndId: build.mutation<
      PutV1RateCardsByRateCardIdRateRecordsAndIdApiResponse,
      PutV1RateCardsByRateCardIdRateRecordsAndIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/rate_cards/${queryArg.rateCardId}/rate_records/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    shipperCarrierFulfillmentsDelete: build.mutation<
      ShipperCarrierFulfillmentsDeleteApiResponse,
      ShipperCarrierFulfillmentsDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/shippers/${queryArg.shipperId}/carrier_accounts/${queryArg.carrierAccountId}/fulfillment_centers/${queryArg.fulfillmentCenterId}`,
        method: "DELETE",
      }),
    }),
    shipperCarrierFulfillmentsCreate: build.mutation<
      ShipperCarrierFulfillmentsCreateApiResponse,
      ShipperCarrierFulfillmentsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/shippers/${queryArg.shipperId}/carrier_accounts/${queryArg.carrierAccountId}/fulfillment_centers/${queryArg.fulfillmentCenterId}`,
        method: "POST",
      }),
    }),
    carriersIndex: build.query<CarriersIndexApiResponse, CarriersIndexApiArg>({
      query: (queryArg) => ({
        url: `/v1/carriers`,
        params: {
          sort: queryArg.sort,
          page: queryArg.page,
          page_size: queryArg.pageSize,
          filter: queryArg.filter,
        },
      }),
    }),
    carriersCreate: build.mutation<
      CarriersCreateApiResponse,
      CarriersCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/carriers`,
        method: "POST",
        body: queryArg,
      }),
    }),
    vendorsServiceMethodMappingsDelete: build.mutation<
      VendorsServiceMethodMappingsDeleteApiResponse,
      VendorsServiceMethodMappingsDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/vendors/${queryArg.vendorId}/service_method_mappings/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    vendorsServiceMethodMappingsShow: build.query<
      VendorsServiceMethodMappingsShowApiResponse,
      VendorsServiceMethodMappingsShowApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/vendors/${queryArg.vendorId}/service_method_mappings/${queryArg.id}`,
      }),
    }),
    vendorsServiceMethodMappingsUpdate: build.mutation<
      VendorsServiceMethodMappingsUpdateApiResponse,
      VendorsServiceMethodMappingsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/vendors/${queryArg.vendorId}/service_method_mappings/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    putV1VendorsByVendorIdServiceMethodMappingsAndId: build.mutation<
      PutV1VendorsByVendorIdServiceMethodMappingsAndIdApiResponse,
      PutV1VendorsByVendorIdServiceMethodMappingsAndIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/vendors/${queryArg.vendorId}/service_method_mappings/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    shippersIndex: build.query<ShippersIndexApiResponse, ShippersIndexApiArg>({
      query: (queryArg) => ({
        url: `/v1/shippers`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    shippersCreate: build.mutation<
      ShippersCreateApiResponse,
      ShippersCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/shippers`,
        method: "POST",
        body: queryArg,
      }),
    }),
    rateCardsDelete: build.mutation<
      RateCardsDeleteApiResponse,
      RateCardsDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/rate_cards/${queryArg}`,
        method: "DELETE",
      }),
    }),
    rateCardsShow: build.query<RateCardsShowApiResponse, RateCardsShowApiArg>({
      query: (queryArg) => ({ url: `/v1/rate_cards/${queryArg}` }),
    }),
    rateCardsUpdate: build.mutation<
      RateCardsUpdateApiResponse,
      RateCardsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/rate_cards/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    putV1RateCardsById: build.mutation<
      PutV1RateCardsByIdApiResponse,
      PutV1RateCardsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/rate_cards/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    serviceMethodsIndex: build.query<
      ServiceMethodsIndexApiResponse,
      ServiceMethodsIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/service_methods`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    accessorialsDelete: build.mutation<
      AccessorialsDeleteApiResponse,
      AccessorialsDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/accessorials/${queryArg}`,
        method: "DELETE",
      }),
    }),
    accessorialsShow: build.query<
      AccessorialsShowApiResponse,
      AccessorialsShowApiArg
    >({
      query: (queryArg) => ({ url: `/v1/accessorials/${queryArg}` }),
    }),
    accessorialsUpdate: build.mutation<
      AccessorialsUpdateApiResponse,
      AccessorialsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/accessorials/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    putV1AccessorialsById: build.mutation<
      PutV1AccessorialsByIdApiResponse,
      PutV1AccessorialsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/accessorials/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    billingAccountsIndex: build.query<
      BillingAccountsIndexApiResponse,
      BillingAccountsIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/shippers/${queryArg.shipperId}/billing_accounts`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    billingAccountCreate: build.mutation<
      BillingAccountCreateApiResponse,
      BillingAccountCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/shippers/${queryArg.shipperId}/billing_accounts`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    fulfillmentCentersIndex: build.query<
      FulfillmentCentersIndexApiResponse,
      FulfillmentCentersIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/fulfillment_centers`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
          search: queryArg.search,
        },
      }),
    }),
    fulfillmentCentersCreate: build.mutation<
      FulfillmentCentersCreateApiResponse,
      FulfillmentCentersCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/fulfillment_centers`,
        method: "POST",
        body: queryArg,
      }),
    }),
    vendorsCarrierMappingsIndex: build.query<
      VendorsCarrierMappingsIndexApiResponse,
      VendorsCarrierMappingsIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/vendors/${queryArg.vendorId}/carrier_mappings`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    vendorsCarrierMappingsCreate: build.mutation<
      VendorsCarrierMappingsCreateApiResponse,
      VendorsCarrierMappingsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/vendors/${queryArg.vendorId}/carrier_mappings`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    registrationsDelete: build.mutation<
      RegistrationsDeleteApiResponse,
      RegistrationsDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/registrations/${queryArg}`,
        method: "DELETE",
      }),
    }),
    registrationsShow: build.query<
      RegistrationsShowApiResponse,
      RegistrationsShowApiArg
    >({
      query: (queryArg) => ({ url: `/v1/registrations/${queryArg}` }),
    }),
    registrationsUpdate: build.mutation<
      RegistrationsUpdateApiResponse,
      RegistrationsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/registrations/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.updateRegistration,
      }),
    }),
    putV1RegistrationsById: build.mutation<
      PutV1RegistrationsByIdApiResponse,
      PutV1RegistrationsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/registrations/${queryArg.id}`,
        method: "PUT",
        body: queryArg.updateRegistration,
      }),
    }),
    fulfillmentCentersDelete: build.mutation<
      FulfillmentCentersDeleteApiResponse,
      FulfillmentCentersDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/fulfillment_centers/${queryArg}`,
        method: "DELETE",
      }),
    }),
    fulfillmentCentersShow: build.query<
      FulfillmentCentersShowApiResponse,
      FulfillmentCentersShowApiArg
    >({
      query: (queryArg) => ({ url: `/v1/fulfillment_centers/${queryArg}` }),
    }),
    fulfillmentCentersUpdate: build.mutation<
      FulfillmentCentersUpdateApiResponse,
      FulfillmentCentersUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/fulfillment_centers/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    putV1FulfillmentCentersById: build.mutation<
      PutV1FulfillmentCentersByIdApiResponse,
      PutV1FulfillmentCentersByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/fulfillment_centers/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    accessorialsShipperAccessorialsIndex: build.query<
      AccessorialsShipperAccessorialsIndexApiResponse,
      AccessorialsShipperAccessorialsIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/accessorials/${queryArg.accessorialId}/shipper_accessorials`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    accessorialsShippersAccessorialsCreate: build.mutation<
      AccessorialsShippersAccessorialsCreateApiResponse,
      AccessorialsShippersAccessorialsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/accessorials/${queryArg.accessorialId}/shipper_accessorials`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    billingAccountsDelete: build.mutation<
      BillingAccountsDeleteApiResponse,
      BillingAccountsDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/shippers/${queryArg.shipperId}/billing_accounts/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    billingAccountsShow: build.query<
      BillingAccountsShowApiResponse,
      BillingAccountsShowApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/shippers/${queryArg.shipperId}/billing_accounts/${queryArg.id}`,
      }),
    }),
    billingAccountsUpdate: build.mutation<
      BillingAccountsUpdateApiResponse,
      BillingAccountsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/shippers/${queryArg.shipperId}/billing_accounts/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    putV1ShippersByShipperIdBillingAccountsAndId: build.mutation<
      PutV1ShippersByShipperIdBillingAccountsAndIdApiResponse,
      PutV1ShippersByShipperIdBillingAccountsAndIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/shippers/${queryArg.shipperId}/billing_accounts/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    trackingEventsDelete: build.mutation<
      TrackingEventsDeleteApiResponse,
      TrackingEventsDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/tracking_events/${queryArg}`,
        method: "DELETE",
      }),
    }),
    trackingEventsShow: build.query<
      TrackingEventsShowApiResponse,
      TrackingEventsShowApiArg
    >({
      query: (queryArg) => ({ url: `/v1/tracking_events/${queryArg}` }),
    }),
    trackingEventsUpdate: build.mutation<
      TrackingEventsUpdateApiResponse,
      TrackingEventsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/tracking_events/${queryArg.trackingEventId}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    putV1TrackingEventsByTrackingEventId: build.mutation<
      PutV1TrackingEventsByTrackingEventIdApiResponse,
      PutV1TrackingEventsByTrackingEventIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/tracking_events/${queryArg.trackingEventId}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    vendorsDelete: build.mutation<
      VendorsDeleteApiResponse,
      VendorsDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/vendors/${queryArg}`,
        method: "DELETE",
      }),
    }),
    vendorsShow: build.query<VendorsShowApiResponse, VendorsShowApiArg>({
      query: (queryArg) => ({ url: `/v1/vendors/${queryArg}` }),
    }),
    vendorsUpdate: build.mutation<
      VendorsUpdateApiResponse,
      VendorsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/vendors/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    putV1VendorsById: build.mutation<
      PutV1VendorsByIdApiResponse,
      PutV1VendorsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/vendors/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    returnLabelsCreate: build.mutation<
      ReturnLabelsCreateApiResponse,
      ReturnLabelsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/shippers/${queryArg.shipperId}/return_labels`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    parcelsIndex: build.query<ParcelsIndexApiResponse, ParcelsIndexApiArg>({
      query: (queryArg) => ({
        url: `/v1/parcels`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    oversizedSurchargesDelete: build.mutation<
      OversizedSurchargesDeleteApiResponse,
      OversizedSurchargesDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/oversized_surcharges/${queryArg}`,
        method: "DELETE",
      }),
    }),
    oversizedSurchargesShow: build.query<
      OversizedSurchargesShowApiResponse,
      OversizedSurchargesShowApiArg
    >({
      query: (queryArg) => ({ url: `/v1/oversized_surcharges/${queryArg}` }),
    }),
    oversizedSurchargesUpdate: build.mutation<
      OversizedSurchargesUpdateApiResponse,
      OversizedSurchargesUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/oversized_surcharges/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    putV1OversizedSurchargesById: build.mutation<
      PutV1OversizedSurchargesByIdApiResponse,
      PutV1OversizedSurchargesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/oversized_surcharges/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    dimensionalFactorsDelete: build.mutation<
      DimensionalFactorsDeleteApiResponse,
      DimensionalFactorsDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/dimensional_factors/${queryArg}`,
        method: "DELETE",
      }),
    }),
    dimensionalFactorsShow: build.query<
      DimensionalFactorsShowApiResponse,
      DimensionalFactorsShowApiArg
    >({
      query: (queryArg) => ({ url: `/v1/dimensional_factors/${queryArg}` }),
    }),
    dimensionalFactorsUpdate: build.mutation<
      DimensionalFactorsUpdateApiResponse,
      DimensionalFactorsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/dimensional_factors/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    putV1DimensionalFactorsById: build.mutation<
      PutV1DimensionalFactorsByIdApiResponse,
      PutV1DimensionalFactorsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/dimensional_factors/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    shippersParcelsRateEstimate: build.mutation<
      ShippersParcelsRateEstimateApiResponse,
      ShippersParcelsRateEstimateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/shippers/${queryArg.shipperId}/parcels/rate_estimate`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    shippersParcelsIndex: build.query<
      ShippersParcelsIndexApiResponse,
      ShippersParcelsIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/shippers/${queryArg.shipperId}/parcels`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    shippersParcelsCreate: build.mutation<
      ShippersParcelsCreateApiResponse,
      ShippersParcelsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/shippers/${queryArg.shipperId}/parcels`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    accessorialsIndex: build.query<
      AccessorialsIndexApiResponse,
      AccessorialsIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/accessorials`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    accessorialsCreate: build.mutation<
      AccessorialsCreateApiResponse,
      AccessorialsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/accessorials`,
        method: "POST",
        body: queryArg,
      }),
    }),
    extendedAreasDelete: build.mutation<
      ExtendedAreasDeleteApiResponse,
      ExtendedAreasDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/extended_areas/${queryArg}`,
        method: "DELETE",
      }),
    }),
    extendedAreasShow: build.query<
      ExtendedAreasShowApiResponse,
      ExtendedAreasShowApiArg
    >({
      query: (queryArg) => ({ url: `/v1/extended_areas/${queryArg}` }),
    }),
    extendedAreasUpdate: build.mutation<
      ExtendedAreasUpdateApiResponse,
      ExtendedAreasUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/extended_areas/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    putV1ExtendedAreasById: build.mutation<
      PutV1ExtendedAreasByIdApiResponse,
      PutV1ExtendedAreasByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/extended_areas/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    vendorsServiceMethodMappingsIndex: build.query<
      VendorsServiceMethodMappingsIndexApiResponse,
      VendorsServiceMethodMappingsIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/vendors/${queryArg.vendorId}/service_method_mappings`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    vendorsServiceMethodMappingsCreate: build.mutation<
      VendorsServiceMethodMappingsCreateApiResponse,
      VendorsServiceMethodMappingsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/vendors/${queryArg.vendorId}/service_method_mappings`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    carrierAccountsDelete: build.mutation<
      CarrierAccountsDeleteApiResponse,
      CarrierAccountsDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/carrier_accounts/${queryArg}`,
        method: "DELETE",
      }),
    }),
    carrierAccountsShow: build.query<
      CarrierAccountsShowApiResponse,
      CarrierAccountsShowApiArg
    >({
      query: (queryArg) => ({ url: `/v1/carrier_accounts/${queryArg}` }),
    }),
    carrierAccountsUpdate: build.mutation<
      CarrierAccountsUpdateApiResponse,
      CarrierAccountsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/carrier_accounts/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    putV1CarrierAccountsById: build.mutation<
      PutV1CarrierAccountsByIdApiResponse,
      PutV1CarrierAccountsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/carrier_accounts/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    residentialSurchargesIndex: build.query<
      ResidentialSurchargesIndexApiResponse,
      ResidentialSurchargesIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/residential_surcharges`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    residentialSurchargesCreate: build.mutation<
      ResidentialSurchargesCreateApiResponse,
      ResidentialSurchargesCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/residential_surcharges`,
        method: "POST",
        body: queryArg,
      }),
    }),
    parcelsRerate: build.mutation<
      ParcelsRerateApiResponse,
      ParcelsRerateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/parcels/${queryArg.parcelId}/rerate`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    extendedAreasIndex: build.query<
      ExtendedAreasIndexApiResponse,
      ExtendedAreasIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/extended_areas`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    extendedAreaCreate: build.mutation<
      ExtendedAreaCreateApiResponse,
      ExtendedAreaCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/extended_areas`,
        method: "POST",
        body: queryArg,
      }),
    }),
    vendorsCarrierMappingsDelete: build.mutation<
      VendorsCarrierMappingsDeleteApiResponse,
      VendorsCarrierMappingsDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/vendors/${queryArg.vendorId}/carrier_mappings/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    vendorsCarrierMappingsShow: build.query<
      VendorsCarrierMappingsShowApiResponse,
      VendorsCarrierMappingsShowApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/vendors/${queryArg.vendorId}/carrier_mappings/${queryArg.id}`,
      }),
    }),
    vendorsCarrierMappingsUpdate: build.mutation<
      VendorsCarrierMappingsUpdateApiResponse,
      VendorsCarrierMappingsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/vendors/${queryArg.vendorId}/carrier_mappings/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    putV1VendorsByVendorIdCarrierMappingsAndId: build.mutation<
      PutV1VendorsByVendorIdCarrierMappingsAndIdApiResponse,
      PutV1VendorsByVendorIdCarrierMappingsAndIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/vendors/${queryArg.vendorId}/carrier_mappings/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    parcelsRatingsIndex: build.query<
      ParcelsRatingsIndexApiResponse,
      ParcelsRatingsIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/parcels/ratings`,
        params: {
          sort: queryArg.sort,
          page: queryArg.page,
          page_size: queryArg.pageSize,
          filter: queryArg.filter,
        },
      }),
    }),
    zoneGroupsIndex: build.query<
      ZoneGroupsIndexApiResponse,
      ZoneGroupsIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/zone_groups`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
          search: queryArg.search,
        },
      }),
    }),
    zoneGroupsCreate: build.mutation<
      ZoneGroupsCreateApiResponse,
      ZoneGroupsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/zone_groups`,
        method: "POST",
        body: queryArg,
      }),
    }),
    shippersParcelsVoid: build.mutation<
      ShippersParcelsVoidApiResponse,
      ShippersParcelsVoidApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/shippers/${queryArg.shipperId}/parcels/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    shippersParcelsShow: build.query<
      ShippersParcelsShowApiResponse,
      ShippersParcelsShowApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/shippers/${queryArg.shipperId}/parcels/${queryArg.id}`,
      }),
    }),
    carriersServiceMethodsDelete: build.mutation<
      CarriersServiceMethodsDeleteApiResponse,
      CarriersServiceMethodsDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/carriers/${queryArg.carrierId}/service_methods/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    carriersServiceMethodsShow: build.query<
      CarriersServiceMethodsShowApiResponse,
      CarriersServiceMethodsShowApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/carriers/${queryArg.carrierId}/service_methods/${queryArg.id}`,
      }),
    }),
    carriersServiceMethodsUpdate: build.mutation<
      CarriersServiceMethodsUpdateApiResponse,
      CarriersServiceMethodsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/carriers/${queryArg.carrierId}/service_methods/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    putV1CarriersByCarrierIdServiceMethodsAndId: build.mutation<
      PutV1CarriersByCarrierIdServiceMethodsAndIdApiResponse,
      PutV1CarriersByCarrierIdServiceMethodsAndIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/carriers/${queryArg.carrierId}/service_methods/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    carrierAccessorialsIndex: build.query<
      CarrierAccessorialsIndexApiResponse,
      CarrierAccessorialsIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/carrier_accessorials`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    carrierAccessorialsCreate: build.mutation<
      CarrierAccessorialsCreateApiResponse,
      CarrierAccessorialsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/carrier_accessorials`,
        method: "POST",
        body: queryArg,
      }),
    }),
    residentialSurchargesDelete: build.mutation<
      ResidentialSurchargesDeleteApiResponse,
      ResidentialSurchargesDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/residential_surcharges/${queryArg}`,
        method: "DELETE",
      }),
    }),
    residentialSurchargesShow: build.query<
      ResidentialSurchargesShowApiResponse,
      ResidentialSurchargesShowApiArg
    >({
      query: (queryArg) => ({ url: `/v1/residential_surcharges/${queryArg}` }),
    }),
    residentialSurchargesUpdate: build.mutation<
      ResidentialSurchargesUpdateApiResponse,
      ResidentialSurchargesUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/residential_surcharges/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    putV1ResidentialSurchargesById: build.mutation<
      PutV1ResidentialSurchargesByIdApiResponse,
      PutV1ResidentialSurchargesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/residential_surcharges/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    fuelGroupsFuelSurchargesIndex: build.query<
      FuelGroupsFuelSurchargesIndexApiResponse,
      FuelGroupsFuelSurchargesIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/fuel_groups/${queryArg.fuelGroupId}/fuel_surcharges`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
        },
      }),
    }),
    fuelGroupsFuelSurchargesCreate: build.mutation<
      FuelGroupsFuelSurchargesCreateApiResponse,
      FuelGroupsFuelSurchargesCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/fuel_groups/${queryArg.fuelGroupId}/fuel_surcharges`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    registrationsIndex: build.query<
      RegistrationsIndexApiResponse,
      RegistrationsIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/registrations`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    registrationsCreate: build.mutation<
      RegistrationsCreateApiResponse,
      RegistrationsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/registrations`,
        method: "POST",
        body: queryArg,
      }),
    }),
    rateCardsRateRecordsBulkCreate: build.mutation<
      RateCardsRateRecordsBulkCreateApiResponse,
      RateCardsRateRecordsBulkCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/rate_cards/${queryArg.rateCardId}/rate_records/bulk`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    fuelGroupsFuelSurchargesDelete: build.mutation<
      FuelGroupsFuelSurchargesDeleteApiResponse,
      FuelGroupsFuelSurchargesDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/fuel_groups/${queryArg.fuelGroupId}/fuel_surcharges/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    fuelGroupsFuelSurchargesShow: build.query<
      FuelGroupsFuelSurchargesShowApiResponse,
      FuelGroupsFuelSurchargesShowApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/fuel_groups/${queryArg.fuelGroupId}/fuel_surcharges/${queryArg.id}`,
      }),
    }),
    fuelGroupsFuelSurchargesUpdate: build.mutation<
      FuelGroupsFuelSurchargesUpdateApiResponse,
      FuelGroupsFuelSurchargesUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/fuel_groups/${queryArg.fuelGroupId}/fuel_surcharges/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    putV1FuelGroupsByFuelGroupIdFuelSurchargesAndId: build.mutation<
      PutV1FuelGroupsByFuelGroupIdFuelSurchargesAndIdApiResponse,
      PutV1FuelGroupsByFuelGroupIdFuelSurchargesAndIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/fuel_groups/${queryArg.fuelGroupId}/fuel_surcharges/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    zoneGroupsDelete: build.mutation<
      ZoneGroupsDeleteApiResponse,
      ZoneGroupsDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/zone_groups/${queryArg}`,
        method: "DELETE",
      }),
    }),
    zoneGroupsShow: build.query<
      ZoneGroupsShowApiResponse,
      ZoneGroupsShowApiArg
    >({
      query: (queryArg) => ({ url: `/v1/zone_groups/${queryArg}` }),
    }),
    zoneGroupsUpdate: build.mutation<
      ZoneGroupsUpdateApiResponse,
      ZoneGroupsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/zone_groups/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    putV1ZoneGroupsById: build.mutation<
      PutV1ZoneGroupsByIdApiResponse,
      PutV1ZoneGroupsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/zone_groups/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    accessorialsShipperAccessorialsDelete: build.mutation<
      AccessorialsShipperAccessorialsDeleteApiResponse,
      AccessorialsShipperAccessorialsDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/accessorials/${queryArg.accessorialId}/shipper_accessorials/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    accessorialsShipperAccessorialsShow: build.query<
      AccessorialsShipperAccessorialsShowApiResponse,
      AccessorialsShipperAccessorialsShowApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/accessorials/${queryArg.accessorialId}/shipper_accessorials/${queryArg.id}`,
      }),
    }),
    accessorialsShipperAccessorialsUpdate: build.mutation<
      AccessorialsShipperAccessorialsUpdateApiResponse,
      AccessorialsShipperAccessorialsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/accessorials/${queryArg.accessorialId}/shipper_accessorials/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    putV1AccessorialsByAccessorialIdShipperAccessorialsAndId: build.mutation<
      PutV1AccessorialsByAccessorialIdShipperAccessorialsAndIdApiResponse,
      PutV1AccessorialsByAccessorialIdShipperAccessorialsAndIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/accessorials/${queryArg.accessorialId}/shipper_accessorials/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    accessorialsConfigsIndex: build.query<
      AccessorialsConfigsIndexApiResponse,
      AccessorialsConfigsIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/accessorials_configs`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    carriersServiceMethodsIndex: build.query<
      CarriersServiceMethodsIndexApiResponse,
      CarriersServiceMethodsIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/carriers/${queryArg.carrierId}/service_methods`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    carrierServiceMethodsCreate: build.mutation<
      CarrierServiceMethodsCreateApiResponse,
      CarrierServiceMethodsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/carriers/${queryArg.carrierId}/service_methods`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    shippersParcelsBatchShow: build.query<
      ShippersParcelsBatchShowApiResponse,
      ShippersParcelsBatchShowApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/shippers/${queryArg.shipperId}/parcels/batches/${queryArg.batchId}`,
      }),
    }),
    easyPostWebhookEvent: build.mutation<
      EasyPostWebhookEventApiResponse,
      EasyPostWebhookEventApiArg
    >({
      query: (queryArg) => ({
        url: `/webhooks/easy_post/tracking_events`,
        method: "POST",
        body: queryArg,
      }),
    }),
    zoneGroupsZonesIndex: build.query<
      ZoneGroupsZonesIndexApiResponse,
      ZoneGroupsZonesIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/zone_groups/${queryArg.zoneGroupId}/zones`,
        params: {
          sort: queryArg.sort,
          page: queryArg.page,
          page_size: queryArg.pageSize,
          filter: queryArg.filter,
        },
      }),
    }),
    zoneGroupsZonesCreate: build.mutation<
      ZoneGroupsZonesCreateApiResponse,
      ZoneGroupsZonesCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/zone_groups/${queryArg.zoneGroupId}/zones`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    accessorialsAccessorialsConfigsIndex: build.query<
      AccessorialsAccessorialsConfigsIndexApiResponse,
      AccessorialsAccessorialsConfigsIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/accessorials/${queryArg.accessorialId}/configs`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    accessorialsAccessorialsConfigsCreate: build.mutation<
      AccessorialsAccessorialsConfigsCreateApiResponse,
      AccessorialsAccessorialsConfigsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/accessorials/${queryArg.accessorialId}/configs`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    fuelGroupsDelete: build.mutation<
      FuelGroupsDeleteApiResponse,
      FuelGroupsDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/fuel_groups/${queryArg}`,
        method: "DELETE",
      }),
    }),
    fuelGroupsShow: build.query<
      FuelGroupsShowApiResponse,
      FuelGroupsShowApiArg
    >({
      query: (queryArg) => ({ url: `/v1/fuel_groups/${queryArg}` }),
    }),
    fuelGroupsUpdate: build.mutation<
      FuelGroupsUpdateApiResponse,
      FuelGroupsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/fuel_groups/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    putV1FuelGroupsById: build.mutation<
      PutV1FuelGroupsByIdApiResponse,
      PutV1FuelGroupsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/fuel_groups/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    vendorsIndex: build.query<VendorsIndexApiResponse, VendorsIndexApiArg>({
      query: (queryArg) => ({
        url: `/v1/vendors`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    vendorsCreate: build.mutation<
      VendorsCreateApiResponse,
      VendorsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/vendors`,
        method: "POST",
        body: queryArg,
      }),
    }),
    extendedAreaSurchargesIndex: build.query<
      ExtendedAreaSurchargesIndexApiResponse,
      ExtendedAreaSurchargesIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/extended_area_surcharges`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    extendedAreaSurchargesCreate: build.mutation<
      ExtendedAreaSurchargesCreateApiResponse,
      ExtendedAreaSurchargesCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/extended_area_surcharges`,
        method: "POST",
        body: queryArg,
      }),
    }),
    shipiumWebhookEvent: build.mutation<
      ShipiumWebhookEventApiResponse,
      ShipiumWebhookEventApiArg
    >({
      query: (queryArg) => ({
        url: `/webhooks/shipium/tracking_events`,
        method: "POST",
        body: queryArg,
      }),
    }),
    rateCardsRateRecordsIndex: build.query<
      RateCardsRateRecordsIndexApiResponse,
      RateCardsRateRecordsIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/rate_cards/${queryArg.rateCardId}/rate_records`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    rateCardsRateRecordsCreate: build.mutation<
      RateCardsRateRecordsCreateApiResponse,
      RateCardsRateRecordsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/rate_cards/${queryArg.rateCardId}/rate_records`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    trackingEventsIndex: build.query<
      TrackingEventsIndexApiResponse,
      TrackingEventsIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/tracking_events`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    trackingEventsCreate: build.mutation<
      TrackingEventsCreateApiResponse,
      TrackingEventsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/tracking_events`,
        method: "POST",
        body: queryArg,
      }),
    }),
    dimensionalFactorsIndex: build.query<
      DimensionalFactorsIndexApiResponse,
      DimensionalFactorsIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/dimensional_factors`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    dimensionalFactorsCreate: build.mutation<
      DimensionalFactorsCreateApiResponse,
      DimensionalFactorsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/dimensional_factors`,
        method: "POST",
        body: queryArg,
      }),
    }),
    carrierAccessorialsDelete: build.mutation<
      CarrierAccessorialsDeleteApiResponse,
      CarrierAccessorialsDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/carrier_accessorials/${queryArg}`,
        method: "DELETE",
      }),
    }),
    carrierAccessorialsShow: build.query<
      CarrierAccessorialsShowApiResponse,
      CarrierAccessorialsShowApiArg
    >({
      query: (queryArg) => ({ url: `/v1/carrier_accessorials/${queryArg}` }),
    }),
    carrierAccessorialsUpdate: build.mutation<
      CarrierAccessorialsUpdateApiResponse,
      CarrierAccessorialsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/carrier_accessorials/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    putV1CarrierAccessorialsById: build.mutation<
      PutV1CarrierAccessorialsByIdApiResponse,
      PutV1CarrierAccessorialsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/carrier_accessorials/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    serviceMethodsShow: build.query<
      ServiceMethodsShowApiResponse,
      ServiceMethodsShowApiArg
    >({
      query: (queryArg) => ({ url: `/v1/service_methods/${queryArg}` }),
    }),
    carriersDelete: build.mutation<
      CarriersDeleteApiResponse,
      CarriersDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/carriers/${queryArg}`,
        method: "DELETE",
      }),
    }),
    carriersShow: build.query<CarriersShowApiResponse, CarriersShowApiArg>({
      query: (queryArg) => ({ url: `/v1/carriers/${queryArg}` }),
    }),
    carriersUpdate: build.mutation<
      CarriersUpdateApiResponse,
      CarriersUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/carriers/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    putV1CarriersById: build.mutation<
      PutV1CarriersByIdApiResponse,
      PutV1CarriersByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/carriers/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    extendedAreaLevelsDelete: build.mutation<
      ExtendedAreaLevelsDeleteApiResponse,
      ExtendedAreaLevelsDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/extended_area_levels/${queryArg}`,
        method: "DELETE",
      }),
    }),
    extendedAreaLevelsShow: build.query<
      ExtendedAreaLevelsShowApiResponse,
      ExtendedAreaLevelsShowApiArg
    >({
      query: (queryArg) => ({ url: `/v1/extended_area_levels/${queryArg}` }),
    }),
    extendedAreaLevelsUpdate: build.mutation<
      ExtendedAreaLevelsUpdateApiResponse,
      ExtendedAreaLevelsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/extended_area_levels/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    putV1ExtendedAreaLevelsById: build.mutation<
      PutV1ExtendedAreaLevelsByIdApiResponse,
      PutV1ExtendedAreaLevelsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/extended_area_levels/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    rateCardsIndex: build.query<
      RateCardsIndexApiResponse,
      RateCardsIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/rate_cards`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    rateCardsCreate: build.mutation<
      RateCardsCreateApiResponse,
      RateCardsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/rate_cards`,
        method: "POST",
        body: queryArg,
      }),
    }),
    peakSurchargesDelete: build.mutation<
      PeakSurchargesDeleteApiResponse,
      PeakSurchargesDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/peak_surcharges/${queryArg}`,
        method: "DELETE",
      }),
    }),
    peakSurchargesShow: build.query<
      PeakSurchargesShowApiResponse,
      PeakSurchargesShowApiArg
    >({
      query: (queryArg) => ({ url: `/v1/peak_surcharges/${queryArg}` }),
    }),
    peakSurchargesUpdate: build.mutation<
      PeakSurchargesUpdateApiResponse,
      PeakSurchargesUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/peak_surcharges/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    putV1PeakSurchargesById: build.mutation<
      PutV1PeakSurchargesByIdApiResponse,
      PutV1PeakSurchargesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/peak_surcharges/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    shippersShow: build.query<ShippersShowApiResponse, ShippersShowApiArg>({
      query: (queryArg) => ({ url: `/v1/shippers/${queryArg}` }),
    }),
    shippersUpdate: build.mutation<
      ShippersUpdateApiResponse,
      ShippersUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/shippers/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    putV1ShippersById: build.mutation<
      PutV1ShippersByIdApiResponse,
      PutV1ShippersByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/shippers/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    accessorialsAccessorialsConfigsDelete: build.mutation<
      AccessorialsAccessorialsConfigsDeleteApiResponse,
      AccessorialsAccessorialsConfigsDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/accessorials/${queryArg.accessorialId}/configs/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    accessorialsAccessorialsConfigsShow: build.query<
      AccessorialsAccessorialsConfigsShowApiResponse,
      AccessorialsAccessorialsConfigsShowApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/accessorials/${queryArg.accessorialId}/configs/${queryArg.id}`,
      }),
    }),
    accessorialsAccessorialsConfigsUpdate: build.mutation<
      AccessorialsAccessorialsConfigsUpdateApiResponse,
      AccessorialsAccessorialsConfigsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/accessorials/${queryArg.accessorialId}/configs/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    putV1AccessorialsByAccessorialIdConfigsAndId: build.mutation<
      PutV1AccessorialsByAccessorialIdConfigsAndIdApiResponse,
      PutV1AccessorialsByAccessorialIdConfigsAndIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/accessorials/${queryArg.accessorialId}/configs/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    oversizedSurchargesIndex: build.query<
      OversizedSurchargesIndexApiResponse,
      OversizedSurchargesIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/oversized_surcharges`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    oversizedSurchargesCreate: build.mutation<
      OversizedSurchargesCreateApiResponse,
      OversizedSurchargesCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/oversized_surcharges`,
        method: "POST",
        body: queryArg,
      }),
    }),
    serviceLevelsDelete: build.mutation<
      ServiceLevelsDeleteApiResponse,
      ServiceLevelsDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/service_levels/${queryArg}`,
        method: "DELETE",
      }),
    }),
    serviceLevelsShow: build.query<
      ServiceLevelsShowApiResponse,
      ServiceLevelsShowApiArg
    >({
      query: (queryArg) => ({ url: `/v1/service_levels/${queryArg}` }),
    }),
    serviceLevelsUpdate: build.mutation<
      ServiceLevelsUpdateApiResponse,
      ServiceLevelsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/service_levels/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    putV1ServiceLevelsById: build.mutation<
      PutV1ServiceLevelsByIdApiResponse,
      PutV1ServiceLevelsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/service_levels/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    extendedAreaLevelsIndex: build.query<
      ExtendedAreaLevelsIndexApiResponse,
      ExtendedAreaLevelsIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/extended_area_levels`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    extendedAreaLevelsCreate: build.mutation<
      ExtendedAreaLevelsCreateApiResponse,
      ExtendedAreaLevelsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/extended_area_levels`,
        method: "POST",
        body: queryArg,
      }),
    }),
    serviceLevelsIndex: build.query<
      ServiceLevelsIndexApiResponse,
      ServiceLevelsIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/service_levels`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    serviceLevelsCreate: build.mutation<
      ServiceLevelsCreateApiResponse,
      ServiceLevelsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/service_levels`,
        method: "POST",
        body: queryArg,
      }),
    }),
    fuelGroupsIndex: build.query<
      FuelGroupsIndexApiResponse,
      FuelGroupsIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/fuel_groups`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    fuelGroupsCreate: build.mutation<
      FuelGroupsCreateApiResponse,
      FuelGroupsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/fuel_groups`,
        method: "POST",
        body: queryArg,
      }),
    }),
    deliveryEstimateGetDeliveryEstimate: build.mutation<
      DeliveryEstimateGetDeliveryEstimateApiResponse,
      DeliveryEstimateGetDeliveryEstimateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/shippers/${queryArg.shipperId}/delivery_estimate`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as generatedApi };
export type ZoneGroupsZonesDeleteApiResponse =
  /** status 204 Successful deletion of a zone */ string;
export type ZoneGroupsZonesDeleteApiArg = {
  /** The zone group id */
  zoneGroupId: string;
  /** The zone id */
  id: string;
};
export type ZoneGroupsZonesShowApiResponse = /** status 200 The zone */ {
  data: Zone;
};
export type ZoneGroupsZonesShowApiArg = {
  /** The zone group id */
  zoneGroupId: string;
  /** The zone id */
  id: string;
};
export type ZoneGroupsZonesUpdateApiResponse =
  /** status 200 An updated zone */ {
    data: Zone;
  };
export type ZoneGroupsZonesUpdateApiArg = {
  /** The zone group id */
  zoneGroupId: string;
  /** The zone id */
  id: string;
  /** Zone update payload */
  body: {
    data: UpdateZone;
  };
};
export type PutV1ZoneGroupsByZoneGroupIdZonesAndIdApiResponse =
  /** status 200 An updated zone */ {
    data: Zone;
  };
export type PutV1ZoneGroupsByZoneGroupIdZonesAndIdApiArg = {
  /** The zone group id */
  zoneGroupId: string;
  /** The zone id */
  id: string;
  /** Zone update payload */
  body: {
    data: UpdateZone;
  };
};
export type ZoneGroupsZonesBulkCreateApiResponse =
  /** status 201 The created zones */ {
    data: Zone[];
  };
export type ZoneGroupsZonesBulkCreateApiArg = {
  /** The zone group id */
  zoneGroupId: string;
  /** Bulk zone creation payload */
  body: {
    data: CreateZone[];
  };
};
export type PeakSurchargesIndexApiResponse =
  /** status 200 A collection of peak surcharges */ {
    data: PeakSurchargeRead[];
    meta: PaginationMetadata;
  };
export type PeakSurchargesIndexApiArg = {
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `zone_code`, `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    accessorial_id?: string;
    carrier_id?: string;
    fulfillment_center_id?: string;
    service_level_id?: string;
    service_method_id?: string;
    shipper_id?: string;
    zone_code?: string;
  };
};
export type PeakSurchargesCreateApiResponse =
  /** status 201 The created peak surcharge */ {
    data: PeakSurchargeRead;
  };
export type PeakSurchargesCreateApiArg =
  /** Peak surcharge creation payload */ {
    data: CreatePeakSurcharge;
  };
export type ExtendedAreaSurchargesDeleteApiResponse =
  /** status 204 Successful deletion of an extended area surcharge */ string;
export type ExtendedAreaSurchargesDeleteApiArg =
  /** The extended area surcharge id */ string;
export type ExtendedAreaSurchargesShowApiResponse =
  /** status 200 The Extended Area Surcharge */ {
    data: ExtendedAreaSurcharge;
  };
export type ExtendedAreaSurchargesShowApiArg =
  /** The extended area surcharge id */ string;
export type ExtendedAreaSurchargesUpdateApiResponse =
  /** status 200 An updated extended area surcharge */ {
    data: ExtendedAreaSurcharge;
  };
export type ExtendedAreaSurchargesUpdateApiArg = {
  /** The extended area surcharge id */
  id: string;
  /** Extended Area Surcharge update payload */
  body: {
    data: UpdateExtendedAreaSurcharge;
  };
};
export type PutV1ExtendedAreaSurchargesByIdApiResponse =
  /** status 200 An updated extended area surcharge */ {
    data: ExtendedAreaSurcharge;
  };
export type PutV1ExtendedAreaSurchargesByIdApiArg = {
  /** The extended area surcharge id */
  id: string;
  /** Extended Area Surcharge update payload */
  body: {
    data: UpdateExtendedAreaSurcharge;
  };
};
export type CarrierAccountsIndexApiResponse =
  /** status 200 A collection of carrier accounts */ {
    data: ShowCarrierAccountRead[];
    meta: PaginationMetadata;
  };
export type CarrierAccountsIndexApiArg = {
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `carrier_name`, `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    carrier_name?: string;
    postal_code?: string;
  };
};
export type CarrierAccountCreateApiResponse =
  /** status 201 The created carrier_account */ {
    data: ShowCarrierAccountRead;
  };
export type CarrierAccountCreateApiArg =
  /** Carrier Account creation payload */ {
    data: CreateCarrierAccount;
  };
export type ShippersAccessorialsIndexApiResponse =
  /** status 200 A collection of shipper accessorials */ {
    data: ShipperAccessorialRead[];
    meta: PaginationMetadata;
  };
export type ShippersAccessorialsIndexApiArg = {
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `name`, `destination_address_type`, `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    accessorial_id?: string;
    /** The destination address type. */
    destination_address_type?: "residential" | "commercial";
    name?: string;
    shipper_id?: string;
  };
};
export type RateCardsRateRecordsDeleteApiResponse =
  /** status 204 Successful deletion of a rate record */ string;
export type RateCardsRateRecordsDeleteApiArg = {
  /** The rate card id */
  rateCardId: string;
  /** The rate record id */
  id: string;
};
export type RateCardsRateRecordsShowApiResponse =
  /** status 200 The rate record */ {
    data: RateRecord;
  };
export type RateCardsRateRecordsShowApiArg = {
  /** The rate card id */
  rateCardId: string;
  /** The rate record id */
  id: string;
};
export type RateCardsRateRecordsUpdateApiResponse =
  /** status 200 An updated rate record */ {
    data: RateRecord;
  };
export type RateCardsRateRecordsUpdateApiArg = {
  /** The rate card id */
  rateCardId: string;
  /** The rate record id */
  id: string;
  /** Rate record update payload */
  body: {
    data: UpdateRateRecord;
  };
};
export type PutV1RateCardsByRateCardIdRateRecordsAndIdApiResponse =
  /** status 200 An updated rate record */ {
    data: RateRecord;
  };
export type PutV1RateCardsByRateCardIdRateRecordsAndIdApiArg = {
  /** The rate card id */
  rateCardId: string;
  /** The rate record id */
  id: string;
  /** Rate record update payload */
  body: {
    data: UpdateRateRecord;
  };
};
export type ShipperCarrierFulfillmentsDeleteApiResponse =
  /** status 204 Successful deletion of a shipper carrier fulfillment context */ string;
export type ShipperCarrierFulfillmentsDeleteApiArg = {
  /** The shipper id */
  shipperId: string;
  /** The carrier account id */
  carrierAccountId: string;
  /** The fulfillment center id */
  fulfillmentCenterId: string;
};
export type ShipperCarrierFulfillmentsCreateApiResponse =
  /** status 201 A shipper carrier fulfillment context */ {
    data: ShipperCarrierFulfillmentRead;
  };
export type ShipperCarrierFulfillmentsCreateApiArg = {
  /** The shipper id */
  shipperId: string;
  /** The carrier account id */
  carrierAccountId: string;
  /** The fulfillment center id */
  fulfillmentCenterId: string;
};
export type CarriersIndexApiResponse =
  /** status 200 A collection of carriers */ {
    data: Carrier[];
    meta: PaginationMetadata;
  };
export type CarriersIndexApiArg = {
  /** Sort by updated_at, inserted_at, name. You can specify the direction by appending `:asc` or `:desc`. Example: `field1:asc,field2:desc`. */
  sort?: string;
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    name?: string;
  };
};
export type CarriersCreateApiResponse = /** status 201 The created carrier */ {
  data: Carrier;
};
export type CarriersCreateApiArg = /** Carrier creation payload */ {
  data: CreateCarrier;
};
export type VendorsServiceMethodMappingsDeleteApiResponse =
  /** status 204 Successful deletion of a vendor service method mapping */ string;
export type VendorsServiceMethodMappingsDeleteApiArg = {
  /** The vendor id */
  vendorId: string;
  /** The vendor service method mapping id */
  id: string;
};
export type VendorsServiceMethodMappingsShowApiResponse =
  /** status 200 The vendor service method mapping */ {
    data: VendorServiceMethodMapping;
  };
export type VendorsServiceMethodMappingsShowApiArg = {
  /** The vendor id */
  vendorId: string;
  /** The vendor service method mapping id */
  id: string;
};
export type VendorsServiceMethodMappingsUpdateApiResponse =
  /** status 200 An updated vendor service method mapping */ {
    data: VendorServiceMethodMapping;
  };
export type VendorsServiceMethodMappingsUpdateApiArg = {
  /** The vendor id */
  vendorId: string;
  /** The vendor service method mapping id */
  id: string;
  /** Vendor service method mapping update payload */
  body: {
    data: UpdateVendorServiceMethodMapping;
  };
};
export type PutV1VendorsByVendorIdServiceMethodMappingsAndIdApiResponse =
  /** status 200 An updated vendor service method mapping */ {
    data: VendorServiceMethodMapping;
  };
export type PutV1VendorsByVendorIdServiceMethodMappingsAndIdApiArg = {
  /** The vendor id */
  vendorId: string;
  /** The vendor service method mapping id */
  id: string;
  /** Vendor service method mapping update payload */
  body: {
    data: UpdateVendorServiceMethodMapping;
  };
};
export type ShippersIndexApiResponse =
  /** status 200 A collection of shippers */ {
    data: Shipper[];
    meta: PaginationMetadata;
  };
export type ShippersIndexApiArg = {
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `name`, `rating_enabled`, `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    external_reference?: string;
    id?: string;
    name?: string;
    rating_enabled?: boolean;
  };
};
export type ShippersCreateApiResponse = /** status 201 The shipper */ {
  data: Shipper;
};
export type ShippersCreateApiArg = /** Shipper creation payload */ {
  data: CreateShipper;
};
export type RateCardsDeleteApiResponse =
  /** status 204 Successful deletion of a rate card */ string;
export type RateCardsDeleteApiArg = /** The rate card id */ string;
export type RateCardsShowApiResponse = /** status 200 The Rate Card */ {
  data: RateCardRead;
};
export type RateCardsShowApiArg = /** The rate card id */ string;
export type RateCardsUpdateApiResponse =
  /** status 200 An updated rate card */ {
    data: RateCardRead;
  };
export type RateCardsUpdateApiArg = {
  /** The rate card id */
  id: string;
  /** Rate card update payload */
  body: {
    data: UpdateRateCard;
  };
};
export type PutV1RateCardsByIdApiResponse =
  /** status 200 An updated rate card */ {
    data: RateCardRead;
  };
export type PutV1RateCardsByIdApiArg = {
  /** The rate card id */
  id: string;
  /** Rate card update payload */
  body: {
    data: UpdateRateCard;
  };
};
export type ServiceMethodsIndexApiResponse =
  /** status 200 A collection of service methods */ {
    data: ServiceMethod[];
    meta: PaginationMetadata;
  };
export type ServiceMethodsIndexApiArg = {
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `name`, `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    carrier_id?: string;
    name?: string;
  };
};
export type AccessorialsDeleteApiResponse =
  /** status 204 Successful deletion of an accessorial */ string;
export type AccessorialsDeleteApiArg = /** The accessorial id */ string;
export type AccessorialsShowApiResponse = /** status 200 The Accessorial */ {
  data: Accessorial;
};
export type AccessorialsShowApiArg = /** The accessorial id */ string;
export type AccessorialsUpdateApiResponse =
  /** status 200 An updated accessorial */ {
    data: Accessorial;
  };
export type AccessorialsUpdateApiArg = {
  /** The accessorial id */
  id: string;
  /** Accessorial update payload */
  body: {
    data: UpdateAccessorial;
  };
};
export type PutV1AccessorialsByIdApiResponse =
  /** status 200 An updated accessorial */ {
    data: Accessorial;
  };
export type PutV1AccessorialsByIdApiArg = {
  /** The accessorial id */
  id: string;
  /** Accessorial update payload */
  body: {
    data: UpdateAccessorial;
  };
};
export type BillingAccountsIndexApiResponse =
  /** status 200 A collection of billing accounts */ {
    data: BillingAccount[];
    meta: PaginationMetadata;
  };
export type BillingAccountsIndexApiArg = {
  /** The shipper external reference */
  shipperId: string;
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `name`, `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    id?: string;
    name?: string;
  };
};
export type BillingAccountCreateApiResponse =
  /** status 201 The created billing account */ {
    data: BillingAccount;
  };
export type BillingAccountCreateApiArg = {
  /** The shipper external reference */
  shipperId: string;
  /** Billing Account creation payload */
  body: {
    data: CreateBillingAccount;
  };
};
export type FulfillmentCentersIndexApiResponse =
  /** status 200 A collection of fulfillment centers */ {
    data: FulfillmentCenterRead[];
    meta: PaginationMetadata;
  };
export type FulfillmentCentersIndexApiArg = {
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `name`, `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    name?: string;
  };
  /** Search by the following fields: name, alias */
  search?: string;
};
export type FulfillmentCentersCreateApiResponse =
  /** status 201 The created fulfillment_center */ {
    data: FulfillmentCenterRead;
  };
export type FulfillmentCentersCreateApiArg =
  /** Fulfillment center creation payload */ {
    data: CreateFulfillmentCenter;
  };
export type VendorsCarrierMappingsIndexApiResponse =
  /** status 200 A collection of vendor carrier mappings */ {
    data: VendorCarrierMapping[];
    meta: PaginationMetadata;
  };
export type VendorsCarrierMappingsIndexApiArg = {
  /** The vendor id */
  vendorId: string;
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `vendor_carrier`, `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    vendor_carrier?: string;
  };
};
export type VendorsCarrierMappingsCreateApiResponse =
  /** status 201 The created vendor carrier mapping */ {
    data: VendorCarrierMapping;
  };
export type VendorsCarrierMappingsCreateApiArg = {
  /** The vendor id */
  vendorId: string;
  /** Vendor carrier mapping creation payload */
  body: {
    data: CreateVendorCarrierMapping;
  };
};
export type RegistrationsDeleteApiResponse =
  /** status 204 Successful deletion of a registration */ string;
export type RegistrationsDeleteApiArg = /** The registration id */ string;
export type RegistrationsShowApiResponse = /** status 200 The Registration */ {
  data: ShowRegistration;
};
export type RegistrationsShowApiArg = /** The registration id */ string;
export type RegistrationsUpdateApiResponse =
  /** status 200 An updated registration */ ShowRegistration;
export type RegistrationsUpdateApiArg = {
  /** The registration id */
  id: string;
  /** Registration update payload */
  updateRegistration: UpdateRegistration;
};
export type PutV1RegistrationsByIdApiResponse =
  /** status 200 An updated registration */ ShowRegistration;
export type PutV1RegistrationsByIdApiArg = {
  /** The registration id */
  id: string;
  /** Registration update payload */
  updateRegistration: UpdateRegistration;
};
export type FulfillmentCentersDeleteApiResponse =
  /** status 204 Successful deletion of a fulfillment center */ string;
export type FulfillmentCentersDeleteApiArg =
  /** The fulfillment center id */ string;
export type FulfillmentCentersShowApiResponse =
  /** status 200 The Fulfillment Center */ {
    data: FulfillmentCenterRead;
  };
export type FulfillmentCentersShowApiArg =
  /** The fulfillment center id */ string;
export type FulfillmentCentersUpdateApiResponse =
  /** status 200 An updated fulfillment center */ {
    data: FulfillmentCenterRead;
  };
export type FulfillmentCentersUpdateApiArg = {
  /** The fulfillment id */
  id: string;
  /** Fulfillment center update payload */
  body: {
    data: UpdateFulfillmentCenter;
  };
};
export type PutV1FulfillmentCentersByIdApiResponse =
  /** status 200 An updated fulfillment center */ {
    data: FulfillmentCenterRead;
  };
export type PutV1FulfillmentCentersByIdApiArg = {
  /** The fulfillment id */
  id: string;
  /** Fulfillment center update payload */
  body: {
    data: UpdateFulfillmentCenter;
  };
};
export type AccessorialsShipperAccessorialsIndexApiResponse =
  /** status 200 A collection of shipper accessorials */ {
    data: ShipperAccessorialRead[];
    meta: PaginationMetadata;
  };
export type AccessorialsShipperAccessorialsIndexApiArg = {
  /** The accessorial id */
  accessorialId: string;
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `name`, `destination_address_type`, `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    /** The destination address type. */
    destination_address_type?: "residential" | "commercial";
    name?: string;
    shipper_id?: string;
  };
};
export type AccessorialsShippersAccessorialsCreateApiResponse =
  /** status 201 The created shipper accessorial */ {
    data: ShipperAccessorialRead;
  };
export type AccessorialsShippersAccessorialsCreateApiArg = {
  /** The accessorial id */
  accessorialId: string;
  /** Shipper accessorial creation payload */
  body: {
    data: CreateShipperAccessorial;
  };
};
export type BillingAccountsDeleteApiResponse =
  /** status 204 Successful deletion of a billing account */ string;
export type BillingAccountsDeleteApiArg = {
  /** The shipper external reference */
  shipperId: string;
  /** The billing account id */
  id: string;
};
export type BillingAccountsShowApiResponse =
  /** status 200 The Billing Account */ {
    data: BillingAccount;
  };
export type BillingAccountsShowApiArg = {
  /** The shipper external reference */
  shipperId: string;
  /** The billing account id */
  id: string;
};
export type BillingAccountsUpdateApiResponse =
  /** status 200 An updated billing account */ {
    data: BillingAccount;
  };
export type BillingAccountsUpdateApiArg = {
  /** The shipper external reference */
  shipperId: string;
  /** The billing account id */
  id: string;
  /** Billing Account update payload */
  body: {
    data: UpdateBillingAccount;
  };
};
export type PutV1ShippersByShipperIdBillingAccountsAndIdApiResponse =
  /** status 200 An updated billing account */ {
    data: BillingAccount;
  };
export type PutV1ShippersByShipperIdBillingAccountsAndIdApiArg = {
  /** The shipper external reference */
  shipperId: string;
  /** The billing account id */
  id: string;
  /** Billing Account update payload */
  body: {
    data: UpdateBillingAccount;
  };
};
export type TrackingEventsDeleteApiResponse =
  /** status 204 Successful deletion of a tracking event */ string;
export type TrackingEventsDeleteApiArg = /** The tracking event id */ string;
export type TrackingEventsShowApiResponse =
  /** status 200 The Tracking Event */ {
    data: ShowTrackingEventRead;
  };
export type TrackingEventsShowApiArg = /** The tracking event id */ string;
export type TrackingEventsUpdateApiResponse =
  /** status 200 An updated tracking event */ {
    data: ShowTrackingEventRead;
  };
export type TrackingEventsUpdateApiArg = {
  /** The tracking event id */
  trackingEventId: string;
  /** Tracking Event update payload */
  body: {
    data: UpdateTrackingEvent;
  };
};
export type PutV1TrackingEventsByTrackingEventIdApiResponse =
  /** status 200 An updated tracking event */ {
    data: ShowTrackingEventRead;
  };
export type PutV1TrackingEventsByTrackingEventIdApiArg = {
  /** The tracking event id */
  trackingEventId: string;
  /** Tracking Event update payload */
  body: {
    data: UpdateTrackingEvent;
  };
};
export type VendorsDeleteApiResponse =
  /** status 204 Successful deletion of a vendor */ string;
export type VendorsDeleteApiArg = /** The vendor id */ string;
export type VendorsShowApiResponse = /** status 200 The Vendor */ {
  data: Vendor;
};
export type VendorsShowApiArg = /** The vendor id */ string;
export type VendorsUpdateApiResponse = /** status 200 An updated vendor */ {
  data: Vendor;
};
export type VendorsUpdateApiArg = {
  /** The vendor id */
  id: string;
  /** Vendor update payload */
  body: {
    data: UpdateVendor;
  };
};
export type PutV1VendorsByIdApiResponse = /** status 200 An updated vendor */ {
  data: Vendor;
};
export type PutV1VendorsByIdApiArg = {
  /** The vendor id */
  id: string;
  /** Vendor update payload */
  body: {
    data: UpdateVendor;
  };
};
export type ReturnLabelsCreateApiResponse =
  /** status 201 The created return label */ {
    data: ShowParcelRead;
  };
export type ReturnLabelsCreateApiArg = {
  /** The shipper associated with the return label */
  shipperId: string;
  /** Return label creation payload */
  body: {
    data: CreateReturnLabel;
  };
};
export type ParcelsIndexApiResponse =
  /** status 200 A collection of parcels */ {
    data: ShowParcelRead[];
    meta: PaginationMetadata;
  };
export type ParcelsIndexApiArg = {
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `carrier_name`, `label_provider`, `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    carrier_name?: string;
    external_order_number?: string;
    external_source_id?: string;
    /** Filter by return status */
    is_return?: boolean;
    label_provider?: string;
    /** Filter by rated status */
    rated?: boolean;
    shipper_id?: string;
    tracking_number?: string;
  };
};
export type OversizedSurchargesDeleteApiResponse =
  /** status 204 Successful deletion of an oversized surcharge */ string;
export type OversizedSurchargesDeleteApiArg =
  /** The oversized surcharge id */ string;
export type OversizedSurchargesShowApiResponse =
  /** status 200 The oversized surcharge */ {
    data: OversizedSurcharge;
  };
export type OversizedSurchargesShowApiArg =
  /** The oversized surcharge id */ string;
export type OversizedSurchargesUpdateApiResponse =
  /** status 200 An updated oversized surcharge */ {
    data: OversizedSurcharge;
  };
export type OversizedSurchargesUpdateApiArg = {
  /** The oversized surcharge id */
  id: string;
  /** Oversized surcharge update payload */
  body: {
    data: UpdateOversizedSurcharge;
  };
};
export type PutV1OversizedSurchargesByIdApiResponse =
  /** status 200 An updated oversized surcharge */ {
    data: OversizedSurcharge;
  };
export type PutV1OversizedSurchargesByIdApiArg = {
  /** The oversized surcharge id */
  id: string;
  /** Oversized surcharge update payload */
  body: {
    data: UpdateOversizedSurcharge;
  };
};
export type DimensionalFactorsDeleteApiResponse =
  /** status 204 Successful deletion of a dimensional factor */ string;
export type DimensionalFactorsDeleteApiArg =
  /** The dimensional factor id */ string;
export type DimensionalFactorsShowApiResponse =
  /** status 200 The Dimensional Factor */ {
    data: DimensionalFactor;
  };
export type DimensionalFactorsShowApiArg =
  /** The dimensional factor id */ string;
export type DimensionalFactorsUpdateApiResponse =
  /** status 200 An updated dimensional factor */ {
    data: DimensionalFactor;
  };
export type DimensionalFactorsUpdateApiArg = {
  /** The dimensional factor id */
  id: string;
  /** Dimensional Factor update payload */
  body: {
    data: UpdateDimensionalFactor;
  };
};
export type PutV1DimensionalFactorsByIdApiResponse =
  /** status 200 An updated dimensional factor */ {
    data: DimensionalFactor;
  };
export type PutV1DimensionalFactorsByIdApiArg = {
  /** The dimensional factor id */
  id: string;
  /** Dimensional Factor update payload */
  body: {
    data: UpdateDimensionalFactor;
  };
};
export type ShippersParcelsRateEstimateApiResponse =
  /** status 200 A collection parcel rate estimates */ {
    data: ShowRates[];
  };
export type ShippersParcelsRateEstimateApiArg = {
  /** The shipper external reference */
  shipperId: string;
  /** parcel rate payload */
  body: {
    data: RateEstimate;
  };
};
export type ShippersParcelsIndexApiResponse =
  /** status 200 A collection of parcels */ {
    data: ShowParcelRead[];
    meta: PaginationMetadata;
  };
export type ShippersParcelsIndexApiArg = {
  /** The shipper external reference */
  shipperId: string;
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `carrier_name`, `label_provider`, `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    carrier_name?: string;
    external_order_number?: string;
    external_source_id?: string;
    /** Filter by return status */
    is_return?: boolean;
    label_provider?: string;
    /** Filter by rated status */
    rated?: boolean;
    tracking_number?: string;
  };
};
export type ShippersParcelsCreateApiResponse =
  /** status 201 The created parcel */
  | {
      data: ShowParcelRead;
    }
  | /** status 202 Parcels Batch: Accepted */ {
      data?: {
        attributes: {
          status: string;
        };
        id: string;
        links?: {
          self?: string;
        };
        type: string;
      };
    };
export type ShippersParcelsCreateApiArg = {
  /** The shipper external reference */
  shipperId: string;
  /** Parcel creation payload */
  body:
    | {
        data: CreateParcel;
      }
    | {
        data: CreateParcelBatch;
      };
};
export type AccessorialsIndexApiResponse =
  /** status 200 A collection of accessorials */ {
    data: Accessorial[];
    meta: PaginationMetadata;
  };
export type AccessorialsIndexApiArg = {
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `name`, `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    name?: string;
  };
};
export type AccessorialsCreateApiResponse =
  /** status 201 The created accessorial */ {
    data: Accessorial;
  };
export type AccessorialsCreateApiArg = /** Accessorial creation payload */ {
  data: CreateAccessorial;
};
export type ExtendedAreasDeleteApiResponse =
  /** status 204 Successful deletion of a extended area */ string;
export type ExtendedAreasDeleteApiArg = /** The extended area id */ string;
export type ExtendedAreasShowApiResponse = /** status 200 The Extended Area */ {
  data: ExtendedArea;
};
export type ExtendedAreasShowApiArg = /** The extended area id */ string;
export type ExtendedAreasUpdateApiResponse =
  /** status 200 An updated extended area */ {
    data: ExtendedArea;
  };
export type ExtendedAreasUpdateApiArg = {
  /** The extended area id */
  id: string;
  /** Extended Area update payload */
  body: {
    data: UpdateExtendedArea;
  };
};
export type PutV1ExtendedAreasByIdApiResponse =
  /** status 200 An updated extended area */ {
    data: ExtendedArea;
  };
export type PutV1ExtendedAreasByIdApiArg = {
  /** The extended area id */
  id: string;
  /** Extended Area update payload */
  body: {
    data: UpdateExtendedArea;
  };
};
export type VendorsServiceMethodMappingsIndexApiResponse =
  /** status 200 A collection of vendor service method mappings */ {
    data: VendorServiceMethodMapping[];
    meta: PaginationMetadata;
  };
export type VendorsServiceMethodMappingsIndexApiArg = {
  /** The vendor id */
  vendorId: string;
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `vendor_service_method`, `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    vendor_service_method?: string;
  };
};
export type VendorsServiceMethodMappingsCreateApiResponse =
  /** status 201 The created vendor service method mapping */ {
    data: VendorServiceMethodMapping;
  };
export type VendorsServiceMethodMappingsCreateApiArg = {
  /** The vendor id */
  vendorId: string;
  /** Vendor service method mapping creation payload */
  body: {
    data: CreateVendorServiceMethodMapping;
  };
};
export type CarrierAccountsDeleteApiResponse =
  /** status 204 Successful deletion of a carrier account */ string;
export type CarrierAccountsDeleteApiArg = /** The carrier account id */ string;
export type CarrierAccountsShowApiResponse =
  /** status 200 The Carrier Account */ {
    data: ShowCarrierAccountRead;
  };
export type CarrierAccountsShowApiArg = /** The carrier account id */ string;
export type CarrierAccountsUpdateApiResponse =
  /** status 200 An updated carrier account */ {
    data: ShowCarrierAccountRead;
  };
export type CarrierAccountsUpdateApiArg = {
  /** The carrier account id */
  id: string;
  /** Carrier Account update payload */
  body: {
    data: UpdateCarrierAccount;
  };
};
export type PutV1CarrierAccountsByIdApiResponse =
  /** status 200 An updated carrier account */ {
    data: ShowCarrierAccountRead;
  };
export type PutV1CarrierAccountsByIdApiArg = {
  /** The carrier account id */
  id: string;
  /** Carrier Account update payload */
  body: {
    data: UpdateCarrierAccount;
  };
};
export type ResidentialSurchargesIndexApiResponse =
  /** status 200 A collection of residential surcharges */ {
    data: ResidentialSurcharge[];
    meta: PaginationMetadata;
  };
export type ResidentialSurchargesIndexApiArg = {
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `rate_type`, `min_weight`, `max_weight`, `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    accessorial_id?: string;
    carrier_id?: string;
    rate_type?: "buy" | "sell";
    service_level_id?: string;
    service_method_id?: string;
    shipper_id?: string;
  };
};
export type ResidentialSurchargesCreateApiResponse =
  /** status 201 The created residential surcharge */ {
    data: ResidentialSurcharge;
  };
export type ResidentialSurchargesCreateApiArg =
  /** Residential surcharge creation payload */ {
    data: CreateResidentialSurcharge;
  };
export type ParcelsRerateApiResponse = /** status 200 The rating */ {
  data: RatingRead;
};
export type ParcelsRerateApiArg = {
  /** The parcel id */
  parcelId: string;
  /** Rerating payload */
  body: {
    data: RerateRequest;
  };
};
export type ExtendedAreasIndexApiResponse =
  /** status 200 A collection of extended areas */ {
    data: ExtendedArea[];
    meta: PaginationMetadata;
  };
export type ExtendedAreasIndexApiArg = {
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `postal_code`, `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    postal_code?: string;
  };
};
export type ExtendedAreaCreateApiResponse =
  /** status 201 The created extended area */ {
    data: ExtendedArea;
  };
export type ExtendedAreaCreateApiArg = /** Extended Area creation payload */ {
  data: CreateExtendedArea;
};
export type VendorsCarrierMappingsDeleteApiResponse =
  /** status 204 Successful deletion of a vendor carrier mapping */ string;
export type VendorsCarrierMappingsDeleteApiArg = {
  /** The vendor id */
  vendorId: string;
  /** The vendor carrier mapping id */
  id: string;
};
export type VendorsCarrierMappingsShowApiResponse =
  /** status 200 The vendor carrier mapping */ {
    data: VendorCarrierMapping;
  };
export type VendorsCarrierMappingsShowApiArg = {
  /** The vendor id */
  vendorId: string;
  /** The vendor carrier mapping id */
  id: string;
};
export type VendorsCarrierMappingsUpdateApiResponse =
  /** status 200 An updated vendor carrier mapping */ {
    data: VendorCarrierMapping;
  };
export type VendorsCarrierMappingsUpdateApiArg = {
  /** The vendor id */
  vendorId: string;
  /** The vendor carrier mapping id */
  id: string;
  /** Vendor carrier mapping update payload */
  body: {
    data: UpdateVendorCarrierMapping;
  };
};
export type PutV1VendorsByVendorIdCarrierMappingsAndIdApiResponse =
  /** status 200 An updated vendor carrier mapping */ {
    data: VendorCarrierMapping;
  };
export type PutV1VendorsByVendorIdCarrierMappingsAndIdApiArg = {
  /** The vendor id */
  vendorId: string;
  /** The vendor carrier mapping id */
  id: string;
  /** Vendor carrier mapping update payload */
  body: {
    data: UpdateVendorCarrierMapping;
  };
};
export type ParcelsRatingsIndexApiResponse =
  /** status 200 A collection of rating results */ {
    data: RatingRead[];
    meta: PaginationMetadata;
  };
export type ParcelsRatingsIndexApiArg = {
  /** Sort by updated_at, inserted_at. You can specify the direction by appending `:asc` or `:desc`. Example: `field1:asc,field2:desc`. */
  sort?: string;
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    external_reference?: string;
    is_rerate?: boolean;
    parcel_id?: string;
    service_level_id?: string;
    service_method_id?: string;
    shipper_id?: string;
    zone_code?: string;
  };
};
export type ZoneGroupsIndexApiResponse =
  /** status 200 A collection of zone groups */ {
    data: ZoneGroup[];
    meta: PaginationMetadata;
  };
export type ZoneGroupsIndexApiArg = {
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `name`, `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    carrier_id?: string;
    origin_country?: string;
    origin_zip?: string;
    rate_type?: "buy" | "sell";
    service_level_id?: string;
    service_method_id?: string;
  };
  /** Search by the following fields: name */
  search?: string;
};
export type ZoneGroupsCreateApiResponse =
  /** status 201 The created zone group */ {
    data: ZoneGroup;
  };
export type ZoneGroupsCreateApiArg = /** Zone group creation payload */ {
  data: CreateZoneGroup;
};
export type ShippersParcelsVoidApiResponse =
  /** status 204 Successful deletion of a parcel */ string;
export type ShippersParcelsVoidApiArg = {
  /** The shipper external reference */
  shipperId: string;
  /** The parcel id */
  id: string;
};
export type ShippersParcelsShowApiResponse = /** status 200 The Parcel */ {
  data: ShowParcelRead;
};
export type ShippersParcelsShowApiArg = {
  /** The shipper external reference */
  shipperId: string;
  /** The parcel id */
  id: string;
};
export type CarriersServiceMethodsDeleteApiResponse =
  /** status 204 Successful deletion of a service method */ string;
export type CarriersServiceMethodsDeleteApiArg = {
  /** The carrier id */
  carrierId: string;
  /** The service method id */
  id: string;
};
export type CarriersServiceMethodsShowApiResponse =
  /** status 200 The service method */ {
    data: ServiceMethod;
  };
export type CarriersServiceMethodsShowApiArg = {
  /** The carrier id */
  carrierId: string;
  /** The service method id */
  id: string;
};
export type CarriersServiceMethodsUpdateApiResponse =
  /** status 200 An updated service method */ {
    data: ServiceMethod;
  };
export type CarriersServiceMethodsUpdateApiArg = {
  /** The carrier id */
  carrierId: string;
  /** The service method id */
  id: string;
  /** Service method update payload */
  body: {
    data: UpdateServiceMethod;
  };
};
export type PutV1CarriersByCarrierIdServiceMethodsAndIdApiResponse =
  /** status 200 An updated service method */ {
    data: ServiceMethod;
  };
export type PutV1CarriersByCarrierIdServiceMethodsAndIdApiArg = {
  /** The carrier id */
  carrierId: string;
  /** The service method id */
  id: string;
  /** Service method update payload */
  body: {
    data: UpdateServiceMethod;
  };
};
export type CarrierAccessorialsIndexApiResponse =
  /** status 200 A collection of carrier accessorials */ {
    data: CarrierAccessorial[];
    meta: PaginationMetadata;
  };
export type CarrierAccessorialsIndexApiArg = {
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `name`, `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    accessorial_id?: string | "null";
    carrier_id?: string;
    name?: string;
  };
};
export type CarrierAccessorialsCreateApiResponse =
  /** status 201 The created carrier accessorial */ {
    data: CarrierAccessorial;
  };
export type CarrierAccessorialsCreateApiArg =
  /** Carrier accessorial creation payload */ {
    data: CreateCarrierAccessorial;
  };
export type ResidentialSurchargesDeleteApiResponse =
  /** status 204 Successful deletion of a residential surcharge */ string;
export type ResidentialSurchargesDeleteApiArg =
  /** The residential surcharge id */ string;
export type ResidentialSurchargesShowApiResponse =
  /** status 200 The residential surcharge */ {
    data: ResidentialSurcharge;
  };
export type ResidentialSurchargesShowApiArg =
  /** The residential surcharge id */ string;
export type ResidentialSurchargesUpdateApiResponse =
  /** status 200 An updated residential surcharge */ {
    data: ResidentialSurcharge;
  };
export type ResidentialSurchargesUpdateApiArg = {
  /** The residential surcharge id */
  id: string;
  /** Residential surcharge update payload */
  body: {
    data: UpdateResidentialSurcharge;
  };
};
export type PutV1ResidentialSurchargesByIdApiResponse =
  /** status 200 An updated residential surcharge */ {
    data: ResidentialSurcharge;
  };
export type PutV1ResidentialSurchargesByIdApiArg = {
  /** The residential surcharge id */
  id: string;
  /** Residential surcharge update payload */
  body: {
    data: UpdateResidentialSurcharge;
  };
};
export type FuelGroupsFuelSurchargesIndexApiResponse =
  /** status 200 A collection of fuel surcharges */ {
    data: FuelSurcharge[];
    meta: PaginationMetadata;
  };
export type FuelGroupsFuelSurchargesIndexApiArg = {
  /** The fuel group id */
  fuelGroupId: string;
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `fuel_percent`, `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
};
export type FuelGroupsFuelSurchargesCreateApiResponse =
  /** status 201 The created carrier accessorial */ {
    data: FuelSurcharge;
  };
export type FuelGroupsFuelSurchargesCreateApiArg = {
  /** The fuel group id */
  fuelGroupId: string;
  /** Fuel surcharge creation payload */
  body: {
    data: CreateFuelSurcharge;
  };
};
export type RegistrationsIndexApiResponse =
  /** status 200 A collection of registrations */ {
    data: Registration[];
    meta: PaginationMetadata;
  };
export type RegistrationsIndexApiArg = {
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `delivered_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    name?: string;
  };
};
export type RegistrationsCreateApiResponse =
  /** status 201 The created registrations */ {
    data: Registration;
  };
export type RegistrationsCreateApiArg = /** Registration creation payload */ {
  data: Registration;
};
export type RateCardsRateRecordsBulkCreateApiResponse =
  /** status 201 The created rate records */ {
    data: RateRecord[];
  };
export type RateCardsRateRecordsBulkCreateApiArg = {
  /** The rate card id */
  rateCardId: string;
  /** Bulk rate record creation payload */
  body: {
    data: CreateRateRecord[];
  };
};
export type FuelGroupsFuelSurchargesDeleteApiResponse =
  /** status 204 Successful deletion of a fuel surcharge */ string;
export type FuelGroupsFuelSurchargesDeleteApiArg = {
  /** The fuel group id */
  fuelGroupId: string;
  /** The fuel surcharge id */
  id: string;
};
export type FuelGroupsFuelSurchargesShowApiResponse =
  /** status 200 The fuel surcharge */ {
    data: FuelSurcharge;
  };
export type FuelGroupsFuelSurchargesShowApiArg = {
  /** The fuel group id */
  fuelGroupId: string;
  /** The fuel surcharge id */
  id: string;
};
export type FuelGroupsFuelSurchargesUpdateApiResponse =
  /** status 200 An updated fuel surcharge */ {
    data: FuelSurcharge;
  };
export type FuelGroupsFuelSurchargesUpdateApiArg = {
  /** The fuel group id */
  fuelGroupId: string;
  /** The fuel surcharge id */
  id: string;
  /** Carrier accessorial update payload */
  body: {
    data: UpdateFuelSurcharge;
  };
};
export type PutV1FuelGroupsByFuelGroupIdFuelSurchargesAndIdApiResponse =
  /** status 200 An updated fuel surcharge */ {
    data: FuelSurcharge;
  };
export type PutV1FuelGroupsByFuelGroupIdFuelSurchargesAndIdApiArg = {
  /** The fuel group id */
  fuelGroupId: string;
  /** The fuel surcharge id */
  id: string;
  /** Carrier accessorial update payload */
  body: {
    data: UpdateFuelSurcharge;
  };
};
export type ZoneGroupsDeleteApiResponse =
  /** status 204 Successful deletion of a zone group */ string;
export type ZoneGroupsDeleteApiArg = /** The zone group id */ string;
export type ZoneGroupsShowApiResponse = /** status 200 The zone group */ {
  data: ZoneGroup;
};
export type ZoneGroupsShowApiArg = /** The zone group id */ string;
export type ZoneGroupsUpdateApiResponse =
  /** status 200 An updated zone group */ {
    data: ZoneGroup;
  };
export type ZoneGroupsUpdateApiArg = {
  /** The zone group id */
  id: string;
  /** Zone group update payload */
  body: {
    data: UpdateZoneGroup;
  };
};
export type PutV1ZoneGroupsByIdApiResponse =
  /** status 200 An updated zone group */ {
    data: ZoneGroup;
  };
export type PutV1ZoneGroupsByIdApiArg = {
  /** The zone group id */
  id: string;
  /** Zone group update payload */
  body: {
    data: UpdateZoneGroup;
  };
};
export type AccessorialsShipperAccessorialsDeleteApiResponse =
  /** status 204 Successful deletion of a shipper accessorial */ string;
export type AccessorialsShipperAccessorialsDeleteApiArg = {
  /** The accessorial id */
  accessorialId: string;
  /** The shipper accessorial id */
  id: string;
};
export type AccessorialsShipperAccessorialsShowApiResponse =
  /** status 200 The shipper accessorial */ {
    data: ShipperAccessorialRead;
  };
export type AccessorialsShipperAccessorialsShowApiArg = {
  /** The accessorial id */
  accessorialId: string;
  /** The shipper accessorial id */
  id: string;
};
export type AccessorialsShipperAccessorialsUpdateApiResponse =
  /** status 200 An updated shipper accessorial */ {
    data: ShipperAccessorialRead;
  };
export type AccessorialsShipperAccessorialsUpdateApiArg = {
  /** The accessorial id */
  accessorialId: string;
  /** The shipper accessorial id */
  id: string;
  /** Shipper accessorial update payload */
  body: {
    data: UpdateShipperAccessorial;
  };
};
export type PutV1AccessorialsByAccessorialIdShipperAccessorialsAndIdApiResponse =
  /** status 200 An updated shipper accessorial */ {
    data: ShipperAccessorialRead;
  };
export type PutV1AccessorialsByAccessorialIdShipperAccessorialsAndIdApiArg = {
  /** The accessorial id */
  accessorialId: string;
  /** The shipper accessorial id */
  id: string;
  /** Shipper accessorial update payload */
  body: {
    data: UpdateShipperAccessorial;
  };
};
export type AccessorialsConfigsIndexApiResponse =
  /** status 200 A collection of accessorial configs */ {
    data: AccessorialConfigRead[];
    meta: PaginationMetadata;
  };
export type AccessorialsConfigsIndexApiArg = {
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `run_type`, `destination_address_type`, `min_weight`, `max_weight`, `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    accessorial_id?: string;
    /** The name of the accessorial */
    accessorial_name?: string;
    /** The destination address type. */
    destination_address_type?: "residential" | "commercial";
    /** Accepts a known or partial run type (e.g. `use_buy%`). Known run types are: [:use_buy_rate, :use_shipper_rate, :ignore] */
    run_type?: string;
    shipper_id?: string;
  };
};
export type CarriersServiceMethodsIndexApiResponse =
  /** status 200 A collection of service methods */ {
    data: ServiceMethod[];
    meta: PaginationMetadata;
  };
export type CarriersServiceMethodsIndexApiArg = {
  /** The carrier id */
  carrierId: string;
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `name`, `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    name?: string;
  };
};
export type CarrierServiceMethodsCreateApiResponse =
  /** status 201 The created carrier service method */ {
    data: ServiceMethod;
  };
export type CarrierServiceMethodsCreateApiArg = {
  /** The carrier id */
  carrierId: string;
  /** Service method creation payload */
  body: {
    data: CreateServiceMethod;
  };
};
export type ShippersParcelsBatchShowApiResponse =
  /** status 200 A collection of batch jobs */ {
    data: BatchJobRead[];
  };
export type ShippersParcelsBatchShowApiArg = {
  /** The shipper external reference */
  shipperId: string;
  /** The batch id */
  batchId: string;
};
export type EasyPostWebhookEventApiResponse =
  /** status 202 Acknowledge the webhook */ string;
export type EasyPostWebhookEventApiArg =
  /** Webhook tracking event payload */ WebhookEvent;
export type ZoneGroupsZonesIndexApiResponse =
  /** status 200 A collection of zones */ {
    data: Zone[];
    meta: PaginationMetadata;
  };
export type ZoneGroupsZonesIndexApiArg = {
  /** The zone group id */
  zoneGroupId: string;
  /** Sort by zone_code, updated_at, inserted_at. You can specify the direction by appending `:asc` or `:desc`. Example: `field1:asc,field2:desc`. */
  sort?: string;
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    destination_country?: string;
    destination_zip?: string;
    zone_code?: string;
    zone_group_id?: string;
  };
};
export type ZoneGroupsZonesCreateApiResponse =
  /** status 201 The created zone */ {
    data: Zone;
  };
export type ZoneGroupsZonesCreateApiArg = {
  /** The zone group id */
  zoneGroupId: string;
  /** Zone creation payload */
  body: {
    data: CreateZone;
  };
};
export type AccessorialsAccessorialsConfigsIndexApiResponse =
  /** status 200 A collection of accessorial configs */ {
    data: AccessorialConfigRead[];
    meta: PaginationMetadata;
  };
export type AccessorialsAccessorialsConfigsIndexApiArg = {
  /** The accessorial id */
  accessorialId: string;
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `run_type`, `destination_address_type`, `min_weight`, `max_weight`, `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    /** The name of the accessorial */
    accessorial_name?: string;
    /** The destination address type. */
    destination_address_type?: "residential" | "commercial";
    /** Accepts a known or partial run type (e.g. `buy%`). Known run types are: [:use_buy_rate, :use_shipper_rate, :ignore] */
    run_type?: string;
    shipper_id?: string;
  };
};
export type AccessorialsAccessorialsConfigsCreateApiResponse =
  /** status 201 The created accessorial config */ {
    data: AccessorialConfigRead;
  };
export type AccessorialsAccessorialsConfigsCreateApiArg = {
  /** The accessorial id */
  accessorialId: string;
  /** Accessorial config creation payload */
  body: {
    data: CreateAccessorialConfig;
  };
};
export type FuelGroupsDeleteApiResponse =
  /** status 204 Successful deletion of a fuel group */ string;
export type FuelGroupsDeleteApiArg = /** The fuel group id */ string;
export type FuelGroupsShowApiResponse = /** status 200 The Fuel Group */ {
  data: FuelGroup;
};
export type FuelGroupsShowApiArg = /** The fuel group id */ string;
export type FuelGroupsUpdateApiResponse =
  /** status 200 An updated fuel group */ {
    data: FuelGroup;
  };
export type FuelGroupsUpdateApiArg = {
  /** The fuel group id */
  id: string;
  /** Fuel Group update payload */
  body: {
    data: UpdateFuelGroup;
  };
};
export type PutV1FuelGroupsByIdApiResponse =
  /** status 200 An updated fuel group */ {
    data: FuelGroup;
  };
export type PutV1FuelGroupsByIdApiArg = {
  /** The fuel group id */
  id: string;
  /** Fuel Group update payload */
  body: {
    data: UpdateFuelGroup;
  };
};
export type VendorsIndexApiResponse =
  /** status 200 A collection of vendors */ {
    data: Vendor[];
    meta: PaginationMetadata;
  };
export type VendorsIndexApiArg = {
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `name`, `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    name?: string;
  };
};
export type VendorsCreateApiResponse = /** status 201 The created vendor */ {
  data: Vendor;
};
export type VendorsCreateApiArg = /** Vendor creation payload */ {
  data: CreateVendor;
};
export type ExtendedAreaSurchargesIndexApiResponse =
  /** status 200 A collection of extended area surcharges */ {
    data: ExtendedAreaSurcharge[];
    meta: PaginationMetadata;
  };
export type ExtendedAreaSurchargesIndexApiArg = {
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    id?: string;
    zone_code?: string;
  };
};
export type ExtendedAreaSurchargesCreateApiResponse =
  /** status 201 The created extended area surcharge */ {
    data: ExtendedAreaSurcharge;
  };
export type ExtendedAreaSurchargesCreateApiArg =
  /** Extended Area Surcharge creation payload */ {
    data: CreateExtendedAreaSurcharge;
  };
export type ShipiumWebhookEventApiResponse =
  /** status 202 Acknowledge the webhook */ string;
export type ShipiumWebhookEventApiArg =
  /** Webhook tracking event payload */ ShipiumWebhookEvent;
export type RateCardsRateRecordsIndexApiResponse =
  /** status 200 A collection of rate records */ {
    data: RateRecord[];
    meta: PaginationMetadata;
  };
export type RateCardsRateRecordsIndexApiArg = {
  /** The rate card id */
  rateCardId: string;
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `rating_type`, `min_weight`, `max_weight`, `flat_rate`, `variable_rate`, `discount_rate`, `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    discount_rate?: string;
    flat_rate?: string;
    rating_type?: "discount" | "flat" | "variable" | "hybrid";
    variable_rate?: string;
    zone_code?: string;
  };
};
export type RateCardsRateRecordsCreateApiResponse =
  /** status 201 The created rate record */ {
    data: RateRecord;
  };
export type RateCardsRateRecordsCreateApiArg = {
  /** The rate card id */
  rateCardId: string;
  /** Rate record creation payload */
  body: {
    data: CreateRateRecord;
  };
};
export type TrackingEventsIndexApiResponse =
  /** status 200 A collection of tracking events */ {
    data: TrackingEventRead[];
    meta: PaginationMetadata;
  };
export type TrackingEventsIndexApiArg = {
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `occurred_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    external_order_number?: string;
    external_source_id?: string;
    shipper_id?: string;
    tracking_number?: string;
  };
};
export type TrackingEventsCreateApiResponse =
  /** status 201 The created tracking_events */ {
    data: TrackingEventRead;
  };
export type TrackingEventsCreateApiArg =
  /** Tracking event creation payload */ {
    data: TrackingEvent;
  };
export type DimensionalFactorsIndexApiResponse =
  /** status 200 A collection of dimensional factors */ {
    data: DimensionalFactor[];
    meta: PaginationMetadata;
  };
export type DimensionalFactorsIndexApiArg = {
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `factor`, `minimum_threshold`, `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    carrier_id?: string;
    service_method_id?: string;
    shipper_id?: string;
  };
};
export type DimensionalFactorsCreateApiResponse =
  /** status 201 The created dimensional factor */ {
    data: DimensionalFactor;
  };
export type DimensionalFactorsCreateApiArg =
  /** Dimensional factor creation payload */ {
    data: CreateDimensionalFactor;
  };
export type CarrierAccessorialsDeleteApiResponse =
  /** status 204 Successful deletion of a carrier accessorial */ string;
export type CarrierAccessorialsDeleteApiArg =
  /** The carrier accessorial id */ string;
export type CarrierAccessorialsShowApiResponse =
  /** status 200 The carrier accessorial */ {
    data: CarrierAccessorial;
  };
export type CarrierAccessorialsShowApiArg =
  /** The carrier accessorial id */ string;
export type CarrierAccessorialsUpdateApiResponse =
  /** status 200 An updated carrier accessorial */ {
    data: CarrierAccessorial;
  };
export type CarrierAccessorialsUpdateApiArg = {
  /** The carrier accessorial id */
  id: string;
  /** Carrier accessorial update payload */
  body: {
    data: UpdateCarrierAccessorial;
  };
};
export type PutV1CarrierAccessorialsByIdApiResponse =
  /** status 200 An updated carrier accessorial */ {
    data: CarrierAccessorial;
  };
export type PutV1CarrierAccessorialsByIdApiArg = {
  /** The carrier accessorial id */
  id: string;
  /** Carrier accessorial update payload */
  body: {
    data: UpdateCarrierAccessorial;
  };
};
export type ServiceMethodsShowApiResponse =
  /** status 200 The service method */ {
    data: ServiceMethod;
  };
export type ServiceMethodsShowApiArg = /** The service method id */ string;
export type CarriersDeleteApiResponse =
  /** status 204 Successful deletion of a carrier */ string;
export type CarriersDeleteApiArg = /** The carrier id */ string;
export type CarriersShowApiResponse = /** status 200 The Carrier */ {
  data: Carrier;
};
export type CarriersShowApiArg = /** The carrier id */ string;
export type CarriersUpdateApiResponse = /** status 200 An updated carrier */ {
  data: Carrier;
};
export type CarriersUpdateApiArg = {
  /** The carrier id */
  id: string;
  /** Carrier update payload */
  body: {
    data: UpdateCarrier;
  };
};
export type PutV1CarriersByIdApiResponse =
  /** status 200 An updated carrier */ {
    data: Carrier;
  };
export type PutV1CarriersByIdApiArg = {
  /** The carrier id */
  id: string;
  /** Carrier update payload */
  body: {
    data: UpdateCarrier;
  };
};
export type ExtendedAreaLevelsDeleteApiResponse =
  /** status 204 Successful deletion of a extended area level */ string;
export type ExtendedAreaLevelsDeleteApiArg =
  /** The extended area level id */ string;
export type ExtendedAreaLevelsShowApiResponse =
  /** status 200 The Extended Area Level */ {
    data: ExtendedAreaLevel;
  };
export type ExtendedAreaLevelsShowApiArg =
  /** The extended area level id */ string;
export type ExtendedAreaLevelsUpdateApiResponse =
  /** status 200 An updated extended area level */ {
    data: ExtendedAreaLevel;
  };
export type ExtendedAreaLevelsUpdateApiArg = {
  /** The extended area level id */
  id: string;
  /** Extended Area Level update payload */
  body: {
    data: UpdateExtendedAreaLevel;
  };
};
export type PutV1ExtendedAreaLevelsByIdApiResponse =
  /** status 200 An updated extended area level */ {
    data: ExtendedAreaLevel;
  };
export type PutV1ExtendedAreaLevelsByIdApiArg = {
  /** The extended area level id */
  id: string;
  /** Extended Area Level update payload */
  body: {
    data: UpdateExtendedAreaLevel;
  };
};
export type RateCardsIndexApiResponse =
  /** status 200 A collection of rate cards */ {
    data: RateCardRead[];
    meta: PaginationMetadata;
  };
export type RateCardsIndexApiArg = {
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `rate_type`, `rate_records_count`, `calculation_type`, `destination_address_type`, `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    calculation_type?: "published" | "discount" | "static";
    carrier_id?: string;
    destination_address_type?: "residential" | "commercial";
    fulfillment_center_id?: string;
    id?: string;
    rate_type?: "buy" | "sell";
    service_level_id?: string;
    service_method_id?: string;
    shipper_id?: string;
  };
};
export type RateCardsCreateApiResponse =
  /** status 201 The created rate card */ {
    data: RateCardRead;
  };
export type RateCardsCreateApiArg = /** Rate card creation payload */ {
  data: CreateRateCard;
};
export type PeakSurchargesDeleteApiResponse =
  /** status 204 Successful deletion of a peak surcharge */ string;
export type PeakSurchargesDeleteApiArg = /** The peak surcharge id */ string;
export type PeakSurchargesShowApiResponse =
  /** status 200 The peak surcharge */ {
    data: PeakSurchargeRead;
  };
export type PeakSurchargesShowApiArg = /** The peak surcharge id */ string;
export type PeakSurchargesUpdateApiResponse =
  /** status 200 An updated peak surcharge */ {
    data: PeakSurchargeRead;
  };
export type PeakSurchargesUpdateApiArg = {
  /** The peak surcharge id */
  id: string;
  /** Peak surcharge update payload */
  body: {
    data: UpdatePeakSurcharge;
  };
};
export type PutV1PeakSurchargesByIdApiResponse =
  /** status 200 An updated peak surcharge */ {
    data: PeakSurchargeRead;
  };
export type PutV1PeakSurchargesByIdApiArg = {
  /** The peak surcharge id */
  id: string;
  /** Peak surcharge update payload */
  body: {
    data: UpdatePeakSurcharge;
  };
};
export type ShippersShowApiResponse = /** status 200 The shipper */ {
  data: Shipper;
};
export type ShippersShowApiArg =
  /** The shipper id or external reference */ string;
export type ShippersUpdateApiResponse = /** status 200 An updated shipper */ {
  data: Shipper;
};
export type ShippersUpdateApiArg = {
  /** The shipper id or external reference */
  id: string;
  /** Shipper update payload */
  body: {
    data: UpdateShipper;
  };
};
export type PutV1ShippersByIdApiResponse =
  /** status 200 An updated shipper */ {
    data: Shipper;
  };
export type PutV1ShippersByIdApiArg = {
  /** The shipper id or external reference */
  id: string;
  /** Shipper update payload */
  body: {
    data: UpdateShipper;
  };
};
export type AccessorialsAccessorialsConfigsDeleteApiResponse =
  /** status 204 Successful deletion of an accessorial config */ string;
export type AccessorialsAccessorialsConfigsDeleteApiArg = {
  /** The accessorial id */
  accessorialId: string;
  /** The accessorial config id */
  id: string;
};
export type AccessorialsAccessorialsConfigsShowApiResponse =
  /** status 200 The accessorial config */ {
    data: AccessorialConfigRead;
  };
export type AccessorialsAccessorialsConfigsShowApiArg = {
  /** The accessorial id */
  accessorialId: string;
  /** The accessorial config id */
  id: string;
};
export type AccessorialsAccessorialsConfigsUpdateApiResponse =
  /** status 200 An updated accessorial config */ {
    data: AccessorialConfigRead;
  };
export type AccessorialsAccessorialsConfigsUpdateApiArg = {
  /** The accessorial id */
  accessorialId: string;
  /** The accessorial config id */
  id: string;
  /** Accessorial config update payload */
  body: {
    data: UpdateAccessorialConfig;
  };
};
export type PutV1AccessorialsByAccessorialIdConfigsAndIdApiResponse =
  /** status 200 An updated accessorial config */ {
    data: AccessorialConfigRead;
  };
export type PutV1AccessorialsByAccessorialIdConfigsAndIdApiArg = {
  /** The accessorial id */
  accessorialId: string;
  /** The accessorial config id */
  id: string;
  /** Accessorial config update payload */
  body: {
    data: UpdateAccessorialConfig;
  };
};
export type OversizedSurchargesIndexApiResponse =
  /** status 200 A collection of oversized surcharges */ {
    data: OversizedSurcharge[];
    meta: PaginationMetadata;
  };
export type OversizedSurchargesIndexApiArg = {
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `oversized_type`, `zone_code`, `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    accessorial_id?: string;
    carrier_id?: string;
    oversized_type?: string;
    service_level_id?: string;
    shipper_id?: string;
    zone_code?: string;
  };
};
export type OversizedSurchargesCreateApiResponse =
  /** status 201 The created oversized surcharge */ {
    data: OversizedSurcharge;
  };
export type OversizedSurchargesCreateApiArg =
  /** Oversized surcharge creation payload */ {
    data: CreateOversizedSurcharge;
  };
export type ServiceLevelsDeleteApiResponse =
  /** status 204 Successful deletion of a service level */ string;
export type ServiceLevelsDeleteApiArg = /** The service level id */ string;
export type ServiceLevelsShowApiResponse = /** status 200 The service level */ {
  data: ServiceLevel;
};
export type ServiceLevelsShowApiArg = /** The service level id */ string;
export type ServiceLevelsUpdateApiResponse =
  /** status 200 An updated service level */ {
    data: ServiceLevel;
  };
export type ServiceLevelsUpdateApiArg = {
  /** The service level id */
  id: string;
  /** Service level update payload */
  body: {
    data: UpdateServiceLevel;
  };
};
export type PutV1ServiceLevelsByIdApiResponse =
  /** status 200 An updated service level */ {
    data: ServiceLevel;
  };
export type PutV1ServiceLevelsByIdApiArg = {
  /** The service level id */
  id: string;
  /** Service level update payload */
  body: {
    data: UpdateServiceLevel;
  };
};
export type ExtendedAreaLevelsIndexApiResponse =
  /** status 200 A collection of extended area levels */ {
    data: ExtendedAreaLevel[];
    meta: PaginationMetadata;
  };
export type ExtendedAreaLevelsIndexApiArg = {
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `name`, `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    name?: string;
  };
};
export type ExtendedAreaLevelsCreateApiResponse =
  /** status 201 The created extended area level */ {
    data: ExtendedAreaLevel;
  };
export type ExtendedAreaLevelsCreateApiArg =
  /** Extended Area Level creation payload */ {
    data: CreateExtendedAreaLevel;
  };
export type ServiceLevelsIndexApiResponse =
  /** status 200 A collection of service levels */ {
    data: ServiceLevel[];
    meta: PaginationMetadata;
  };
export type ServiceLevelsIndexApiArg = {
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `name`, `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    name?: string;
  };
};
export type ServiceLevelsCreateApiResponse =
  /** status 201 The created service level */ {
    data: ServiceLevel;
  };
export type ServiceLevelsCreateApiArg = /** Service level creation payload */ {
  data: CreateServiceLevel;
};
export type FuelGroupsIndexApiResponse =
  /** status 200 A collection of fuel groups */ {
    data: FuelGroup[];
    meta: PaginationMetadata;
  };
export type FuelGroupsIndexApiArg = {
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `name`, `rate_type`, `calculation_type`, `destination_address_type`, `min_weight`, `max_weight`, `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    accessorial_id?: string;
    calculation_type?: "published" | "discount" | "static";
    destination_address_type?: "residential" | "commercial";
    name?: string;
    rate_type?: "buy" | "sell";
    shipper_id?: string;
  };
};
export type FuelGroupsCreateApiResponse =
  /** status 201 The created fuel group */ {
    data: FuelGroup;
  };
export type FuelGroupsCreateApiArg = /** Fuel Group creation payload */ {
  data: CreateFuelGroup;
};
export type DeliveryEstimateGetDeliveryEstimateApiResponse =
  /** status 200 The delivery estimate */ {
    data: DeliveryEstimate;
  };
export type DeliveryEstimateGetDeliveryEstimateApiArg = {
  /** The shipper external reference */
  shipperId: string;
  /** Delivery estimate request payload */
  body: {
    data: RequestDeliveryEstimate;
  };
};
export type NotFound = {
  /** Not Found errors */
  errors: {
    detail?: string;
  };
};
export type Zone = {
  /** The zip destination country represented by ISO 3166 alpha-2 country code */
  destination_country: string;
  /** The end of the destination zip code range */
  destination_zip_end: string;
  /** The start of the destination zip code range */
  destination_zip_start: string;
  /** The zone ID */
  id: string;
  inserted_at: string;
  updated_at: string;
  /** The code that identifies the zone */
  zone_code: string;
  zone_group_id: string;
};
export type JsonErrorResponse = {
  errors: {
    detail: string;
    source: {
      pointer: string;
    };
    title: string;
  }[];
};
export type UpdateZone = {
  /** The zip destination country represented by ISO 3166 alpha-2 country code */
  destination_country?: string;
  /** The end of the destination zip code range */
  destination_zip_end?: string;
  /** The start of the destination zip code range */
  destination_zip_start?: string;
  /** The code that identifies the zone */
  zone_code?: string;
};
export type CreateZone = {
  /** The zip destination country represented by ISO 3166 alpha-2 country code */
  destination_country: string;
  /** The end of the destination zip code range */
  destination_zip_end: string;
  /** The start of the destination zip code range */
  destination_zip_start: string;
  /** The code that identifies the zone */
  zone_code: string;
};
export type ServiceLevel = {
  display_name: string;
  id: string;
  inserted_at: string;
  name: string;
  updated_at: string;
};
export type ServiceMethodLevel = {
  incoterm?: ("DAP" | "DDP" | "DDU" | "DPU" | "FCA") | null;
  inserted_at: string;
  max_weight?: number | null;
  min_weight?: number | null;
  priority?: number | null;
  /** Service Level */
  service_level: {
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  };
  /** The service level ID */
  service_level_id: string;
  service_level_selection_strategy: "priority" | "weight" | "incoterm";
  /** The service method ID */
  service_method_id: string;
  updated_at: string;
};
export type PeakSurcharge = {
  /** Accessorial */
  accessorial: {
    calculate_fuel: boolean;
    default_rate: number;
    description: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  /** The accessorial ID */
  accessorial_id: string | null;
  /** Carrier */
  carrier: {
    /** The alternate names of the carrier */
    aliases: string[] | null;
    /** The UI friendly name for the carrier */
    display_name: string;
    /** The carrier ID */
    id: string;
    inserted_at: string;
    /** The name of the carrier */
    name: string;
    /** The tracking link template for the carrier */
    tracking_link_template: string | null;
    /** The tracking number lengths of the carrier */
    tracking_number_lengths: number[] | null;
    /** The tracking number prefixes of the carrier */
    tracking_number_prefixes: string[] | null;
    updated_at: string;
  } | null;
  /** The carrier ID */
  carrier_id: string | null;
  /** The effective or start date of the peak surcharge */
  effective_at: string;
  /** The expiration or end date of the peak surcharge */
  expires_at: string;
  fulfillment_center_id: string | null;
  /** The peak surcharge ID */
  id: string;
  inserted_at: string;
  /** Service Level */
  service_level: {
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  /** The service level ID */
  service_level_id: string | null;
  /** Service Method */
  service_method: {
    /** The carrier ID */
    carrier_id: string;
    /** The UI friendly name for the service method */
    display_name: string;
    /** The service method ID */
    id: string;
    inserted_at: string;
    /** The name of the service method */
    name: string;
    service_level_selection_strategy?:
      | ("priority" | "weight" | "incoterm")
      | null;
    service_levels: ServiceLevel[] | null;
    service_method_levels: ServiceMethodLevel[];
    updated_at: string;
  } | null;
  /** The service method ID */
  service_method_id: string | null;
  /** Shipper */
  shipper: {
    /** The enabled label providers for the shipper */
    enabled_label_providers: ("shipium" | "easy_post")[];
    /** The external reference for the shipper */
    external_reference: string | null;
    /** The shipper ID */
    id: string;
    /** Creation timestamp */
    inserted_at: string;
    /** The shipper name */
    name: string;
    /** Determines whether rating is enabled for the shipper */
    rating_enabled: boolean;
    /** Update timestamp */
    updated_at: string;
  } | null;
  /** The shipper ID */
  shipper_id: string | null;
  updated_at: string;
  /** The code that identifies the zone */
  zone_code: string | null;
};
export type PeakSurchargeRead = {
  /** Accessorial */
  accessorial: {
    calculate_fuel: boolean;
    default_rate: number;
    description: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  /** The accessorial ID */
  accessorial_id: string | null;
  /** Carrier */
  carrier: {
    /** The alternate names of the carrier */
    aliases: string[] | null;
    /** The UI friendly name for the carrier */
    display_name: string;
    /** The carrier ID */
    id: string;
    inserted_at: string;
    /** The name of the carrier */
    name: string;
    /** The tracking link template for the carrier */
    tracking_link_template: string | null;
    /** The tracking number lengths of the carrier */
    tracking_number_lengths: number[] | null;
    /** The tracking number prefixes of the carrier */
    tracking_number_prefixes: string[] | null;
    updated_at: string;
  } | null;
  /** The carrier ID */
  carrier_id: string | null;
  /** The effective or start date of the peak surcharge */
  effective_at: string;
  /** The expiration or end date of the peak surcharge */
  expires_at: string;
  /** FulfillmentCenter */
  fulfillment_center: {
    /** The address context used to identify the fulfillment center. */
    address: {
      city: string;
      company?: string | null;
      country: string;
      email?: string | null;
      external_location_id?: string | null;
      line1: string;
      line2?: string | null;
      line3?: string | null;
      name: string;
      phone?: string | null;
      postal_code?: string | null;
      state?: string | null;
      type: "residential" | "commercial";
    } | null;
    /** The alias used to identify the fulfillment center. */
    alias: string;
    /** Fulfillment Center ID */
    id: string;
    /** Creation timestamp */
    inserted_at: string;
    /** The human readable name used identify the fulfillment center. */
    name: string;
    /** The Shipium Origin ID used to identify the fulfillment center. */
    shipium_origin_id: string;
    /** Update timestamp */
    updated_at: string;
  } | null;
  fulfillment_center_id: string | null;
  /** The peak surcharge ID */
  id: string;
  inserted_at: string;
  /** Service Level */
  service_level: {
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  /** The service level ID */
  service_level_id: string | null;
  /** Service Method */
  service_method: {
    /** The carrier ID */
    carrier_id: string;
    /** The UI friendly name for the service method */
    display_name: string;
    /** The service method ID */
    id: string;
    inserted_at: string;
    /** The name of the service method */
    name: string;
    service_level_selection_strategy?:
      | ("priority" | "weight" | "incoterm")
      | null;
    service_levels: ServiceLevel[] | null;
    service_method_levels: ServiceMethodLevel[];
    updated_at: string;
  } | null;
  /** The service method ID */
  service_method_id: string | null;
  /** Shipper */
  shipper: {
    /** The enabled label providers for the shipper */
    enabled_label_providers: ("shipium" | "easy_post")[];
    /** The external reference for the shipper */
    external_reference: string | null;
    /** The shipper ID */
    id: string;
    /** Creation timestamp */
    inserted_at: string;
    /** The shipper name */
    name: string;
    /** Determines whether rating is enabled for the shipper */
    rating_enabled: boolean;
    /** Update timestamp */
    updated_at: string;
  } | null;
  /** The shipper ID */
  shipper_id: string | null;
  updated_at: string;
  /** The code that identifies the zone */
  zone_code: string | null;
};
export type PaginationMetadata = {
  page_number: number;
  page_size: number;
  total_entries: number;
  total_pages: number;
};
export type CreatePeakSurcharge = {
  /** The accessorial ID */
  accessorial_id: string | null;
  /** The carrier ID */
  carrier_id?: string | null;
  /** The effective or start date of the peak surcharge */
  effective_at: string;
  /** The expiration or end date of the peak surcharge */
  expires_at: string;
  fulfillment_center_id?: string | null;
  /** The service level ID */
  service_level_id?: string | null;
  /** The service method ID */
  service_method_id?: string | null;
  /** The shipper ID */
  shipper_id?: string | null;
  /** The code that identifies the zone */
  zone_code?: string | null;
};
export type ExtendedAreaSurcharge = {
  /** Accessorial */
  accessorial: {
    calculate_fuel: boolean;
    default_rate: number;
    description: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  };
  /** The accessorial ID */
  accessorial_id: string;
  /** The effective or start date of the extended area surcharge */
  effective_at: string;
  /** The expiration or end date of the extended area surcharge */
  expires_at: string;
  /** Extended Area Level */
  extended_area_level: {
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  /** The extended area level ID */
  extended_area_level_id: string | null;
  /** The extended area surcharge ID */
  id: string;
  inserted_at: string;
  updated_at: string;
  /** The code that identifies the zone */
  zone_code: string | null;
};
export type UpdateExtendedAreaSurcharge = {
  /** The accessorial ID */
  accessorial_id?: string;
  /** The effective or start date of the extended area surcharge */
  effective_at?: string;
  /** The expiration or end date of the extended area surcharge */
  expires_at?: string;
  /** The extended area level ID */
  extended_area_level_id?: string | null;
  /** The code that identifies the zone */
  zone_code?: string | null;
};
export type ShowCarrierAccount = {
  /** The id of the carrier account object provided by the 3rd party label provider */
  account_id?: string;
  /** The address context used to identify the carrier account. */
  address?: {
    city: string;
    company?: string | null;
    country: string;
    email?: string | null;
    external_location_id?: string | null;
    line1: string;
    line2?: string | null;
    line3?: string | null;
    name: string;
    phone?: string | null;
    postal_code?: string | null;
    state?: string | null;
    type: "residential" | "commercial";
  } | null;
  /** Carrier Name used to identify the carrier */
  carrier_name?: string;
  /** Description of the carrier account */
  description?: string | null;
  /** Creation timestamp */
  inserted_at?: string;
  /** The preferred label provider where the account is configured. */
  label_provider?: ("shipium" | "easy_post") | null;
  /** Update timestamp */
  updated_at?: string;
};
export type ShowCarrierAccountRead = {
  /** The id of the carrier account object provided by the 3rd party label provider */
  account_id?: string;
  /** The address context used to identify the carrier account. */
  address?: {
    city: string;
    company?: string | null;
    country: string;
    email?: string | null;
    external_location_id?: string | null;
    line1: string;
    line2?: string | null;
    line3?: string | null;
    name: string;
    phone?: string | null;
    postal_code?: string | null;
    state?: string | null;
    type: "residential" | "commercial";
  } | null;
  /** Carrier Name used to identify the carrier */
  carrier_name?: string;
  /** Description of the carrier account */
  description?: string | null;
  /** Carrier Account ID */
  id?: string;
  /** Creation timestamp */
  inserted_at?: string;
  /** The preferred label provider where the account is configured. */
  label_provider?: ("shipium" | "easy_post") | null;
  /** Update timestamp */
  updated_at?: string;
};
export type CreateCarrierAccount = {
  /** The id of the carrier account object provided by the 3rd party label provider */
  account_id: string;
  /** The address context used to identify the carrier account. */
  address: {
    city: string;
    company?: string | null;
    country: string;
    email?: string | null;
    external_location_id?: string | null;
    line1: string;
    line2?: string | null;
    line3?: string | null;
    name: string;
    phone?: string | null;
    postal_code?: string | null;
    state?: string | null;
    type: "residential" | "commercial";
  } | null;
  /** Carrier Name used to identify the carrier */
  carrier_name: string;
  /** Description of the carrier account */
  description?: string | null;
  /** The preferred label provider where the account is configured. */
  label_provider: ("shipium" | "easy_post") | null;
};
export type ShipperAccessorial = {
  /** Accessorial */
  accessorial: {
    calculate_fuel: boolean;
    default_rate: number;
    description: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  /** The accessorial ID */
  accessorial_id: string;
  /** Carrier */
  carrier: {
    /** The alternate names of the carrier */
    aliases: string[] | null;
    /** The UI friendly name for the carrier */
    display_name: string;
    /** The carrier ID */
    id: string;
    inserted_at: string;
    /** The name of the carrier */
    name: string;
    /** The tracking link template for the carrier */
    tracking_link_template: string | null;
    /** The tracking number lengths of the carrier */
    tracking_number_lengths: number[] | null;
    /** The tracking number prefixes of the carrier */
    tracking_number_prefixes: string[] | null;
    updated_at: string;
  } | null;
  /** The carrier ID */
  carrier_id: string | null;
  /** A description for the purpose of the shipper accessorial */
  description: string | null;
  /** The destination address type */
  destination_address_type: ("residential" | "commercial") | null;
  /** The effective or start date of the shipper accessorial */
  effective_at: string;
  /** The expiration or end date of the shipper accessorial */
  expires_at: string;
  fulfillment_center_id: string | null;
  /** The shipper accessorial ID */
  id: string;
  inserted_at: string;
  /** The name of the shipper accessorial */
  name: string;
  /** The rate of the shipper accessorial */
  rate: number;
  /** Service Level */
  service_level: {
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  /** The service level ID */
  service_level_id: string | null;
  /** Service Method */
  service_method: {
    /** The carrier ID */
    carrier_id: string;
    /** The UI friendly name for the service method */
    display_name: string;
    /** The service method ID */
    id: string;
    inserted_at: string;
    /** The name of the service method */
    name: string;
    service_level_selection_strategy?:
      | ("priority" | "weight" | "incoterm")
      | null;
    service_levels: ServiceLevel[] | null;
    service_method_levels: ServiceMethodLevel[];
    updated_at: string;
  } | null;
  /** The service method ID */
  service_method_id: string | null;
  /** Shipper */
  shipper: {
    /** The enabled label providers for the shipper */
    enabled_label_providers: ("shipium" | "easy_post")[];
    /** The external reference for the shipper */
    external_reference: string | null;
    /** The shipper ID */
    id: string;
    /** Creation timestamp */
    inserted_at: string;
    /** The shipper name */
    name: string;
    /** Determines whether rating is enabled for the shipper */
    rating_enabled: boolean;
    /** Update timestamp */
    updated_at: string;
  } | null;
  shipper_id: string | null;
  updated_at: string;
};
export type ShipperAccessorialRead = {
  /** Accessorial */
  accessorial: {
    calculate_fuel: boolean;
    default_rate: number;
    description: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  /** The accessorial ID */
  accessorial_id: string;
  /** Carrier */
  carrier: {
    /** The alternate names of the carrier */
    aliases: string[] | null;
    /** The UI friendly name for the carrier */
    display_name: string;
    /** The carrier ID */
    id: string;
    inserted_at: string;
    /** The name of the carrier */
    name: string;
    /** The tracking link template for the carrier */
    tracking_link_template: string | null;
    /** The tracking number lengths of the carrier */
    tracking_number_lengths: number[] | null;
    /** The tracking number prefixes of the carrier */
    tracking_number_prefixes: string[] | null;
    updated_at: string;
  } | null;
  /** The carrier ID */
  carrier_id: string | null;
  /** A description for the purpose of the shipper accessorial */
  description: string | null;
  /** The destination address type */
  destination_address_type: ("residential" | "commercial") | null;
  /** The effective or start date of the shipper accessorial */
  effective_at: string;
  /** The expiration or end date of the shipper accessorial */
  expires_at: string;
  /** FulfillmentCenter */
  fulfillment_center: {
    /** The address context used to identify the fulfillment center. */
    address: {
      city: string;
      company?: string | null;
      country: string;
      email?: string | null;
      external_location_id?: string | null;
      line1: string;
      line2?: string | null;
      line3?: string | null;
      name: string;
      phone?: string | null;
      postal_code?: string | null;
      state?: string | null;
      type: "residential" | "commercial";
    } | null;
    /** The alias used to identify the fulfillment center. */
    alias: string;
    /** Fulfillment Center ID */
    id: string;
    /** Creation timestamp */
    inserted_at: string;
    /** The human readable name used identify the fulfillment center. */
    name: string;
    /** The Shipium Origin ID used to identify the fulfillment center. */
    shipium_origin_id: string;
    /** Update timestamp */
    updated_at: string;
  } | null;
  fulfillment_center_id: string | null;
  /** The shipper accessorial ID */
  id: string;
  inserted_at: string;
  /** The name of the shipper accessorial */
  name: string;
  /** The rate of the shipper accessorial */
  rate: number;
  /** Service Level */
  service_level: {
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  /** The service level ID */
  service_level_id: string | null;
  /** Service Method */
  service_method: {
    /** The carrier ID */
    carrier_id: string;
    /** The UI friendly name for the service method */
    display_name: string;
    /** The service method ID */
    id: string;
    inserted_at: string;
    /** The name of the service method */
    name: string;
    service_level_selection_strategy?:
      | ("priority" | "weight" | "incoterm")
      | null;
    service_levels: ServiceLevel[] | null;
    service_method_levels: ServiceMethodLevel[];
    updated_at: string;
  } | null;
  /** The service method ID */
  service_method_id: string | null;
  /** Shipper */
  shipper: {
    /** The enabled label providers for the shipper */
    enabled_label_providers: ("shipium" | "easy_post")[];
    /** The external reference for the shipper */
    external_reference: string | null;
    /** The shipper ID */
    id: string;
    /** Creation timestamp */
    inserted_at: string;
    /** The shipper name */
    name: string;
    /** Determines whether rating is enabled for the shipper */
    rating_enabled: boolean;
    /** Update timestamp */
    updated_at: string;
  } | null;
  shipper_id: string | null;
  updated_at: string;
};
export type RateRecord = {
  discount_rate: number | null;
  flat_rate: number | null;
  id: string;
  inserted_at: string;
  max_weight: number;
  min_weight: number;
  /** The rate card ID */
  rate_card_id?: string;
  rating_type: "discount" | "flat" | "variable" | "hybrid";
  updated_at: string;
  variable_rate: number | null;
  /** The code that identifies the zone */
  zone_code?: string;
};
export type UpdateRateRecord = {
  discount_rate?: number | null;
  flat_rate?: number | null;
  id?: string;
  inserted_at?: string;
  max_weight?: number;
  min_weight?: number;
  /** The rate card ID */
  rate_card_id?: string;
  rating_type?: "discount" | "flat" | "variable" | "hybrid";
  updated_at?: string;
  variable_rate?: number | null;
  /** The code that identifies the zone */
  zone_code?: string;
};
export type ShipperCarrierFulfillment = {
  carrier_account_id: string;
  fulfillment_center_id: string;
  /** Creation timestamp */
  inserted_at?: string;
  shipper_id: string;
  /** Update timestamp */
  updated_at?: string;
};
export type ShipperCarrierFulfillmentRead = {
  carrier_account_id: string;
  fulfillment_center_id: string;
  /** ShipperCarrierFulfillment Context ID */
  id?: string;
  /** Creation timestamp */
  inserted_at?: string;
  shipper_id: string;
  /** Update timestamp */
  updated_at?: string;
};
export type Carrier = {
  /** The alternate names of the carrier */
  aliases: string[] | null;
  /** The UI friendly name for the carrier */
  display_name: string;
  /** The carrier ID */
  id: string;
  inserted_at: string;
  /** The name of the carrier */
  name: string;
  /** The tracking link template for the carrier */
  tracking_link_template: string | null;
  /** The tracking number lengths of the carrier */
  tracking_number_lengths: number[] | null;
  /** The tracking number prefixes of the carrier */
  tracking_number_prefixes: string[] | null;
  updated_at: string;
};
export type CreateCarrier = {
  /** The alternate names of the carrier */
  aliases?: string[] | null;
  /** The UI friendly name for the carrier */
  display_name: string;
  /** The name of the carrier */
  name: string;
  /** The tracking link template for the carrier */
  tracking_link_template?: string | null;
  /** The tracking number lengths of the carrier */
  tracking_number_lengths?: number[] | null;
  /** The tracking number prefixes of the carrier */
  tracking_number_prefixes?: string[] | null;
};
export type VendorServiceMethodMapping = {
  /** The vendor service method mapping ID */
  id: string;
  inserted_at: string;
  /** Service Method */
  service_method: {
    /** The carrier ID */
    carrier_id: string;
    /** The UI friendly name for the service method */
    display_name: string;
    /** The service method ID */
    id: string;
    inserted_at: string;
    /** The name of the service method */
    name: string;
    service_level_selection_strategy?:
      | ("priority" | "weight" | "incoterm")
      | null;
    service_levels: ServiceLevel[] | null;
    service_method_levels: ServiceMethodLevel[];
    updated_at: string;
  } | null;
  /** The service method ID */
  service_method_id: string;
  updated_at: string;
  /** Vendor */
  vendor: {
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  /** The vendor ID */
  vendor_id: string;
  /** The vendor service method name */
  vendor_service_method: string;
};
export type UpdateVendorServiceMethodMapping = {
  /** The service method ID */
  service_method_id?: string;
  /** The vendor service method name */
  vendor_service_method?: string;
};
export type Shipper = {
  /** The enabled label providers for the shipper */
  enabled_label_providers: ("shipium" | "easy_post")[];
  /** The external reference for the shipper */
  external_reference: string | null;
  /** The shipper ID */
  id: string;
  /** Creation timestamp */
  inserted_at: string;
  /** The shipper name */
  name: string;
  /** Determines whether rating is enabled for the shipper */
  rating_enabled: boolean;
  /** Update timestamp */
  updated_at: string;
};
export type CreateShipper = {
  /** The enabled label providers for the shipper */
  enabled_label_providers?: ("shipium" | "easy_post")[];
  /** The external reference for the shipper */
  external_reference: string | null;
  /** The shipper name */
  name: string;
  /** Determines whether rating is enabled for the shipper */
  rating_enabled?: boolean;
};
export type RateCard = {
  /** The type of calculation to perform on the rate card */
  calculation_type?: ("published" | "discount" | "static") | null;
  /** Carrier */
  carrier: {
    /** The alternate names of the carrier */
    aliases: string[] | null;
    /** The UI friendly name for the carrier */
    display_name: string;
    /** The carrier ID */
    id: string;
    inserted_at: string;
    /** The name of the carrier */
    name: string;
    /** The tracking link template for the carrier */
    tracking_link_template: string | null;
    /** The tracking number lengths of the carrier */
    tracking_number_lengths: number[] | null;
    /** The tracking number prefixes of the carrier */
    tracking_number_prefixes: string[] | null;
    updated_at: string;
  } | null;
  /** The carrier ID */
  carrier_id: string | null;
  /** The destination address type */
  destination_address_type?: ("residential" | "commercial") | null;
  /** The effective or start date of the rate card */
  effective_at: string;
  /** The expiration or end date of the rate card */
  expires_at: string;
  /** The fulfillment center ID */
  fulfillment_center_id: string | null;
  /** The rate card ID */
  id: string;
  inserted_at: string;
  /** The number of rate records in the rate card */
  rate_records_count: number;
  /** The type of rate */
  rate_type: "buy" | "sell";
  /** Service Level */
  service_level: {
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  /** The service level ID */
  service_level_id: string;
  /** Service Method */
  service_method: {
    /** The carrier ID */
    carrier_id: string;
    /** The UI friendly name for the service method */
    display_name: string;
    /** The service method ID */
    id: string;
    inserted_at: string;
    /** The name of the service method */
    name: string;
    service_level_selection_strategy?:
      | ("priority" | "weight" | "incoterm")
      | null;
    service_levels: ServiceLevel[] | null;
    service_method_levels: ServiceMethodLevel[];
    updated_at: string;
  } | null;
  /** The service method ID */
  service_method_id: string | null;
  /** Shipper */
  shipper?: {
    /** The enabled label providers for the shipper */
    enabled_label_providers: ("shipium" | "easy_post")[];
    /** The external reference for the shipper */
    external_reference: string | null;
    /** The shipper ID */
    id: string;
    /** Creation timestamp */
    inserted_at: string;
    /** The shipper name */
    name: string;
    /** Determines whether rating is enabled for the shipper */
    rating_enabled: boolean;
    /** Update timestamp */
    updated_at: string;
  } | null;
  /** The shipper ID */
  shipper_id?: string | null;
  updated_at: string;
};
export type RateCardRead = {
  /** The type of calculation to perform on the rate card */
  calculation_type?: ("published" | "discount" | "static") | null;
  /** Carrier */
  carrier: {
    /** The alternate names of the carrier */
    aliases: string[] | null;
    /** The UI friendly name for the carrier */
    display_name: string;
    /** The carrier ID */
    id: string;
    inserted_at: string;
    /** The name of the carrier */
    name: string;
    /** The tracking link template for the carrier */
    tracking_link_template: string | null;
    /** The tracking number lengths of the carrier */
    tracking_number_lengths: number[] | null;
    /** The tracking number prefixes of the carrier */
    tracking_number_prefixes: string[] | null;
    updated_at: string;
  } | null;
  /** The carrier ID */
  carrier_id: string | null;
  /** The destination address type */
  destination_address_type?: ("residential" | "commercial") | null;
  /** The effective or start date of the rate card */
  effective_at: string;
  /** The expiration or end date of the rate card */
  expires_at: string;
  /** FulfillmentCenter */
  fulfillment_center: {
    /** The address context used to identify the fulfillment center. */
    address: {
      city: string;
      company?: string | null;
      country: string;
      email?: string | null;
      external_location_id?: string | null;
      line1: string;
      line2?: string | null;
      line3?: string | null;
      name: string;
      phone?: string | null;
      postal_code?: string | null;
      state?: string | null;
      type: "residential" | "commercial";
    } | null;
    /** The alias used to identify the fulfillment center. */
    alias: string;
    /** Fulfillment Center ID */
    id: string;
    /** Creation timestamp */
    inserted_at: string;
    /** The human readable name used identify the fulfillment center. */
    name: string;
    /** The Shipium Origin ID used to identify the fulfillment center. */
    shipium_origin_id: string;
    /** Update timestamp */
    updated_at: string;
  } | null;
  /** The fulfillment center ID */
  fulfillment_center_id: string | null;
  /** The rate card ID */
  id: string;
  inserted_at: string;
  /** The number of rate records in the rate card */
  rate_records_count: number;
  /** The type of rate */
  rate_type: "buy" | "sell";
  /** Service Level */
  service_level: {
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  /** The service level ID */
  service_level_id: string;
  /** Service Method */
  service_method: {
    /** The carrier ID */
    carrier_id: string;
    /** The UI friendly name for the service method */
    display_name: string;
    /** The service method ID */
    id: string;
    inserted_at: string;
    /** The name of the service method */
    name: string;
    service_level_selection_strategy?:
      | ("priority" | "weight" | "incoterm")
      | null;
    service_levels: ServiceLevel[] | null;
    service_method_levels: ServiceMethodLevel[];
    updated_at: string;
  } | null;
  /** The service method ID */
  service_method_id: string | null;
  /** Shipper */
  shipper?: {
    /** The enabled label providers for the shipper */
    enabled_label_providers: ("shipium" | "easy_post")[];
    /** The external reference for the shipper */
    external_reference: string | null;
    /** The shipper ID */
    id: string;
    /** Creation timestamp */
    inserted_at: string;
    /** The shipper name */
    name: string;
    /** Determines whether rating is enabled for the shipper */
    rating_enabled: boolean;
    /** Update timestamp */
    updated_at: string;
  } | null;
  /** The shipper ID */
  shipper_id?: string | null;
  updated_at: string;
};
export type UpdateRateCard = {
  /** The carrier ID */
  carrier_id?: string | null;
  /** The effective or start date of the rate card */
  effective_at?: string;
  /** The expiration or end date of the rate card */
  expires_at?: string;
  /** The fulfillment center ID */
  fulfillment_center_id?: string | null;
  /** The type of rate */
  rate_type?: "buy" | "sell";
  /** The service level ID */
  service_level_id?: string;
  /** The service method ID */
  service_method_id?: string | null;
  /** The shipper ID */
  shipper_id?: string | null;
};
export type ServiceMethod = {
  /** The carrier ID */
  carrier_id: string;
  /** The UI friendly name for the service method */
  display_name: string;
  /** The service method ID */
  id: string;
  inserted_at: string;
  /** The name of the service method */
  name: string;
  service_level_selection_strategy?:
    | ("priority" | "weight" | "incoterm")
    | null;
  service_levels: ServiceLevel[] | null;
  service_method_levels: ServiceMethodLevel[];
  updated_at: string;
};
export type Accessorial = {
  calculate_fuel: boolean;
  default_rate: number;
  description: string;
  id: string;
  inserted_at: string;
  name: string;
  updated_at: string;
};
export type UpdateAccessorial = {
  calculate_fuel?: boolean;
  default_rate?: number;
  description?: string;
  name?: string;
};
export type BillingAccount = {
  /** Account number used to identify the billing account */
  account_number: string;
  /** The address where the account will be billed to */
  billing_address: {
    city: string;
    company?: string | null;
    country: string;
    email?: string | null;
    external_location_id?: string | null;
    line1: string;
    line2?: string | null;
    line3?: string | null;
    name: string;
    phone?: string | null;
    postal_code?: string | null;
    state?: string | null;
    type: "residential" | "commercial";
  };
  /** Carrier Name used to identify the billing account */
  carrier_name: "fedex" | "usps" | "ups" | "dhl";
  /** Billing Account ID */
  id: string;
  /** Creation timestamp */
  inserted_at: string;
  /** Unique name used to identify the billing account */
  name: string;
  /** Service method used to identify the billing account */
  service_method?: string | null;
  shipper_id: string;
  /** Update timestamp */
  updated_at: string;
};
export type CreateBillingAccount = {
  /** Account number used to identify the billing account */
  account_number: string;
  /** The address where the account will be billed to */
  billing_address: {
    city: string;
    company?: string | null;
    country: string;
    email?: string | null;
    external_location_id?: string | null;
    line1: string;
    line2?: string | null;
    line3?: string | null;
    name: string;
    phone?: string | null;
    postal_code?: string | null;
    state?: string | null;
    type: "residential" | "commercial";
  };
  /** Carrier Name used to identify the billing account */
  carrier_name: "fedex" | "usps" | "ups" | "dhl";
  /** Unique name used to identify the billing account */
  name: string;
  /** Service method used to identify the billing account */
  service_method?: string | null;
};
export type FulfillmentCenter = {
  /** The address context used to identify the fulfillment center. */
  address: {
    city: string;
    company?: string | null;
    country: string;
    email?: string | null;
    external_location_id?: string | null;
    line1: string;
    line2?: string | null;
    line3?: string | null;
    name: string;
    phone?: string | null;
    postal_code?: string | null;
    state?: string | null;
    type: "residential" | "commercial";
  } | null;
  /** The alias used to identify the fulfillment center. */
  alias: string;
  /** Creation timestamp */
  inserted_at: string;
  /** The human readable name used identify the fulfillment center. */
  name: string;
  /** The Shipium Origin ID used to identify the fulfillment center. */
  shipium_origin_id: string;
  /** Update timestamp */
  updated_at: string;
};
export type FulfillmentCenterRead = {
  /** The address context used to identify the fulfillment center. */
  address: {
    city: string;
    company?: string | null;
    country: string;
    email?: string | null;
    external_location_id?: string | null;
    line1: string;
    line2?: string | null;
    line3?: string | null;
    name: string;
    phone?: string | null;
    postal_code?: string | null;
    state?: string | null;
    type: "residential" | "commercial";
  } | null;
  /** The alias used to identify the fulfillment center. */
  alias: string;
  /** Fulfillment Center ID */
  id: string;
  /** Creation timestamp */
  inserted_at: string;
  /** The human readable name used identify the fulfillment center. */
  name: string;
  /** The Shipium Origin ID used to identify the fulfillment center. */
  shipium_origin_id: string;
  /** Update timestamp */
  updated_at: string;
};
export type CreateFulfillmentCenter = {
  /** The address context used to identify the fulfillment center. */
  address: {
    city: string;
    company?: string | null;
    country: string;
    email?: string | null;
    external_location_id?: string | null;
    line1: string;
    line2?: string | null;
    line3?: string | null;
    name: string;
    phone?: string | null;
    postal_code?: string | null;
    state?: string | null;
    type: "residential" | "commercial";
  } | null;
  /** The alias used to identify the fulfillment center. */
  alias: string;
  /** The human readable name used identify the fulfillment center. */
  name: string;
  /** The Shipium Origin ID used to identify the fulfillment center. */
  shipium_origin_id: string;
};
export type VendorCarrierMapping = {
  /** Carrier */
  carrier: {
    /** The alternate names of the carrier */
    aliases: string[] | null;
    /** The UI friendly name for the carrier */
    display_name: string;
    /** The carrier ID */
    id: string;
    inserted_at: string;
    /** The name of the carrier */
    name: string;
    /** The tracking link template for the carrier */
    tracking_link_template: string | null;
    /** The tracking number lengths of the carrier */
    tracking_number_lengths: number[] | null;
    /** The tracking number prefixes of the carrier */
    tracking_number_prefixes: string[] | null;
    updated_at: string;
  } | null;
  /** The carrier ID */
  carrier_id: string;
  /** The vendor carrier mapping ID */
  id: string;
  inserted_at: string;
  updated_at: string;
  /** Vendor */
  vendor: {
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  /** The vendor carrier name or id */
  vendor_carrier: string;
  /** The vendor ID */
  vendor_id: string;
};
export type CreateVendorCarrierMapping = {
  /** The carrier ID */
  carrier_id: string;
  /** The vendor carrier name or id */
  vendor_carrier: string;
};
export type ShowRegistration = {
  /** The current date the carrier estimated for delivery */
  carrier_estimated_delivery_date?: string | null;
  carrier_name?: string;
  /** Time a delivered tracking event occurs for this registration */
  delivered_at?: string | null;
  /** The first date the carrier estimated for delivery */
  initial_carrier_estimated_delivery_date?: string | null;
  label_provider?: string;
  /** Time shipment is registered with third party tracking provider */
  registered_at?: string | null;
  shipper_external_reference?: string;
  /** Parcel shipper id or external reference */
  shipper_id?: string;
  source?: string | null;
  /** The carrier tracking number */
  tracking_number?: string;
};
export type UpdateRegistration = {
  /** The current date the carrier estimated for delivery */
  carrier_estimated_delivery_date?: string | null;
  carrier_name?: string;
  /** Time a delivered tracking event occurs for this registration */
  delivered_at?: string | null;
  /** The first date the carrier estimated for delivery */
  initial_carrier_estimated_delivery_date?: string | null;
  label_provider?: string;
  /** Time shipment is registered with third party tracking provider */
  registered_at?: string | null;
  shipper_external_reference?: string;
  /** Parcel shipper id or external reference */
  shipper_id?: string;
  source?: string | null;
  /** The carrier tracking number */
  tracking_number?: string;
};
export type UpdateFulfillmentCenter = {
  /** The address context used to identify the fulfillment center. */
  address?: {
    city: string;
    company?: string | null;
    country: string;
    email?: string | null;
    external_location_id?: string | null;
    line1: string;
    line2?: string | null;
    line3?: string | null;
    name: string;
    phone?: string | null;
    postal_code?: string | null;
    state?: string | null;
    type: "residential" | "commercial";
  } | null;
  /** The alias used to identify the fulfillment center. */
  alias?: string;
  /** The human readable name used identify the fulfillment center. */
  name?: string;
  /** The Shipium Origin ID used to identify the fulfillment center. */
  shipium_origin_id?: string;
};
export type CreateShipperAccessorial = {
  /** Accessorial */
  accessorial?: {
    calculate_fuel: boolean;
    default_rate: number;
    description: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  /** The accessorial ID */
  accessorial_id?: string;
  /** The carrier ID */
  carrier_id?: string | null;
  /** A description for the purpose of the shipper accessorial */
  description?: string | null;
  /** The destination address type */
  destination_address_type?: ("residential" | "commercial") | null;
  /** The effective or start date of the shipper accessorial */
  effective_at: string;
  /** The expiration or end date of the shipper accessorial */
  expires_at: string;
  fulfillment_center_id?: string | null;
  /** The name of the shipper accessorial */
  name: string;
  /** The rate of the shipper accessorial */
  rate: number;
  /** The service level ID */
  service_level_id?: string | null;
  /** The service method ID */
  service_method_id?: string | null;
  shipper_id?: string | null;
};
export type UpdateBillingAccount = {
  /** Account number used to identify the billing account */
  account_number?: string;
  /** The address where the account will be billed to */
  billing_address?: {
    city: string;
    company?: string | null;
    country: string;
    email?: string | null;
    external_location_id?: string | null;
    line1: string;
    line2?: string | null;
    line3?: string | null;
    name: string;
    phone?: string | null;
    postal_code?: string | null;
    state?: string | null;
    type: "residential" | "commercial";
  };
  /** Carrier Name used to identify the billing account */
  carrier_name?: "fedex" | "usps" | "ups" | "dhl";
  /** Unique name used to identify the billing account */
  name?: string;
  /** Service method used to identify the billing account */
  service_method?: string | null;
};
export type ShowTrackingEvent = {
  carrier_name?: string;
  country_code?: string | null;
  country_subdivision_code?: string | null;
  description?: string | null;
  /** Creation timestamp */
  inserted_at?: string;
  locality?: string | null;
  /** Time the event occurred */
  occurred_at?: string;
  postal_code?: string | null;
  source?: string;
  status?:
    | "registered"
    | "label_printed"
    | "delivered"
    | "exception"
    | "in_transit"
    | "out_for_delivery"
    | "unknown";
  tracking_number?: string;
  /** Update timestamp */
  updated_at?: string;
};
export type ShowTrackingEventRead = {
  carrier_name?: string;
  country_code?: string | null;
  country_subdivision_code?: string | null;
  description?: string | null;
  /** Creation timestamp */
  inserted_at?: string;
  locality?: string | null;
  /** Time the event occurred */
  occurred_at?: string;
  postal_code?: string | null;
  source?: string;
  status?:
    | "registered"
    | "label_printed"
    | "delivered"
    | "exception"
    | "in_transit"
    | "out_for_delivery"
    | "unknown";
  /** Auto-generated id */
  tracking_event_id?: string;
  tracking_number?: string;
  /** Update timestamp */
  updated_at?: string;
};
export type UpdateTrackingEvent = {
  carrier_name?: string;
  country_code?: string | null;
  country_subdivision_code?: string | null;
  description?: string | null;
  locality?: string | null;
  /** Time the event occurred */
  occurred_at?: string;
  postal_code?: string | null;
  source?: string;
  status?:
    | "registered"
    | "label_printed"
    | "delivered"
    | "exception"
    | "in_transit"
    | "out_for_delivery"
    | "unknown";
  tracking_number?: string;
};
export type Vendor = {
  display_name: string;
  id: string;
  inserted_at: string;
  name: string;
  updated_at: string;
};
export type UpdateVendor = {
  display_name?: string;
  name?: string;
};
export type Rating = {
  additional_accessorial_rates?: {
    name: string;
    rate: number;
  }[];
  buy_rate: number | null;
  buy_rate_breakdown: {
    accessorial_breakdown?: {
      carrier_accessorial_name: string;
      name: string;
      rate: string;
      [key: string]: any;
    }[];
    accessorial_surcharge?: number;
    base_rate?: number;
  } | null;
  error?: string | null;
  external_reference: string;
  id: string;
  inserted_at: string;
  is_rerate: boolean;
  parcel_id: string;
  rating_engine_parameters?: {
    [key: string]: any;
  } | null;
  /** Rerate a parcel */
  rerate_parameters: {
    additional_accessorial_rates?: {
      name: string;
      rate: number;
    }[];
    external_reference: string;
    /** The address where the parcel will be picked up from. */
    from_address?: {
      city: string;
      company?: string | null;
      country: string;
      email?: string | null;
      external_location_id?: string | null;
      line1: string;
      line2?: string | null;
      line3?: string | null;
      name: string;
      phone?: string | null;
      postal_code?: string | null;
      state?: string | null;
      type: "residential" | "commercial";
    };
    /** The height of the parcel in inches. */
    height?: number;
    /** The length of the parcel in inches. */
    length?: number;
    /** The service method that will be used when rerating the parcel. */
    service_method?: string;
    /** The address where the parcel will be delivered to. */
    to_address?: {
      city: string;
      company?: string | null;
      country: string;
      email?: string | null;
      external_location_id?: string | null;
      line1: string;
      line2?: string | null;
      line3?: string | null;
      name: string;
      phone?: string | null;
      postal_code?: string | null;
      state?: string | null;
      type: "residential" | "commercial";
    };
    /** The weight of the parcel in pounds. */
    weight?: number;
    /** The width of the parcel in inches. */
    width?: number;
  } | null;
  sell_rate: number | null;
  sell_rate_breakdown: {
    accessorial_breakdown?: {
      carrier_accessorial_name: string;
      name: string;
      rate: string;
      [key: string]: any;
    }[];
    accessorial_surcharge?: number;
    base_rate?: number;
  } | null;
  /** Service Level */
  service_level: {
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  /** The service level ID */
  service_level_id: string | null;
  tms_buy_rate: number;
  tms_rates: {
    rates?: {
      carrierSurchargeId?: string | null;
      lineItemType?: string;
      name?: string;
      rate?: number;
      surchargeType?: string;
      [key: string]: any;
    }[];
    [key: string]: any;
  };
  tms_reference: string;
  updated_at: string;
  zone_code: string | null;
};
export type Item = {
  description: string;
  hazmat?: boolean | null;
  /** The hazmat info for this item. Only required if hazmat is true. */
  hazmat_info?: {
    /** The number of batteries in the item */
    battery_count?: number | null;
    /** Whether the battery is installed or uninstalled */
    battery_installation?: ("installed" | "uninstalled") | null;
    /** Whether the hazmat item contains lithium */
    contains_lithium?: boolean;
    /** The hazard class for the hazmat item */
    hazard_class?: string;
    /** Whether the hazmat item is limited quantity */
    limited_quantity?: boolean;
    /** The amount of lithium ion in the item. Value is in grams. */
    lithium_ion_content?: number | null;
    packing_group?: ("i" | "ii" | "iii") | null;
    proper_shipping_name?: string;
    transport_mode?:
      | "any"
      | "cargo_aircraft_only"
      | "ground"
      | "passenger_and_cargo_aircraft";
    /** The UN ID for the hazmat item. See https://www.ecfr.gov/current/title-49/subtitle-B/chapter-I/subchapter-C/part-172/subpart-B/section-172.101 */
    un_id?: string;
  } | null;
  origin_country: string;
  /** The total price (unit_price * quantity) of the item in cents */
  price: number;
  product_sku: string;
  quantity: number;
  tariff_code?: string | null;
  /** The price of a single unit of the item in cents */
  unit_price?: number;
  weight: number;
};
export type SubParcel = {
  /** The height of the subparcel in inches. */
  height: number;
  items: Item[];
  /** It's a reference for each subparcel that is visible on the label. */
  label_reference: string | null;
  /** The length of the subparcel in inches. */
  length: number;
  /** The packaging material used to ship the subparcel */
  packaging_material?:
    | (
        | "box"
        | "envelope"
        | "fedex_one_rate_envelope"
        | "fedex_one_rate_extra_large_box"
        | "fedex_one_rate_large_box"
        | "fedex_one_rate_medium_box"
        | "fedex_one_rate_pak"
        | "fedex_one_rate_small_box"
        | "fedex_one_rate_tube"
        | "flat_pack"
      )
    | null;
  /** The weight of the subparcel in pounds. */
  weight: number;
  /** The width of the subparcel in inches. */
  width: number;
};
export type Document = {
  /** Encoding of the document */
  document_encoding?: string;
  /** Format of the document */
  document_format: string;
  document_type?:
    | "commercial_invoice"
    | "electronic_export_information"
    | "cn22"
    | "air_waybill"
    | "multiple"
    | "nafta_certificate_of_origin"
    | "high_value_report"
    | "cod_return_label"
    | "order_summary";
  /** The document encoded as a binary */
  encoded_document: string;
  /** When the document expires */
  expires_at?: string;
  /** Creation timestamp */
  inserted_at: string;
  /** Is the document electronic */
  is_electronic?: boolean;
  /** The parcel the document is associated with */
  parcel_id: string;
  /** Update timestamp */
  updated_at: string;
};
export type DocumentRead = {
  /** Encoding of the document */
  document_encoding?: string;
  /** Format of the document */
  document_format: string;
  document_type?:
    | "commercial_invoice"
    | "electronic_export_information"
    | "cn22"
    | "air_waybill"
    | "multiple"
    | "nafta_certificate_of_origin"
    | "high_value_report"
    | "cod_return_label"
    | "order_summary";
  /** The document encoded as a binary */
  encoded_document: string;
  /** When the document expires */
  expires_at?: string;
  /** Document ID */
  id: string;
  /** Creation timestamp */
  inserted_at: string;
  /** Is the document electronic */
  is_electronic?: boolean;
  /** The parcel the document is associated with */
  parcel_id: string;
  /** Update timestamp */
  updated_at: string;
};
export type TrackingEvent = {
  carrier_name?: string;
  country_code?: string | null;
  country_subdivision_code?: string | null;
  description?: string | null;
  /** Creation timestamp */
  inserted_at?: string;
  locality?: string | null;
  /** Time the event occurred */
  occurred_at: string;
  postal_code?: string | null;
  source: string;
  status:
    | "registered"
    | "label_printed"
    | "delivered"
    | "exception"
    | "in_transit"
    | "out_for_delivery"
    | "unknown";
  tracking_number: string;
  /** Update timestamp */
  updated_at?: string;
};
export type TrackingEventRead = {
  carrier_name?: string;
  country_code?: string | null;
  country_subdivision_code?: string | null;
  description?: string | null;
  /** Creation timestamp */
  inserted_at?: string;
  locality?: string | null;
  /** Time the event occurred */
  occurred_at: string;
  postal_code?: string | null;
  source: string;
  status:
    | "registered"
    | "label_printed"
    | "delivered"
    | "exception"
    | "in_transit"
    | "out_for_delivery"
    | "unknown";
  /** Auto-generated id */
  tracking_event_id?: string;
  tracking_number: string;
  /** Update timestamp */
  updated_at?: string;
};
export type RatingRead = {
  additional_accessorial_rates?: {
    name: string;
    rate: number;
  }[];
  buy_rate: number | null;
  buy_rate_breakdown: {
    accessorial_breakdown?: {
      carrier_accessorial_name: string;
      name: string;
      rate: string;
      [key: string]: any;
    }[];
    accessorial_surcharge?: number;
    base_rate?: number;
  } | null;
  error?: string | null;
  external_reference: string;
  id: string;
  inserted_at: string;
  is_rerate: boolean;
  parcel: {
    id: string;
    /** The external source id of the parcel e.g. order_number. */
    external_source_id: string | null;
    /** The zone code of the parcel from the TMS */
    tms_zone_code: string | null;
    /** The external order number of the parcel e.g. In S1C, this would be the `order_number`. */
    external_order_number: string | null;
    /** The fulfillment center id of the parcel */
    fulfillment_center_id: string | null;
    /** The address where the parcel will be returned to. */
    return_address: {
      city: string;
      company?: string | null;
      country: string;
      email?: string | null;
      external_location_id?: string | null;
      line1: string;
      line2?: string | null;
      line3?: string | null;
      name: string;
      phone?: string | null;
      postal_code?: string | null;
      state?: string | null;
      type: "residential" | "commercial";
    } | null;
    ratings: ({
      [key: string]: any;
    } | null)[];
    items: Item[];
    carrier_selection_method: string | null;
    /** FulfillmentCenter */
    fulfillment_center: {
      /** The address context used to identify the fulfillment center. */
      address: {
        city: string;
        company?: string | null;
        country: string;
        email?: string | null;
        external_location_id?: string | null;
        line1: string;
        line2?: string | null;
        line3?: string | null;
        name: string;
        phone?: string | null;
        postal_code?: string | null;
        state?: string | null;
        type: "residential" | "commercial";
      } | null;
      /** The alias used to identify the fulfillment center. */
      alias: string;
      /** Fulfillment Center ID */
      id: string;
      /** Creation timestamp */
      inserted_at: string;
      /** The human readable name used identify the fulfillment center. */
      name: string;
      /** The Shipium Origin ID used to identify the fulfillment center. */
      shipium_origin_id: string;
      /** Update timestamp */
      updated_at: string;
    } | null;
    /** Shipper */
    shipper: {
      /** The enabled label providers for the shipper */
      enabled_label_providers: ("shipium" | "easy_post")[];
      /** The external reference for the shipper */
      external_reference: string | null;
      /** The shipper ID */
      id: string;
      /** Creation timestamp */
      inserted_at: string;
      /** The shipper name */
      name: string;
      /** Determines whether rating is enabled for the shipper */
      rating_enabled: boolean;
      /** Update timestamp */
      updated_at: string;
    } | null;
    /** Whether or not the parcel should be delivered on a Saturday. */
    saturday_delivery: boolean | null;
    /** The weight of the parcel in pounds. */
    weight: number;
    /** The incoterm that will be used to ship the parcel. */
    incoterm: ("DAP" | "DDP" | "DDU" | "DPU" | "FCA") | null;
    /** The number of business days that the parcel will be in transit. */
    business_days_in_transit: number | null;
    /** The billing account id of the parcel */
    billing_account_id: string | null;
    voided_at: string | null;
    desired_delivery_date: string | null;
    /** Creation timestamp */
    inserted_at: string;
    sub_parcels_count: number;
    /** Update timestamp */
    updated_at: string;
    tracking_url: string;
    /** The preferred label provider to use to generate the shipping label. */
    label_provider: ("easy_post" | "shipium" | "usps_direct") | null;
    sub_parcels: SubParcel[];
    /** The service method that will be used to ship the parcel. */
    service_method: string | null;
    /** The external label id of the parcel */
    external_label_id: string | null;
    tracking_number: string;
    /** The address where the parcel will be delivered to. */
    to_address: {
      city: string;
      company?: string | null;
      country: string;
      email?: string | null;
      external_location_id?: string | null;
      line1: string;
      line2?: string | null;
      line3?: string | null;
      name: string;
      phone?: string | null;
      postal_code?: string | null;
      state?: string | null;
      type: "residential" | "commercial";
    };
    /** The rate (cost of shipping the parcel) that will be used to generate the shipping label. */
    label_rate: number;
    /** The ship option that will be used to ship the parcel. */
    carrier_ship_option: string | null;
    documents?: DocumentRead[];
    /** The service method that will be used to ship the parcel if the first one fails. */
    fallback_service_method: string | null;
    /** The address where the parcel will be picked up from. */
    from_address: {
      city: string;
      company?: string | null;
      country: string;
      email?: string | null;
      external_location_id?: string | null;
      line1: string;
      line2?: string | null;
      line3?: string | null;
      name: string;
      phone?: string | null;
      postal_code?: string | null;
      state?: string | null;
      type: "residential" | "commercial";
    };
    /** The length of the parcel in inches. */
    length: number;
    tracking_events: TrackingEventRead[];
    /** The height of the parcel in inches. */
    height: number;
    shipper_id: string;
    /** The width of the parcel in inches. */
    width: number;
    /** The preferred carrier that will be used to ship the parcel. */
    carrier_name: string | null;
    /** Whether or not the parcel should be billed to a third party. */
    third_party_billing_enabled: boolean | null;
    shipping_label: string | null;
    /** The service method id of the generated label */
    label_service_method_id: string | null;
    /** The preferred label format to use to generate the shipping label. */
    label_format: string | null;
    /** The billable weight of the parcel from the TMS */
    tms_billable_weight: string | null;
    /** The packaging material used to ship the parcel */
    packaging_material:
      | (
          | "box"
          | "envelope"
          | "fedex_one_rate_envelope"
          | "fedex_one_rate_extra_large_box"
          | "fedex_one_rate_large_box"
          | "fedex_one_rate_medium_box"
          | "fedex_one_rate_pak"
          | "fedex_one_rate_small_box"
          | "fedex_one_rate_tube"
          | "flat_pack"
        )
      | null;
    /** ISO 4217 three-letter currency code. */
    currency_code: string;
    expected_shipped_at_date: string | null;
    /** Associates a parcel with a record on an external system (WMS) */
    external_id: string | null;
    /** if null then the default signature option NO_SIGNATURE will be used. */
    signature_required:
      | (
          | "signature"
          | "resident_signature"
          | "adult_signature"
          | "adult_resident_signature"
        )
      | null;
    /** Service Method */
    label_service_method: {
      /** The carrier ID */
      carrier_id: string;
      /** The UI friendly name for the service method */
      display_name: string;
      /** The service method ID */
      id: string;
      inserted_at: string;
      /** The name of the service method */
      name: string;
      service_level_selection_strategy?:
        | ("priority" | "weight" | "incoterm")
        | null;
      service_levels: ServiceLevel[] | null;
      service_method_levels: ServiceMethodLevel[];
      updated_at: string;
    } | null;
    /** Whether or not the parcel is a return */
    is_return: boolean | null;
  } | null;
  parcel_id: string;
  rating_engine_parameters?: {
    [key: string]: any;
  } | null;
  /** Rerate a parcel */
  rerate_parameters: {
    additional_accessorial_rates?: {
      name: string;
      rate: number;
    }[];
    external_reference: string;
    /** The address where the parcel will be picked up from. */
    from_address?: {
      city: string;
      company?: string | null;
      country: string;
      email?: string | null;
      external_location_id?: string | null;
      line1: string;
      line2?: string | null;
      line3?: string | null;
      name: string;
      phone?: string | null;
      postal_code?: string | null;
      state?: string | null;
      type: "residential" | "commercial";
    };
    /** The height of the parcel in inches. */
    height?: number;
    /** The length of the parcel in inches. */
    length?: number;
    /** The service method that will be used when rerating the parcel. */
    service_method?: string;
    /** The address where the parcel will be delivered to. */
    to_address?: {
      city: string;
      company?: string | null;
      country: string;
      email?: string | null;
      external_location_id?: string | null;
      line1: string;
      line2?: string | null;
      line3?: string | null;
      name: string;
      phone?: string | null;
      postal_code?: string | null;
      state?: string | null;
      type: "residential" | "commercial";
    };
    /** The weight of the parcel in pounds. */
    weight?: number;
    /** The width of the parcel in inches. */
    width?: number;
  } | null;
  sell_rate: number | null;
  sell_rate_breakdown: {
    accessorial_breakdown?: {
      carrier_accessorial_name: string;
      name: string;
      rate: string;
      [key: string]: any;
    }[];
    accessorial_surcharge?: number;
    base_rate?: number;
  } | null;
  /** Service Level */
  service_level: {
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  /** The service level ID */
  service_level_id: string | null;
  tms_buy_rate: number;
  tms_rates: {
    rates?: {
      carrierSurchargeId?: string | null;
      lineItemType?: string;
      name?: string;
      rate?: number;
      surchargeType?: string;
      [key: string]: any;
    }[];
    [key: string]: any;
  };
  tms_reference: string;
  updated_at: string;
  zone_code: string | null;
};
export type ShowParcel = {
  id: string;
  /** The external source id of the parcel e.g. order_number. */
  external_source_id: string | null;
  /** The zone code of the parcel from the TMS */
  tms_zone_code: string | null;
  /** The external order number of the parcel e.g. In S1C, this would be the `order_number`. */
  external_order_number: string | null;
  /** The fulfillment center id of the parcel */
  fulfillment_center_id: string | null;
  /** The address where the parcel will be returned to. */
  return_address: {
    city: string;
    company?: string | null;
    country: string;
    email?: string | null;
    external_location_id?: string | null;
    line1: string;
    line2?: string | null;
    line3?: string | null;
    name: string;
    phone?: string | null;
    postal_code?: string | null;
    state?: string | null;
    type: "residential" | "commercial";
  } | null;
  ratings: Rating[];
  items: Item[];
  carrier_selection_method: string | null;
  /** Shipper */
  shipper: {
    /** The enabled label providers for the shipper */
    enabled_label_providers: ("shipium" | "easy_post")[];
    /** The external reference for the shipper */
    external_reference: string | null;
    /** The shipper ID */
    id: string;
    /** Creation timestamp */
    inserted_at: string;
    /** The shipper name */
    name: string;
    /** Determines whether rating is enabled for the shipper */
    rating_enabled: boolean;
    /** Update timestamp */
    updated_at: string;
  } | null;
  /** Whether or not the parcel should be delivered on a Saturday. */
  saturday_delivery: boolean | null;
  /** The weight of the parcel in pounds. */
  weight: number;
  /** The incoterm that will be used to ship the parcel. */
  incoterm: ("DAP" | "DDP" | "DDU" | "DPU" | "FCA") | null;
  /** The number of business days that the parcel will be in transit. */
  business_days_in_transit: number | null;
  /** The billing account id of the parcel */
  billing_account_id: string | null;
  voided_at: string | null;
  desired_delivery_date: string | null;
  /** Creation timestamp */
  inserted_at: string;
  /** Update timestamp */
  updated_at: string;
  tracking_url: string;
  /** The preferred label provider to use to generate the shipping label. */
  label_provider: ("easy_post" | "shipium" | "usps_direct") | null;
  sub_parcels: SubParcel[];
  /** The service method that will be used to ship the parcel. */
  service_method: string | null;
  /** The external label id of the parcel */
  external_label_id: string | null;
  tracking_number: string;
  /** The address where the parcel will be delivered to. */
  to_address: {
    city: string;
    company?: string | null;
    country: string;
    email?: string | null;
    external_location_id?: string | null;
    line1: string;
    line2?: string | null;
    line3?: string | null;
    name: string;
    phone?: string | null;
    postal_code?: string | null;
    state?: string | null;
    type: "residential" | "commercial";
  };
  /** The rate (cost of shipping the parcel) that will be used to generate the shipping label. */
  label_rate: number;
  /** The ship option that will be used to ship the parcel. */
  carrier_ship_option: string | null;
  documents?: Document[];
  /** The service method that will be used to ship the parcel if the first one fails. */
  fallback_service_method: string | null;
  /** The address where the parcel will be picked up from. */
  from_address: {
    city: string;
    company?: string | null;
    country: string;
    email?: string | null;
    external_location_id?: string | null;
    line1: string;
    line2?: string | null;
    line3?: string | null;
    name: string;
    phone?: string | null;
    postal_code?: string | null;
    state?: string | null;
    type: "residential" | "commercial";
  };
  /** The length of the parcel in inches. */
  length: number;
  tracking_events: TrackingEvent[];
  /** The height of the parcel in inches. */
  height: number;
  shipper_id: string;
  /** The width of the parcel in inches. */
  width: number;
  /** The preferred carrier that will be used to ship the parcel. */
  carrier_name: string | null;
  /** Whether or not the parcel should be billed to a third party. */
  third_party_billing_enabled: boolean | null;
  shipping_label: string | null;
  /** The service method id of the generated label */
  label_service_method_id: string | null;
  /** The preferred label format to use to generate the shipping label. */
  label_format: string | null;
  /** The billable weight of the parcel from the TMS */
  tms_billable_weight: string | null;
  /** The packaging material used to ship the parcel */
  packaging_material:
    | (
        | "box"
        | "envelope"
        | "fedex_one_rate_envelope"
        | "fedex_one_rate_extra_large_box"
        | "fedex_one_rate_large_box"
        | "fedex_one_rate_medium_box"
        | "fedex_one_rate_pak"
        | "fedex_one_rate_small_box"
        | "fedex_one_rate_tube"
        | "flat_pack"
      )
    | null;
  /** ISO 4217 three-letter currency code. */
  currency_code: string;
  expected_shipped_at_date: string | null;
  /** Associates a parcel with a record on an external system (WMS) */
  external_id: string | null;
  /** if null then the default signature option NO_SIGNATURE will be used. */
  signature_required:
    | (
        | "signature"
        | "resident_signature"
        | "adult_signature"
        | "adult_resident_signature"
      )
    | null;
  /** Service Method */
  label_service_method: {
    /** The carrier ID */
    carrier_id: string;
    /** The UI friendly name for the service method */
    display_name: string;
    /** The service method ID */
    id: string;
    inserted_at: string;
    /** The name of the service method */
    name: string;
    service_level_selection_strategy?:
      | ("priority" | "weight" | "incoterm")
      | null;
    service_levels: ServiceLevel[] | null;
    service_method_levels: ServiceMethodLevel[];
    updated_at: string;
  } | null;
  /** Whether or not the parcel is a return */
  is_return: boolean | null;
};
export type ShowParcelRead = {
  id: string;
  /** The external source id of the parcel e.g. order_number. */
  external_source_id: string | null;
  /** The zone code of the parcel from the TMS */
  tms_zone_code: string | null;
  /** The external order number of the parcel e.g. In S1C, this would be the `order_number`. */
  external_order_number: string | null;
  /** The fulfillment center id of the parcel */
  fulfillment_center_id: string | null;
  /** The address where the parcel will be returned to. */
  return_address: {
    city: string;
    company?: string | null;
    country: string;
    email?: string | null;
    external_location_id?: string | null;
    line1: string;
    line2?: string | null;
    line3?: string | null;
    name: string;
    phone?: string | null;
    postal_code?: string | null;
    state?: string | null;
    type: "residential" | "commercial";
  } | null;
  ratings: RatingRead[];
  items: Item[];
  carrier_selection_method: string | null;
  /** FulfillmentCenter */
  fulfillment_center: {
    /** The address context used to identify the fulfillment center. */
    address: {
      city: string;
      company?: string | null;
      country: string;
      email?: string | null;
      external_location_id?: string | null;
      line1: string;
      line2?: string | null;
      line3?: string | null;
      name: string;
      phone?: string | null;
      postal_code?: string | null;
      state?: string | null;
      type: "residential" | "commercial";
    } | null;
    /** The alias used to identify the fulfillment center. */
    alias: string;
    /** Fulfillment Center ID */
    id: string;
    /** Creation timestamp */
    inserted_at: string;
    /** The human readable name used identify the fulfillment center. */
    name: string;
    /** The Shipium Origin ID used to identify the fulfillment center. */
    shipium_origin_id: string;
    /** Update timestamp */
    updated_at: string;
  } | null;
  /** Shipper */
  shipper: {
    /** The enabled label providers for the shipper */
    enabled_label_providers: ("shipium" | "easy_post")[];
    /** The external reference for the shipper */
    external_reference: string | null;
    /** The shipper ID */
    id: string;
    /** Creation timestamp */
    inserted_at: string;
    /** The shipper name */
    name: string;
    /** Determines whether rating is enabled for the shipper */
    rating_enabled: boolean;
    /** Update timestamp */
    updated_at: string;
  } | null;
  /** Whether or not the parcel should be delivered on a Saturday. */
  saturday_delivery: boolean | null;
  /** The weight of the parcel in pounds. */
  weight: number;
  /** The incoterm that will be used to ship the parcel. */
  incoterm: ("DAP" | "DDP" | "DDU" | "DPU" | "FCA") | null;
  /** The number of business days that the parcel will be in transit. */
  business_days_in_transit: number | null;
  /** The billing account id of the parcel */
  billing_account_id: string | null;
  voided_at: string | null;
  desired_delivery_date: string | null;
  /** Creation timestamp */
  inserted_at: string;
  sub_parcels_count: number;
  /** Update timestamp */
  updated_at: string;
  tracking_url: string;
  /** The preferred label provider to use to generate the shipping label. */
  label_provider: ("easy_post" | "shipium" | "usps_direct") | null;
  sub_parcels: SubParcel[];
  /** The service method that will be used to ship the parcel. */
  service_method: string | null;
  /** The external label id of the parcel */
  external_label_id: string | null;
  tracking_number: string;
  /** The address where the parcel will be delivered to. */
  to_address: {
    city: string;
    company?: string | null;
    country: string;
    email?: string | null;
    external_location_id?: string | null;
    line1: string;
    line2?: string | null;
    line3?: string | null;
    name: string;
    phone?: string | null;
    postal_code?: string | null;
    state?: string | null;
    type: "residential" | "commercial";
  };
  /** The rate (cost of shipping the parcel) that will be used to generate the shipping label. */
  label_rate: number;
  /** The ship option that will be used to ship the parcel. */
  carrier_ship_option: string | null;
  documents?: DocumentRead[];
  /** The service method that will be used to ship the parcel if the first one fails. */
  fallback_service_method: string | null;
  /** The address where the parcel will be picked up from. */
  from_address: {
    city: string;
    company?: string | null;
    country: string;
    email?: string | null;
    external_location_id?: string | null;
    line1: string;
    line2?: string | null;
    line3?: string | null;
    name: string;
    phone?: string | null;
    postal_code?: string | null;
    state?: string | null;
    type: "residential" | "commercial";
  };
  /** The length of the parcel in inches. */
  length: number;
  tracking_events: TrackingEventRead[];
  /** The height of the parcel in inches. */
  height: number;
  shipper_id: string;
  /** The width of the parcel in inches. */
  width: number;
  /** The preferred carrier that will be used to ship the parcel. */
  carrier_name: string | null;
  /** Whether or not the parcel should be billed to a third party. */
  third_party_billing_enabled: boolean | null;
  shipping_label: string | null;
  /** The service method id of the generated label */
  label_service_method_id: string | null;
  /** The preferred label format to use to generate the shipping label. */
  label_format: string | null;
  /** The billable weight of the parcel from the TMS */
  tms_billable_weight: string | null;
  /** The packaging material used to ship the parcel */
  packaging_material:
    | (
        | "box"
        | "envelope"
        | "fedex_one_rate_envelope"
        | "fedex_one_rate_extra_large_box"
        | "fedex_one_rate_large_box"
        | "fedex_one_rate_medium_box"
        | "fedex_one_rate_pak"
        | "fedex_one_rate_small_box"
        | "fedex_one_rate_tube"
        | "flat_pack"
      )
    | null;
  /** ISO 4217 three-letter currency code. */
  currency_code: string;
  expected_shipped_at_date: string | null;
  /** Associates a parcel with a record on an external system (WMS) */
  external_id: string | null;
  /** if null then the default signature option NO_SIGNATURE will be used. */
  signature_required:
    | (
        | "signature"
        | "resident_signature"
        | "adult_signature"
        | "adult_resident_signature"
      )
    | null;
  /** Service Method */
  label_service_method: {
    /** The carrier ID */
    carrier_id: string;
    /** The UI friendly name for the service method */
    display_name: string;
    /** The service method ID */
    id: string;
    inserted_at: string;
    /** The name of the service method */
    name: string;
    service_level_selection_strategy?:
      | ("priority" | "weight" | "incoterm")
      | null;
    service_levels: ServiceLevel[] | null;
    service_method_levels: ServiceMethodLevel[];
    updated_at: string;
  } | null;
  /** Whether or not the parcel is a return */
  is_return: boolean | null;
};
export type CreateReturnLabel = {
  /** ISO 4217 three-letter currency code. */
  currency_code: string;
  /** Associates a parcel with a record on an external system (WMS) */
  external_id?: string | null;
  /** The external source id of the parcel e.g. order_number. */
  external_source_id: string;
  /** The address where the parcel will be picked up from. */
  from_address: {
    city: string;
    company?: string | null;
    country: string;
    email?: string | null;
    external_location_id?: string | null;
    line1: string;
    line2?: string | null;
    line3?: string | null;
    name: string;
    phone?: string | null;
    postal_code?: string | null;
    state?: string | null;
    type: "residential" | "commercial";
  };
  /** The height of the parcel in inches. */
  height?: number;
  items: Item[];
  label_format?: string;
  /** The preferred label provider to use to generate the shipping label. */
  label_provider?: ("easy_post" | "shipium" | "usps_direct") | null;
  /** The length of the parcel in inches. */
  length?: number;
  /** Contains order related metadata coming from external systems */
  order_metadata?: {
    /** A carrier specific processing ID for the order */
    carrier_processing_id?: string | null;
  } | null;
  /** The address where the parcel will be returned to. */
  return_address?: {
    city: string;
    company?: string | null;
    country: string;
    email?: string | null;
    external_location_id?: string | null;
    line1: string;
    line2?: string | null;
    line3?: string | null;
    name: string;
    phone?: string | null;
    postal_code?: string | null;
    state?: string | null;
    type: "residential" | "commercial";
  } | null;
  /** The service method that will be used to ship the parcel. */
  service_method?: string | null;
  /** A list of tags to associate with the parcel */
  tags?: string[] | null;
  /** The address where the parcel will be delivered to. */
  to_address: {
    city: string;
    company?: string | null;
    country: string;
    email?: string | null;
    external_location_id?: string | null;
    line1: string;
    line2?: string | null;
    line3?: string | null;
    name: string;
    phone?: string | null;
    postal_code?: string | null;
    state?: string | null;
    type: "residential" | "commercial";
  };
  /** The weight of the parcel in pounds. */
  weight?: number;
  /** The width of the parcel in inches. */
  width?: number;
};
export type OversizedSurcharge = {
  /** Accessorial */
  accessorial: {
    calculate_fuel: boolean;
    default_rate: number;
    description: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  /** The accessorial ID */
  accessorial_id: string | null;
  /** Carrier */
  carrier: {
    /** The alternate names of the carrier */
    aliases: string[] | null;
    /** The UI friendly name for the carrier */
    display_name: string;
    /** The carrier ID */
    id: string;
    inserted_at: string;
    /** The name of the carrier */
    name: string;
    /** The tracking link template for the carrier */
    tracking_link_template: string | null;
    /** The tracking number lengths of the carrier */
    tracking_number_lengths: number[] | null;
    /** The tracking number prefixes of the carrier */
    tracking_number_prefixes: string[] | null;
    updated_at: string;
  } | null;
  /** The carrier ID */
  carrier_id: string | null;
  /** The effective or start date of the oversized surcharge */
  effective_at: string;
  /** The expiration or end date of the oversized surcharge */
  expires_at: string;
  /** The oversized surcharge ID */
  id: string;
  inserted_at: string;
  /** The maximum value of the oversized surcharge type */
  max_value: number;
  /** The minimum value of the oversized surcharge type */
  min_value: number;
  /** The oversized surcharge type */
  oversized_type: "weight" | "cubic_volume" | "longest_dim" | "girth";
  /** Service Level */
  service_level: {
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  /** The service level ID */
  service_level_id: string | null;
  /** Shipper */
  shipper: {
    /** The enabled label providers for the shipper */
    enabled_label_providers: ("shipium" | "easy_post")[];
    /** The external reference for the shipper */
    external_reference: string | null;
    /** The shipper ID */
    id: string;
    /** Creation timestamp */
    inserted_at: string;
    /** The shipper name */
    name: string;
    /** Determines whether rating is enabled for the shipper */
    rating_enabled: boolean;
    /** Update timestamp */
    updated_at: string;
  } | null;
  /** The shipper ID */
  shipper_id: string | null;
  updated_at: string;
  /** The code that identifies the zone */
  zone_code: string | null;
};
export type UpdateOversizedSurcharge = {
  /** The accessorial ID */
  accessorial_id?: string | null;
  /** The carrier ID */
  carrier_id?: string | null;
  /** The effective or start date of the oversized surcharge */
  effective_at?: string;
  /** The expiration or end date of the oversized surcharge */
  expires_at?: string;
  /** The maximum value of the oversized surcharge type */
  max_value?: number;
  /** The minimum value of the oversized surcharge type */
  min_value?: number;
  /** The oversized surcharge type */
  oversized_type?: "weight" | "cubic_volume" | "longest_dim" | "girth";
  /** The service level ID */
  service_level_id?: string | null;
  /** The shipper ID */
  shipper_id?: string | null;
  /** The code that identifies the zone */
  zone_code?: string | null;
};
export type DimensionalFactor = {
  /** Carrier */
  carrier: {
    /** The alternate names of the carrier */
    aliases: string[] | null;
    /** The UI friendly name for the carrier */
    display_name: string;
    /** The carrier ID */
    id: string;
    inserted_at: string;
    /** The name of the carrier */
    name: string;
    /** The tracking link template for the carrier */
    tracking_link_template: string | null;
    /** The tracking number lengths of the carrier */
    tracking_number_lengths: number[] | null;
    /** The tracking number prefixes of the carrier */
    tracking_number_prefixes: string[] | null;
    updated_at: string;
  } | null;
  /** The carrier ID */
  carrier_id: string | null;
  /** The effective or start date of the dimensional factor */
  effective_at: string;
  /** The expiration or end date of the dimensional factor */
  expires_at: string;
  factor: string | number;
  /** The dimensional factor ID */
  id: string;
  inserted_at: string;
  minimum_threshold: (string | null) | (number | null);
  /** Service Method */
  service_method: {
    /** The carrier ID */
    carrier_id: string;
    /** The UI friendly name for the service method */
    display_name: string;
    /** The service method ID */
    id: string;
    inserted_at: string;
    /** The name of the service method */
    name: string;
    service_level_selection_strategy?:
      | ("priority" | "weight" | "incoterm")
      | null;
    service_levels: ServiceLevel[] | null;
    service_method_levels: ServiceMethodLevel[];
    updated_at: string;
  } | null;
  /** The service method ID */
  service_method_id: string | null;
  /** Shipper */
  shipper: {
    /** The enabled label providers for the shipper */
    enabled_label_providers: ("shipium" | "easy_post")[];
    /** The external reference for the shipper */
    external_reference: string | null;
    /** The shipper ID */
    id: string;
    /** Creation timestamp */
    inserted_at: string;
    /** The shipper name */
    name: string;
    /** Determines whether rating is enabled for the shipper */
    rating_enabled: boolean;
    /** Update timestamp */
    updated_at: string;
  } | null;
  /** The shipper ID */
  shipper_id: string | null;
  updated_at: string;
};
export type UpdateDimensionalFactor = {
  /** Carrier */
  carrier?: {
    /** The alternate names of the carrier */
    aliases: string[] | null;
    /** The UI friendly name for the carrier */
    display_name: string;
    /** The carrier ID */
    id: string;
    inserted_at: string;
    /** The name of the carrier */
    name: string;
    /** The tracking link template for the carrier */
    tracking_link_template: string | null;
    /** The tracking number lengths of the carrier */
    tracking_number_lengths: number[] | null;
    /** The tracking number prefixes of the carrier */
    tracking_number_prefixes: string[] | null;
    updated_at: string;
  } | null;
  /** The carrier ID */
  carrier_id?: string | null;
  /** The effective or start date of the dimensional factor */
  effective_at?: string;
  /** The expiration or end date of the dimensional factor */
  expires_at?: string;
  factor?: string | number;
  minimum_threshold?: (string | null) | (number | null);
  /** Service Method */
  service_method?: {
    /** The carrier ID */
    carrier_id: string;
    /** The UI friendly name for the service method */
    display_name: string;
    /** The service method ID */
    id: string;
    inserted_at: string;
    /** The name of the service method */
    name: string;
    service_level_selection_strategy?:
      | ("priority" | "weight" | "incoterm")
      | null;
    service_levels: ServiceLevel[] | null;
    service_method_levels: ServiceMethodLevel[];
    updated_at: string;
  } | null;
  /** The service method ID */
  service_method_id?: string | null;
  /** Shipper */
  shipper?: {
    /** The enabled label providers for the shipper */
    enabled_label_providers: ("shipium" | "easy_post")[];
    /** The external reference for the shipper */
    external_reference: string | null;
    /** The shipper ID */
    id: string;
    /** Creation timestamp */
    inserted_at: string;
    /** The shipper name */
    name: string;
    /** Determines whether rating is enabled for the shipper */
    rating_enabled: boolean;
    /** Update timestamp */
    updated_at: string;
  } | null;
  /** The shipper ID */
  shipper_id?: string | null;
};
export type EvaluatedServiceMethod = {
  carrier_name?: string;
  label_rate?: number;
  service_method_name?: string;
};
export type Rate = {
  /** The preferred carrier that will be used to ship the parcel. */
  carrier_name?: string | null;
  /** ISO 4217 three-letter currency code. */
  currency_code?: string;
  evaluated_service_methods?: EvaluatedServiceMethod[];
  expected_delivery_at_date?: string | null;
  /** The rate (cost of shipping the parcel) that will be used to generate the shipping label. */
  label_rate?: number;
  metadata?: {
    shipium_shipment_id?: string;
  };
  /** The service method that will be used to ship the parcel. */
  service_method?: string | null;
};
export type ShowRates = {
  /** The external source id of the parcel e.g. order_number. */
  external_source_id?: string | null;
  /** The address where the parcel will be picked up from. */
  from_address?: {
    city: string;
    company?: string | null;
    country: string;
    email?: string | null;
    external_location_id?: string | null;
    line1: string;
    line2?: string | null;
    line3?: string | null;
    name: string;
    phone?: string | null;
    postal_code?: string | null;
    state?: string | null;
    type: "residential" | "commercial";
  };
  /** The height of the parcel in inches. */
  height?: number;
  items?: Item[];
  /** The length of the parcel in inches. */
  length?: number;
  rates?: Rate[];
  /** The address where the parcel will be delivered to. */
  to_address?: {
    city: string;
    company?: string | null;
    country: string;
    email?: string | null;
    external_location_id?: string | null;
    line1: string;
    line2?: string | null;
    line3?: string | null;
    name: string;
    phone?: string | null;
    postal_code?: string | null;
    state?: string | null;
    type: "residential" | "commercial";
  };
  /** The weight of the parcel in pounds. */
  weight?: number;
  /** The width of the parcel in inches. */
  width?: number;
};
export type RateEstimate =
  | {
      /** The number of business days that the parcel will be in transit. */
      business_days_in_transit: number | null;
      /** ISO 4217 three-letter currency code. */
      currency_code?: string;
      /** The external source id of the parcel e.g. order_number. */
      external_source_id?: string | null;
      /** The height of the parcel in inches. */
      height?: number;
      /** The incoterm that will be used to ship the parcel. */
      incoterm?: ("DAP" | "DDP" | "DDU" | "DPU" | "FCA") | null;
      items?: Item[];
      /** The length of the parcel in inches. */
      length?: number;
      /** List of addresses of shipping from locations (e.g. facilities) to get rates for */
      locations?: {
        city: string;
        company?: string | null;
        country: string;
        email?: string | null;
        external_location_id?: string | null;
        line1: string;
        line2?: string | null;
        line3?: string | null;
        name: string;
        phone?: string | null;
        postal_code?: string | null;
        state?: string | null;
        type: "residential" | "commercial";
      }[];
      /** The carrier rate type to get rates for e.g. BDOT, CarrierShipOption, ServiceMethod and DesiredDeliveryDate. */
      rate_type?: string;
      /** Whether or not the parcel should be delivered on a Saturday. */
      saturday_delivery?: boolean | null;
      /** if null then the default signature option NO_SIGNATURE will be used. */
      signature_required?:
        | (
            | "signature"
            | "resident_signature"
            | "adult_signature"
            | "adult_resident_signature"
          )
        | null;
      /** The address where the parcel will be delivered to. */
      to_address?: {
        city: string;
        company?: string | null;
        country: string;
        email?: string | null;
        external_location_id?: string | null;
        line1: string;
        line2?: string | null;
        line3?: string | null;
        name: string;
        phone?: string | null;
        postal_code?: string | null;
        state?: string | null;
        type: "residential" | "commercial";
      };
      /** The weight of the parcel in pounds. */
      weight?: number;
      /** The width of the parcel in inches. */
      width?: number;
    }
  | {
      /** The ship option that will be used to ship the parcel. */
      carrier_ship_option: string | null;
      /** ISO 4217 three-letter currency code. */
      currency_code?: string;
      /** The external source id of the parcel e.g. order_number. */
      external_source_id?: string | null;
      /** The height of the parcel in inches. */
      height?: number;
      /** The incoterm that will be used to ship the parcel. */
      incoterm?: ("DAP" | "DDP" | "DDU" | "DPU" | "FCA") | null;
      items?: Item[];
      /** The length of the parcel in inches. */
      length?: number;
      /** List of addresses of shipping from locations (e.g. facilities) to get rates for */
      locations?: {
        city: string;
        company?: string | null;
        country: string;
        email?: string | null;
        external_location_id?: string | null;
        line1: string;
        line2?: string | null;
        line3?: string | null;
        name: string;
        phone?: string | null;
        postal_code?: string | null;
        state?: string | null;
        type: "residential" | "commercial";
      }[];
      /** The carrier rate type to get rates for e.g. BDOT, CarrierShipOption, ServiceMethod and DesiredDeliveryDate. */
      rate_type?: string;
      /** Whether or not the parcel should be delivered on a Saturday. */
      saturday_delivery?: boolean | null;
      /** if null then the default signature option NO_SIGNATURE will be used. */
      signature_required?:
        | (
            | "signature"
            | "resident_signature"
            | "adult_signature"
            | "adult_resident_signature"
          )
        | null;
      /** The address where the parcel will be delivered to. */
      to_address?: {
        city: string;
        company?: string | null;
        country: string;
        email?: string | null;
        external_location_id?: string | null;
        line1: string;
        line2?: string | null;
        line3?: string | null;
        name: string;
        phone?: string | null;
        postal_code?: string | null;
        state?: string | null;
        type: "residential" | "commercial";
      };
      /** The weight of the parcel in pounds. */
      weight?: number;
      /** The width of the parcel in inches. */
      width?: number;
    }
  | {
      /** ISO 4217 three-letter currency code. */
      currency_code?: string;
      /** The external source id of the parcel e.g. order_number. */
      external_source_id?: string | null;
      /** The height of the parcel in inches. */
      height?: number;
      /** The incoterm that will be used to ship the parcel. */
      incoterm?: ("DAP" | "DDP" | "DDU" | "DPU" | "FCA") | null;
      items?: Item[];
      /** The length of the parcel in inches. */
      length?: number;
      /** List of addresses of shipping from locations (e.g. facilities) to get rates for */
      locations?: {
        city: string;
        company?: string | null;
        country: string;
        email?: string | null;
        external_location_id?: string | null;
        line1: string;
        line2?: string | null;
        line3?: string | null;
        name: string;
        phone?: string | null;
        postal_code?: string | null;
        state?: string | null;
        type: "residential" | "commercial";
      }[];
      /** The carrier rate type to get rates for e.g. BDOT, CarrierShipOption, ServiceMethod and DesiredDeliveryDate. */
      rate_type?: string;
      /** Whether or not the parcel should be delivered on a Saturday. */
      saturday_delivery?: boolean | null;
      /** The service method that will be used to ship the parcel. */
      service_method: string | null;
      /** if null then the default signature option NO_SIGNATURE will be used. */
      signature_required?:
        | (
            | "signature"
            | "resident_signature"
            | "adult_signature"
            | "adult_resident_signature"
          )
        | null;
      /** The address where the parcel will be delivered to. */
      to_address?: {
        city: string;
        company?: string | null;
        country: string;
        email?: string | null;
        external_location_id?: string | null;
        line1: string;
        line2?: string | null;
        line3?: string | null;
        name: string;
        phone?: string | null;
        postal_code?: string | null;
        state?: string | null;
        type: "residential" | "commercial";
      };
      /** The weight of the parcel in pounds. */
      weight?: number;
      /** The width of the parcel in inches. */
      width?: number;
    }
  | {
      /** ISO 4217 three-letter currency code. */
      currency_code?: string;
      desired_delivery_date: string | null;
      /** The external source id of the parcel e.g. order_number. */
      external_source_id?: string | null;
      /** The height of the parcel in inches. */
      height?: number;
      /** The incoterm that will be used to ship the parcel. */
      incoterm?: ("DAP" | "DDP" | "DDU" | "DPU" | "FCA") | null;
      items?: Item[];
      /** The length of the parcel in inches. */
      length?: number;
      /** List of addresses of shipping from locations (e.g. facilities) to get rates for */
      locations?: {
        city: string;
        company?: string | null;
        country: string;
        email?: string | null;
        external_location_id?: string | null;
        line1: string;
        line2?: string | null;
        line3?: string | null;
        name: string;
        phone?: string | null;
        postal_code?: string | null;
        state?: string | null;
        type: "residential" | "commercial";
      }[];
      /** The carrier rate type to get rates for e.g. BDOT, CarrierShipOption, ServiceMethod and DesiredDeliveryDate. */
      rate_type?: string;
      /** Whether or not the parcel should be delivered on a Saturday. */
      saturday_delivery?: boolean | null;
      /** if null then the default signature option NO_SIGNATURE will be used. */
      signature_required?:
        | (
            | "signature"
            | "resident_signature"
            | "adult_signature"
            | "adult_resident_signature"
          )
        | null;
      /** The address where the parcel will be delivered to. */
      to_address?: {
        city: string;
        company?: string | null;
        country: string;
        email?: string | null;
        external_location_id?: string | null;
        line1: string;
        line2?: string | null;
        line3?: string | null;
        name: string;
        phone?: string | null;
        postal_code?: string | null;
        state?: string | null;
        type: "residential" | "commercial";
      };
      /** The weight of the parcel in pounds. */
      weight?: number;
      /** The width of the parcel in inches. */
      width?: number;
    };
export type CreateParcel = {
  /** The number of business days that the parcel will be in transit. */
  business_days_in_transit?: number | null;
  /** The ship option that will be used to ship the parcel. */
  carrier_ship_option?: string | null;
  /** ISO 4217 three-letter currency code. */
  currency_code: string;
  desired_delivery_date?: string | null;
  expected_shipped_at_date?: string | null;
  /** Associates a parcel with a record on an external system (WMS) */
  external_id?: string | null;
  /** The external order number of the parcel e.g. In S1C, this would be the `order_number`. */
  external_order_number?: string | null;
  /** The external source id of the parcel e.g. order_number. */
  external_source_id: string | null;
  /** The service method that will be used to ship the parcel if the first one fails. */
  fallback_service_method?: string | null;
  /** The address where the parcel will be picked up from. */
  from_address: {
    city: string;
    company?: string | null;
    country: string;
    email?: string | null;
    external_location_id?: string | null;
    line1: string;
    line2?: string | null;
    line3?: string | null;
    name: string;
    phone?: string | null;
    postal_code?: string | null;
    state?: string | null;
    type: "residential" | "commercial";
  };
  /** The height of the parcel in inches. */
  height: number;
  /** The incoterm that will be used to ship the parcel. */
  incoterm?: ("DAP" | "DDP" | "DDU" | "DPU" | "FCA") | null;
  items: Item[];
  /** The preferred label format to use to generate the shipping label. */
  label_format?: string | null;
  /** The preferred label provider to use to generate the shipping label. */
  label_provider?: ("easy_post" | "shipium" | "usps_direct") | null;
  /** The length of the parcel in inches. */
  length: number;
  /** Contains order related metadata coming from external systems */
  order_metadata?: {
    /** A carrier specific processing ID for the order */
    carrier_processing_id?: string | null;
  } | null;
  /** The packaging material used to ship the parcel */
  packaging_material?:
    | (
        | "box"
        | "envelope"
        | "fedex_one_rate_envelope"
        | "fedex_one_rate_extra_large_box"
        | "fedex_one_rate_large_box"
        | "fedex_one_rate_medium_box"
        | "fedex_one_rate_pak"
        | "fedex_one_rate_small_box"
        | "fedex_one_rate_tube"
        | "flat_pack"
      )
    | null;
  /** Custom text to print on the first optional position on the label */
  print_option_1?: string | null;
  /** Custom text to print on the second optional position on the label */
  print_option_2?: string | null;
  /** Custom text to print on the third optional position on the label */
  print_option_3?: string | null;
  /** The address where the parcel will be returned to. */
  return_address?: {
    city: string;
    company?: string | null;
    country: string;
    email?: string | null;
    external_location_id?: string | null;
    line1: string;
    line2?: string | null;
    line3?: string | null;
    name: string;
    phone?: string | null;
    postal_code?: string | null;
    state?: string | null;
    type: "residential" | "commercial";
  } | null;
  /** Whether or not the parcel should be delivered on a Saturday. */
  saturday_delivery?: boolean | null;
  /** The service method that will be used to ship the parcel. */
  service_method?: string | null;
  /** if null then the default signature option NO_SIGNATURE will be used. */
  signature_required?:
    | (
        | "signature"
        | "resident_signature"
        | "adult_signature"
        | "adult_resident_signature"
      )
    | null;
  sub_parcels?: SubParcel[];
  /** A list of tags to associate with the parcel */
  tags?: string[] | null;
  /** The address where the parcel will be delivered to. */
  to_address: {
    city: string;
    company?: string | null;
    country: string;
    email?: string | null;
    external_location_id?: string | null;
    line1: string;
    line2?: string | null;
    line3?: string | null;
    name: string;
    phone?: string | null;
    postal_code?: string | null;
    state?: string | null;
    type: "residential" | "commercial";
  };
  /** The weight of the parcel in pounds. */
  weight: number;
  /** The width of the parcel in inches. */
  width: number;
};
export type CreateParcelBatch = {
  parcels: CreateParcel[];
};
export type CreateAccessorial = {
  calculate_fuel: boolean;
  default_rate: number;
  description: string;
  name: string;
};
export type ExtendedArea = {
  /** Carrier */
  carrier: {
    /** The alternate names of the carrier */
    aliases: string[] | null;
    /** The UI friendly name for the carrier */
    display_name: string;
    /** The carrier ID */
    id: string;
    inserted_at: string;
    /** The name of the carrier */
    name: string;
    /** The tracking link template for the carrier */
    tracking_link_template: string | null;
    /** The tracking number lengths of the carrier */
    tracking_number_lengths: number[] | null;
    /** The tracking number prefixes of the carrier */
    tracking_number_prefixes: string[] | null;
    updated_at: string;
  } | null;
  /** The carrier ID */
  carrier_id: string | null;
  /** The effective or start date of the extended area */
  effective_at: string;
  /** The expiration or end date of the extended area */
  expires_at: string;
  /** Extended Area Level */
  extended_area_level: {
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  /** The extended area level ID */
  extended_area_level_id: string | null;
  /** The extended area ID */
  id: string;
  inserted_at: string;
  /** The destination postal code */
  postal_code: string;
  /** Service Level */
  service_level: {
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  /** The service level ID */
  service_level_id: string | null;
  updated_at: string;
};
export type UpdateExtendedArea = {
  /** Carrier */
  carrier?: {
    /** The alternate names of the carrier */
    aliases: string[] | null;
    /** The UI friendly name for the carrier */
    display_name: string;
    /** The carrier ID */
    id: string;
    inserted_at: string;
    /** The name of the carrier */
    name: string;
    /** The tracking link template for the carrier */
    tracking_link_template: string | null;
    /** The tracking number lengths of the carrier */
    tracking_number_lengths: number[] | null;
    /** The tracking number prefixes of the carrier */
    tracking_number_prefixes: string[] | null;
    updated_at: string;
  } | null;
  /** The carrier ID */
  carrier_id?: string | null;
  /** The effective or start date of the extended area */
  effective_at?: string;
  /** The expiration or end date of the extended area */
  expires_at?: string;
  /** Extended Area Level */
  extended_area_level?: {
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  /** The extended area level ID */
  extended_area_level_id?: string | null;
  /** The destination postal code */
  postal_code?: string;
  /** Service Level */
  service_level?: {
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  /** The service level ID */
  service_level_id?: string | null;
};
export type CreateVendorServiceMethodMapping = {
  /** The service method ID */
  service_method_id: string;
  /** The vendor service method name */
  vendor_service_method: string;
};
export type UpdateCarrierAccount = {
  /** The id of the carrier account object provided by the 3rd party label provider */
  account_id?: string;
  /** Carrier Name used to identify the carrier */
  carrier_name?: string;
  /** Description of the carrier account */
  description?: string | null;
  /** The preferred label provider where the account is configured. */
  label_provider?: ("shipium" | "easy_post") | null;
};
export type ResidentialSurcharge = {
  /** Accessorial */
  accessorial: {
    calculate_fuel: boolean;
    default_rate: number;
    description: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  /** The accessorial ID */
  accessorial_id: string;
  /** Carrier */
  carrier: {
    /** The alternate names of the carrier */
    aliases: string[] | null;
    /** The UI friendly name for the carrier */
    display_name: string;
    /** The carrier ID */
    id: string;
    inserted_at: string;
    /** The name of the carrier */
    name: string;
    /** The tracking link template for the carrier */
    tracking_link_template: string | null;
    /** The tracking number lengths of the carrier */
    tracking_number_lengths: number[] | null;
    /** The tracking number prefixes of the carrier */
    tracking_number_prefixes: string[] | null;
    updated_at: string;
  } | null;
  /** The carrier ID */
  carrier_id: string | null;
  effective_at: string;
  expires_at: string;
  id: string;
  inserted_at: string;
  max_weight: number;
  min_weight: number;
  /** The type of rate */
  rate_type: "buy" | "sell";
  /** Service Level */
  service_level: {
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  /** The service level ID */
  service_level_id: string | null;
  /** Service Method */
  service_method: {
    /** The carrier ID */
    carrier_id: string;
    /** The UI friendly name for the service method */
    display_name: string;
    /** The service method ID */
    id: string;
    inserted_at: string;
    /** The name of the service method */
    name: string;
    service_level_selection_strategy?:
      | ("priority" | "weight" | "incoterm")
      | null;
    service_levels: ServiceLevel[] | null;
    service_method_levels: ServiceMethodLevel[];
    updated_at: string;
  } | null;
  /** The service method ID */
  service_method_id: string | null;
  /** Shipper */
  shipper: {
    /** The enabled label providers for the shipper */
    enabled_label_providers: ("shipium" | "easy_post")[];
    /** The external reference for the shipper */
    external_reference: string | null;
    /** The shipper ID */
    id: string;
    /** Creation timestamp */
    inserted_at: string;
    /** The shipper name */
    name: string;
    /** Determines whether rating is enabled for the shipper */
    rating_enabled: boolean;
    /** Update timestamp */
    updated_at: string;
  } | null;
  /** The shipper ID */
  shipper_id: string | null;
  updated_at: string;
};
export type CreateResidentialSurcharge = {
  /** The accessorial ID */
  accessorial_id: string;
  /** The carrier ID */
  carrier_id?: string | null;
  effective_at: string;
  expires_at: string;
  max_weight: number;
  min_weight: number;
  /** The type of rate */
  rate_type: "buy" | "sell";
  /** The service level ID */
  service_level_id?: string | null;
  /** The service method ID */
  service_method_id?: string | null;
  /** The shipper ID */
  shipper_id?: string | null;
};
export type RerateRequest = {
  additional_accessorial_rates?: {
    name: string;
    rate: number;
  }[];
  external_reference: string;
  /** The address where the parcel will be picked up from. */
  from_address?: {
    city: string;
    company?: string | null;
    country: string;
    email?: string | null;
    external_location_id?: string | null;
    line1: string;
    line2?: string | null;
    line3?: string | null;
    name: string;
    phone?: string | null;
    postal_code?: string | null;
    state?: string | null;
    type: "residential" | "commercial";
  };
  /** The height of the parcel in inches. */
  height?: number;
  /** The length of the parcel in inches. */
  length?: number;
  /** The service method that will be used when rerating the parcel. */
  service_method?: string;
  /** The address where the parcel will be delivered to. */
  to_address?: {
    city: string;
    company?: string | null;
    country: string;
    email?: string | null;
    external_location_id?: string | null;
    line1: string;
    line2?: string | null;
    line3?: string | null;
    name: string;
    phone?: string | null;
    postal_code?: string | null;
    state?: string | null;
    type: "residential" | "commercial";
  };
  /** The weight of the parcel in pounds. */
  weight?: number;
  /** The width of the parcel in inches. */
  width?: number;
};
export type CreateExtendedArea = {
  /** Carrier */
  carrier?: {
    /** The alternate names of the carrier */
    aliases: string[] | null;
    /** The UI friendly name for the carrier */
    display_name: string;
    /** The carrier ID */
    id: string;
    inserted_at: string;
    /** The name of the carrier */
    name: string;
    /** The tracking link template for the carrier */
    tracking_link_template: string | null;
    /** The tracking number lengths of the carrier */
    tracking_number_lengths: number[] | null;
    /** The tracking number prefixes of the carrier */
    tracking_number_prefixes: string[] | null;
    updated_at: string;
  } | null;
  /** The carrier ID */
  carrier_id?: string | null;
  /** The effective or start date of the extended area */
  effective_at: string;
  /** The expiration or end date of the extended area */
  expires_at: string;
  /** Extended Area Level */
  extended_area_level?: {
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  /** The extended area level ID */
  extended_area_level_id?: string | null;
  /** The destination postal code */
  postal_code: string;
  /** Service Level */
  service_level?: {
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  /** The service level ID */
  service_level_id?: string | null;
};
export type UpdateVendorCarrierMapping = {
  /** The carrier ID */
  carrier_id?: string;
  /** The vendor carrier name or id */
  vendor_carrier?: string;
};
export type ZoneGroup = {
  /** Carrier */
  carrier: {
    /** The alternate names of the carrier */
    aliases: string[] | null;
    /** The UI friendly name for the carrier */
    display_name: string;
    /** The carrier ID */
    id: string;
    inserted_at: string;
    /** The name of the carrier */
    name: string;
    /** The tracking link template for the carrier */
    tracking_link_template: string | null;
    /** The tracking number lengths of the carrier */
    tracking_number_lengths: number[] | null;
    /** The tracking number prefixes of the carrier */
    tracking_number_prefixes: string[] | null;
    updated_at: string;
  } | null;
  carrier_id: string | null;
  effective_at: string;
  expires_at: string;
  id: string;
  inserted_at: string;
  name: string;
  origin_country: string;
  origin_zip_end: string;
  origin_zip_start: string;
  /** The type of rate */
  rate_type: "buy" | "sell";
  /** Service Level */
  service_level: {
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  service_level_id: string | null;
  /** Service Method */
  service_method: {
    /** The carrier ID */
    carrier_id: string;
    /** The UI friendly name for the service method */
    display_name: string;
    /** The service method ID */
    id: string;
    inserted_at: string;
    /** The name of the service method */
    name: string;
    service_level_selection_strategy?:
      | ("priority" | "weight" | "incoterm")
      | null;
    service_levels: ServiceLevel[] | null;
    service_method_levels: ServiceMethodLevel[];
    updated_at: string;
  } | null;
  service_method_id: string | null;
  updated_at: string;
};
export type CreateZoneGroup = {
  /** Carrier */
  carrier?: {
    /** The alternate names of the carrier */
    aliases: string[] | null;
    /** The UI friendly name for the carrier */
    display_name: string;
    /** The carrier ID */
    id: string;
    inserted_at: string;
    /** The name of the carrier */
    name: string;
    /** The tracking link template for the carrier */
    tracking_link_template: string | null;
    /** The tracking number lengths of the carrier */
    tracking_number_lengths: number[] | null;
    /** The tracking number prefixes of the carrier */
    tracking_number_prefixes: string[] | null;
    updated_at: string;
  } | null;
  carrier_id?: string | null;
  effective_at: string;
  expires_at: string;
  name: string;
  origin_country: string;
  origin_zip_end: string;
  origin_zip_start: string;
  /** The type of rate */
  rate_type?: "buy" | "sell";
  /** Service Level */
  service_level?: {
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  service_level_id?: string | null;
  /** Service Method */
  service_method?: {
    /** The carrier ID */
    carrier_id: string;
    /** The UI friendly name for the service method */
    display_name: string;
    /** The service method ID */
    id: string;
    inserted_at: string;
    /** The name of the service method */
    name: string;
    service_level_selection_strategy?:
      | ("priority" | "weight" | "incoterm")
      | null;
    service_levels: ServiceLevel[] | null;
    service_method_levels: ServiceMethodLevel[];
    updated_at: string;
  } | null;
  service_method_id?: string | null;
};
export type UpdateServiceMethod = {
  /** The UI friendly name for the service method */
  display_name?: string;
  /** The name of the service method */
  name?: string;
  service_level_selection_strategy?:
    | ("priority" | "weight" | "incoterm")
    | null;
  service_levels?: {
    id?: string;
    incoterm?: "DAP" | "DDP" | "DDU" | "DPU" | "FCA";
    max_weight?: number;
    min_weight?: number;
  }[];
};
export type CarrierAccessorial = {
  /** Accessorial */
  accessorial: {
    calculate_fuel: boolean;
    default_rate: number;
    description: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  /** The accessorial ID */
  accessorial_id: string | null;
  carrier: Carrier;
  /** The carrier ID */
  carrier_id: string;
  /** A description for the purpose of the carrier accessorial */
  description: string | null;
  /** The carrier accessorial ID */
  id: string;
  inserted_at: string;
  /** The carrier accessorial name */
  name: string;
  updated_at: string;
};
export type CreateCarrierAccessorial = {
  /** Accessorial */
  accessorial?: {
    calculate_fuel: boolean;
    default_rate: number;
    description: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  /** The accessorial ID */
  accessorial_id?: string | null;
  carrier?: Carrier;
  /** The carrier ID */
  carrier_id: string;
  /** A description for the purpose of the carrier accessorial */
  description?: string | null;
  /** The carrier accessorial name */
  name: string;
};
export type UpdateResidentialSurcharge = {
  /** The accessorial ID */
  accessorial_id?: string;
  /** The carrier ID */
  carrier_id?: string | null;
  effective_at?: string;
  expires_at?: string;
  max_weight?: number;
  min_weight?: number;
  /** The type of rate */
  rate_type?: "buy" | "sell";
  /** The service level ID */
  service_level_id?: string | null;
  /** The service method ID */
  service_method_id?: string | null;
  /** The shipper ID */
  shipper_id?: string | null;
};
export type FuelSurcharge = {
  /** The effective or start date of the fuel surcharge */
  effective_at: string;
  /** The expiration or end date of the fuel surcharge */
  expires_at: string;
  fuel_group_id: string;
  /** The fuel surcharge percentage. This will be applied across all accessorials with `calculate_fuel` and the base freight rate. */
  fuel_percent: string;
  /** The fuel surcharge ID */
  id: string;
  inserted_at: string;
  updated_at: string;
};
export type CreateFuelSurcharge = {
  /** The effective or start date of the fuel surcharge */
  effective_at: string;
  /** The expiration or end date of the fuel surcharge */
  expires_at: string;
  fuel_group_id?: string;
  /** The fuel surcharge percentage. This will be applied across all accessorials with `calculate_fuel` and the base freight rate. */
  fuel_percent: string;
};
export type Registration = {
  /** The current date the carrier estimated for delivery */
  carrier_estimated_delivery_date?: string | null;
  carrier_name: string;
  /** Time a delivered tracking event occurs for this registration */
  delivered_at?: string | null;
  /** The first date the carrier estimated for delivery */
  initial_carrier_estimated_delivery_date?: string | null;
  label_provider: string;
  /** Time shipment is registered with third party tracking provider */
  registered_at?: string | null;
  shipper_external_reference: string;
  /** Parcel shipper id or external reference */
  shipper_id: string;
  source?: string | null;
  /** The carrier tracking number */
  tracking_number: string;
};
export type CreateRateRecord = {
  discount_rate?: number | null;
  flat_rate?: number | null;
  max_weight: number;
  min_weight: number;
  rating_type: "discount" | "flat" | "variable" | "hybrid";
  variable_rate?: number | null;
  /** The code that identifies the zone */
  zone_code: string;
};
export type UpdateFuelSurcharge = {
  /** The effective or start date of the fuel surcharge */
  effective_at?: string;
  /** The expiration or end date of the fuel surcharge */
  expires_at?: string;
  fuel_group_id?: string;
  /** The fuel surcharge percentage. This will be applied across all accessorials with `calculate_fuel` and the base freight rate. */
  fuel_percent?: string;
};
export type UpdateZoneGroup = {
  /** Carrier */
  carrier?: {
    /** The alternate names of the carrier */
    aliases: string[] | null;
    /** The UI friendly name for the carrier */
    display_name: string;
    /** The carrier ID */
    id: string;
    inserted_at: string;
    /** The name of the carrier */
    name: string;
    /** The tracking link template for the carrier */
    tracking_link_template: string | null;
    /** The tracking number lengths of the carrier */
    tracking_number_lengths: number[] | null;
    /** The tracking number prefixes of the carrier */
    tracking_number_prefixes: string[] | null;
    updated_at: string;
  } | null;
  carrier_id?: string | null;
  effective_at?: string;
  expires_at?: string;
  name?: string;
  origin_country?: string;
  origin_zip_end?: string;
  origin_zip_start?: string;
  /** The type of rate */
  rate_type?: "buy" | "sell";
  /** Service Level */
  service_level?: {
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  service_level_id?: string | null;
  /** Service Method */
  service_method?: {
    /** The carrier ID */
    carrier_id: string;
    /** The UI friendly name for the service method */
    display_name: string;
    /** The service method ID */
    id: string;
    inserted_at: string;
    /** The name of the service method */
    name: string;
    service_level_selection_strategy?:
      | ("priority" | "weight" | "incoterm")
      | null;
    service_levels: ServiceLevel[] | null;
    service_method_levels: ServiceMethodLevel[];
    updated_at: string;
  } | null;
  service_method_id?: string | null;
};
export type UpdateShipperAccessorial = {
  /** Accessorial */
  accessorial?: {
    calculate_fuel: boolean;
    default_rate: number;
    description: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  /** The accessorial ID */
  accessorial_id?: string;
  /** The carrier ID */
  carrier_id?: string | null;
  /** A description for the purpose of the shipper accessorial */
  description?: string | null;
  /** The destination address type */
  destination_address_type?: ("residential" | "commercial") | null;
  /** The effective or start date of the shipper accessorial */
  effective_at?: string;
  /** The expiration or end date of the shipper accessorial */
  expires_at?: string;
  fulfillment_center_id?: string | null;
  /** The name of the shipper accessorial */
  name?: string;
  /** The rate of the shipper accessorial */
  rate?: number;
  /** The service level ID */
  service_level_id?: string | null;
  /** The service method ID */
  service_method_id?: string | null;
  shipper_id?: string | null;
};
export type AccessorialConfig = {
  /** Accessorial */
  accessorial: {
    calculate_fuel: boolean;
    default_rate: number;
    description: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  };
  /** The accessorial ID */
  accessorial_id: string;
  /** A factor that is applied to the carrier accessorial rate when `run_type` is `use_buy_rate`. The rate is multiplied by the factor to get the new rate */
  buy_rate_factor?: number | null;
  /** Carrier */
  carrier: {
    /** The alternate names of the carrier */
    aliases: string[] | null;
    /** The UI friendly name for the carrier */
    display_name: string;
    /** The carrier ID */
    id: string;
    inserted_at: string;
    /** The name of the carrier */
    name: string;
    /** The tracking link template for the carrier */
    tracking_link_template: string | null;
    /** The tracking number lengths of the carrier */
    tracking_number_lengths: number[] | null;
    /** The tracking number prefixes of the carrier */
    tracking_number_prefixes: string[] | null;
    updated_at: string;
  } | null;
  /** The carrier ID */
  carrier_id: string | null;
  /** The destination address type */
  destination_address_type: ("residential" | "commercial") | null;
  fulfillment_center_id: string | null;
  /** The accessorial config ID */
  id: string;
  inserted_at: string;
  /** The maximum weight for which this accessorial config applies */
  max_weight: number;
  /** The minimum weight for which this accessorial config applies */
  min_weight: number;
  /** Determines how the sell accessorial rate should be calculated */
  run_type: "use_buy_rate" | "use_shipper_rate" | "ignore";
  /** Service Level */
  service_level: {
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  /** The service level ID */
  service_level_id: string | null;
  /** Service Method */
  service_method: {
    /** The carrier ID */
    carrier_id: string;
    /** The UI friendly name for the service method */
    display_name: string;
    /** The service method ID */
    id: string;
    inserted_at: string;
    /** The name of the service method */
    name: string;
    service_level_selection_strategy?:
      | ("priority" | "weight" | "incoterm")
      | null;
    service_levels: ServiceLevel[] | null;
    service_method_levels: ServiceMethodLevel[];
    updated_at: string;
  } | null;
  /** The service method ID */
  service_method_id: string | null;
  /** Shipper */
  shipper: {
    /** The enabled label providers for the shipper */
    enabled_label_providers: ("shipium" | "easy_post")[];
    /** The external reference for the shipper */
    external_reference: string | null;
    /** The shipper ID */
    id: string;
    /** Creation timestamp */
    inserted_at: string;
    /** The shipper name */
    name: string;
    /** Determines whether rating is enabled for the shipper */
    rating_enabled: boolean;
    /** Update timestamp */
    updated_at: string;
  } | null;
  /** The shipper ID */
  shipper_id: string | null;
  updated_at: string;
};
export type AccessorialConfigRead = {
  /** Accessorial */
  accessorial: {
    calculate_fuel: boolean;
    default_rate: number;
    description: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  };
  /** The accessorial ID */
  accessorial_id: string;
  /** A factor that is applied to the carrier accessorial rate when `run_type` is `use_buy_rate`. The rate is multiplied by the factor to get the new rate */
  buy_rate_factor?: number | null;
  /** Carrier */
  carrier: {
    /** The alternate names of the carrier */
    aliases: string[] | null;
    /** The UI friendly name for the carrier */
    display_name: string;
    /** The carrier ID */
    id: string;
    inserted_at: string;
    /** The name of the carrier */
    name: string;
    /** The tracking link template for the carrier */
    tracking_link_template: string | null;
    /** The tracking number lengths of the carrier */
    tracking_number_lengths: number[] | null;
    /** The tracking number prefixes of the carrier */
    tracking_number_prefixes: string[] | null;
    updated_at: string;
  } | null;
  /** The carrier ID */
  carrier_id: string | null;
  /** The destination address type */
  destination_address_type: ("residential" | "commercial") | null;
  /** FulfillmentCenter */
  fulfillment_center: {
    /** The address context used to identify the fulfillment center. */
    address: {
      city: string;
      company?: string | null;
      country: string;
      email?: string | null;
      external_location_id?: string | null;
      line1: string;
      line2?: string | null;
      line3?: string | null;
      name: string;
      phone?: string | null;
      postal_code?: string | null;
      state?: string | null;
      type: "residential" | "commercial";
    } | null;
    /** The alias used to identify the fulfillment center. */
    alias: string;
    /** Fulfillment Center ID */
    id: string;
    /** Creation timestamp */
    inserted_at: string;
    /** The human readable name used identify the fulfillment center. */
    name: string;
    /** The Shipium Origin ID used to identify the fulfillment center. */
    shipium_origin_id: string;
    /** Update timestamp */
    updated_at: string;
  } | null;
  fulfillment_center_id: string | null;
  /** The accessorial config ID */
  id: string;
  inserted_at: string;
  /** The maximum weight for which this accessorial config applies */
  max_weight: number;
  /** The minimum weight for which this accessorial config applies */
  min_weight: number;
  /** Determines how the sell accessorial rate should be calculated */
  run_type: "use_buy_rate" | "use_shipper_rate" | "ignore";
  /** Service Level */
  service_level: {
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  /** The service level ID */
  service_level_id: string | null;
  /** Service Method */
  service_method: {
    /** The carrier ID */
    carrier_id: string;
    /** The UI friendly name for the service method */
    display_name: string;
    /** The service method ID */
    id: string;
    inserted_at: string;
    /** The name of the service method */
    name: string;
    service_level_selection_strategy?:
      | ("priority" | "weight" | "incoterm")
      | null;
    service_levels: ServiceLevel[] | null;
    service_method_levels: ServiceMethodLevel[];
    updated_at: string;
  } | null;
  /** The service method ID */
  service_method_id: string | null;
  /** Shipper */
  shipper: {
    /** The enabled label providers for the shipper */
    enabled_label_providers: ("shipium" | "easy_post")[];
    /** The external reference for the shipper */
    external_reference: string | null;
    /** The shipper ID */
    id: string;
    /** Creation timestamp */
    inserted_at: string;
    /** The shipper name */
    name: string;
    /** Determines whether rating is enabled for the shipper */
    rating_enabled: boolean;
    /** Update timestamp */
    updated_at: string;
  } | null;
  /** The shipper ID */
  shipper_id: string | null;
  updated_at: string;
};
export type CreateServiceMethod = {
  /** The UI friendly name for the service method */
  display_name: string;
  /** The name of the service method */
  name: string;
  service_level_selection_strategy?:
    | ("priority" | "weight" | "incoterm")
    | null;
  service_levels?: {
    id?: string;
    incoterm?: "DAP" | "DDP" | "DDU" | "DPU" | "FCA";
    max_weight?: number;
    min_weight?: number;
  }[];
};
export type BatchJob = {
  /** The last error after the max attempts have been reached */
  error?: string | null;
  /** The external_id that associates a parcel with a record on an external system (WMS) */
  id: string;
  /** The status of the job */
  status: "completed" | "pending" | "failed";
};
export type BatchJobRead = {
  /** The last error after the max attempts have been reached */
  error?: string | null;
  /** The external_id that associates a parcel with a record on an external system (WMS) */
  id: string;
  /** The parcel object of a successful job */
  parcel?: {
    /** Custom text to print on the first optional position on the label */
    print_option_1?: string | null;
    id?: string;
    /** The external source id of the parcel e.g. order_number. */
    external_source_id?: string | null;
    /** The zone code of the parcel from the TMS */
    tms_zone_code?: string | null;
    /** The external order number of the parcel e.g. In S1C, this would be the `order_number`. */
    external_order_number?: string | null;
    /** The fulfillment center id of the parcel */
    fulfillment_center_id?: string | null;
    /** The address where the parcel will be returned to. */
    return_address?: {
      city: string;
      company?: string | null;
      country: string;
      email?: string | null;
      external_location_id?: string | null;
      line1: string;
      line2?: string | null;
      line3?: string | null;
      name: string;
      phone?: string | null;
      postal_code?: string | null;
      state?: string | null;
      type: "residential" | "commercial";
    } | null;
    ratings?: RatingRead[];
    items?: Item[];
    carrier_selection_method?: string | null;
    /** FulfillmentCenter */
    fulfillment_center?: {
      /** The address context used to identify the fulfillment center. */
      address: {
        city: string;
        company?: string | null;
        country: string;
        email?: string | null;
        external_location_id?: string | null;
        line1: string;
        line2?: string | null;
        line3?: string | null;
        name: string;
        phone?: string | null;
        postal_code?: string | null;
        state?: string | null;
        type: "residential" | "commercial";
      } | null;
      /** The alias used to identify the fulfillment center. */
      alias: string;
      /** Fulfillment Center ID */
      id: string;
      /** Creation timestamp */
      inserted_at: string;
      /** The human readable name used identify the fulfillment center. */
      name: string;
      /** The Shipium Origin ID used to identify the fulfillment center. */
      shipium_origin_id: string;
      /** Update timestamp */
      updated_at: string;
    } | null;
    /** Shipper */
    shipper?: {
      /** The enabled label providers for the shipper */
      enabled_label_providers: ("shipium" | "easy_post")[];
      /** The external reference for the shipper */
      external_reference: string | null;
      /** The shipper ID */
      id: string;
      /** Creation timestamp */
      inserted_at: string;
      /** The shipper name */
      name: string;
      /** Determines whether rating is enabled for the shipper */
      rating_enabled: boolean;
      /** Update timestamp */
      updated_at: string;
    } | null;
    /** Whether or not the parcel should be delivered on a Saturday. */
    saturday_delivery?: boolean | null;
    /** The weight of the parcel in pounds. */
    weight?: number;
    /** Custom text to print on the second optional position on the label */
    print_option_2?: string | null;
    /** The incoterm that will be used to ship the parcel. */
    incoterm?: ("DAP" | "DDP" | "DDU" | "DPU" | "FCA") | null;
    /** Custom text to print on the third optional position on the label */
    print_option_3?: string | null;
    /** The number of business days that the parcel will be in transit. */
    business_days_in_transit?: number | null;
    /** The billing account id of the parcel */
    billing_account_id?: string | null;
    voided_at?: string | null;
    desired_delivery_date?: string | null;
    /** Creation timestamp */
    inserted_at?: string;
    sub_parcels_count?: number;
    /** Update timestamp */
    updated_at?: string;
    /** Contains order related metadata coming from external systems */
    order_metadata?: {
      /** A carrier specific processing ID for the order */
      carrier_processing_id?: string | null;
    } | null;
    expected_delivery_at_date?: string | null;
    tracking_url?: string;
    /** The preferred label provider to use to generate the shipping label. */
    label_provider?: ("easy_post" | "shipium" | "usps_direct") | null;
    sub_parcels?: SubParcel[];
    /** The service method that will be used to ship the parcel. */
    service_method?: string | null;
    /** A list of tags to associate with the parcel */
    tags?: string[] | null;
    /** The external label id of the parcel */
    external_label_id?: string | null;
    tracking_number?: string;
    /** The address where the parcel will be delivered to. */
    to_address?: {
      city: string;
      company?: string | null;
      country: string;
      email?: string | null;
      external_location_id?: string | null;
      line1: string;
      line2?: string | null;
      line3?: string | null;
      name: string;
      phone?: string | null;
      postal_code?: string | null;
      state?: string | null;
      type: "residential" | "commercial";
    };
    /** The rate (cost of shipping the parcel) that will be used to generate the shipping label. */
    label_rate?: number;
    /** The ship option that will be used to ship the parcel. */
    carrier_ship_option?: string | null;
    documents?: DocumentRead[];
    /** The service method that will be used to ship the parcel if the first one fails. */
    fallback_service_method?: string | null;
    /** The address where the parcel will be picked up from. */
    from_address?: {
      city: string;
      company?: string | null;
      country: string;
      email?: string | null;
      external_location_id?: string | null;
      line1: string;
      line2?: string | null;
      line3?: string | null;
      name: string;
      phone?: string | null;
      postal_code?: string | null;
      state?: string | null;
      type: "residential" | "commercial";
    };
    /** The length of the parcel in inches. */
    length?: number;
    tracking_events?: TrackingEventRead[];
    /** The height of the parcel in inches. */
    height?: number;
    shipper_id?: string;
    /** The width of the parcel in inches. */
    width?: number;
    /** The preferred carrier that will be used to ship the parcel. */
    carrier_name?: string | null;
    /** Whether or not the parcel should be billed to a third party. */
    third_party_billing_enabled?: boolean | null;
    shipping_label?: string | null;
    /** The service method id of the generated label */
    label_service_method_id?: string | null;
    /** The preferred label format to use to generate the shipping label. */
    label_format?: string | null;
    /** The billable weight of the parcel from the TMS */
    tms_billable_weight?: string | null;
    /** The packaging material used to ship the parcel */
    packaging_material?:
      | (
          | "box"
          | "envelope"
          | "fedex_one_rate_envelope"
          | "fedex_one_rate_extra_large_box"
          | "fedex_one_rate_large_box"
          | "fedex_one_rate_medium_box"
          | "fedex_one_rate_pak"
          | "fedex_one_rate_small_box"
          | "fedex_one_rate_tube"
          | "flat_pack"
        )
      | null;
    /** ISO 4217 three-letter currency code. */
    currency_code?: string;
    expected_shipped_at_date?: string | null;
    /** Associates a parcel with a record on an external system (WMS) */
    external_id?: string | null;
    /** if null then the default signature option NO_SIGNATURE will be used. */
    signature_required?:
      | (
          | "signature"
          | "resident_signature"
          | "adult_signature"
          | "adult_resident_signature"
        )
      | null;
    /** Service Method */
    label_service_method?: {
      /** The carrier ID */
      carrier_id: string;
      /** The UI friendly name for the service method */
      display_name: string;
      /** The service method ID */
      id: string;
      inserted_at: string;
      /** The name of the service method */
      name: string;
      service_level_selection_strategy?:
        | ("priority" | "weight" | "incoterm")
        | null;
      service_levels: ServiceLevel[] | null;
      service_method_levels: ServiceMethodLevel[];
      updated_at: string;
    } | null;
    /** Whether or not the parcel is a return */
    is_return?: boolean | null;
  } | null;
  /** The status of the job */
  status: "completed" | "pending" | "failed";
};
export type TrackingLocation = {
  city?: string | null;
  country?: string | null;
  object: "TrackingLocation";
  state?: string | null;
  zip?: string | null;
} | null;
export type CarrierDetail = {
  alternate_identifier?: string | null;
  container_type?: string | null;
  destination_location?: string | null;
  destination_tracking_location?: TrackingLocation;
  est_delivery_date_local?: string | null;
  est_delivery_time_local?: string | null;
  guaranteed_delivery_date?: string | null;
  initial_delivery_attempt?: string | null;
  object?: "CarrierDetail";
  origin_location?: string | null;
  origin_tracking_location?: TrackingLocation;
  service?: string | null;
} | null;
export type Fee = {
  amount: number;
  charged: boolean;
  object: "Fee";
  refunded: boolean;
  type:
    | "LabelFee"
    | "CarbonOffsetFee"
    | "PostageFee"
    | "InsuranceFee"
    | "TrackerFee";
};
export type TrackingDetail = {
  carrier_code?: string | null;
  datetime: string;
  description?: string | null;
  message: string;
  object: "TrackingDetail";
  source: string;
  status: string;
  status_detail: string | null;
  tracking_location?: TrackingLocation;
};
export type Tracker = {
  carrier: string;
  carrier_detail?: CarrierDetail;
  created_at: string;
  est_delivery_date?: string | null;
  fees?: Fee[];
  finalized: boolean;
  id: string;
  is_return: boolean;
  mode: "test" | "production";
  object: "Tracker";
  public_url: string;
  shipment_id?: string | null;
  signed_by?: string | null;
  status: string;
  status_detail: string;
  tracking_code: string;
  tracking_details?: TrackingDetail[];
  updated_at: string;
  weight?: number | null;
};
export type WebhookEvent = {
  created_at: string;
  description: string | null;
  id: string;
  mode: string;
  object: string;
  result: Tracker;
  updated_at: string;
};
export type CreateAccessorialConfig = {
  /** Accessorial */
  accessorial?: {
    calculate_fuel: boolean;
    default_rate: number;
    description: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  };
  /** The accessorial ID */
  accessorial_id?: string;
  /** A factor that is applied to the carrier accessorial rate when `run_type` is `use_buy_rate`. The rate is multiplied by the factor to get the new rate */
  buy_rate_factor?: number | null;
  /** Carrier */
  carrier?: {
    /** The alternate names of the carrier */
    aliases: string[] | null;
    /** The UI friendly name for the carrier */
    display_name: string;
    /** The carrier ID */
    id: string;
    inserted_at: string;
    /** The name of the carrier */
    name: string;
    /** The tracking link template for the carrier */
    tracking_link_template: string | null;
    /** The tracking number lengths of the carrier */
    tracking_number_lengths: number[] | null;
    /** The tracking number prefixes of the carrier */
    tracking_number_prefixes: string[] | null;
    updated_at: string;
  } | null;
  /** The carrier ID */
  carrier_id?: string | null;
  /** The destination address type */
  destination_address_type?: ("residential" | "commercial") | null;
  fulfillment_center_id?: string | null;
  /** The maximum weight for which this accessorial config applies */
  max_weight: number;
  /** The minimum weight for which this accessorial config applies */
  min_weight: number;
  /** Determines how the sell accessorial rate should be calculated */
  run_type: "use_buy_rate" | "use_shipper_rate" | "ignore";
  /** Service Level */
  service_level?: {
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  /** The service level ID */
  service_level_id?: string | null;
  /** Service Method */
  service_method?: {
    /** The carrier ID */
    carrier_id: string;
    /** The UI friendly name for the service method */
    display_name: string;
    /** The service method ID */
    id: string;
    inserted_at: string;
    /** The name of the service method */
    name: string;
    service_level_selection_strategy?:
      | ("priority" | "weight" | "incoterm")
      | null;
    service_levels: ServiceLevel[] | null;
    service_method_levels: ServiceMethodLevel[];
    updated_at: string;
  } | null;
  /** The service method ID */
  service_method_id?: string | null;
  /** Shipper */
  shipper?: {
    /** The enabled label providers for the shipper */
    enabled_label_providers: ("shipium" | "easy_post")[];
    /** The external reference for the shipper */
    external_reference: string | null;
    /** The shipper ID */
    id: string;
    /** Creation timestamp */
    inserted_at: string;
    /** The shipper name */
    name: string;
    /** Determines whether rating is enabled for the shipper */
    rating_enabled: boolean;
    /** Update timestamp */
    updated_at: string;
  } | null;
  /** The shipper ID */
  shipper_id?: string | null;
};
export type CreateAccessorialConfigRead = {
  /** Accessorial */
  accessorial?: {
    calculate_fuel: boolean;
    default_rate: number;
    description: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  };
  /** The accessorial ID */
  accessorial_id?: string;
  /** A factor that is applied to the carrier accessorial rate when `run_type` is `use_buy_rate`. The rate is multiplied by the factor to get the new rate */
  buy_rate_factor?: number | null;
  /** Carrier */
  carrier?: {
    /** The alternate names of the carrier */
    aliases: string[] | null;
    /** The UI friendly name for the carrier */
    display_name: string;
    /** The carrier ID */
    id: string;
    inserted_at: string;
    /** The name of the carrier */
    name: string;
    /** The tracking link template for the carrier */
    tracking_link_template: string | null;
    /** The tracking number lengths of the carrier */
    tracking_number_lengths: number[] | null;
    /** The tracking number prefixes of the carrier */
    tracking_number_prefixes: string[] | null;
    updated_at: string;
  } | null;
  /** The carrier ID */
  carrier_id?: string | null;
  /** The destination address type */
  destination_address_type?: ("residential" | "commercial") | null;
  /** FulfillmentCenter */
  fulfillment_center?: {
    /** The address context used to identify the fulfillment center. */
    address: {
      city: string;
      company?: string | null;
      country: string;
      email?: string | null;
      external_location_id?: string | null;
      line1: string;
      line2?: string | null;
      line3?: string | null;
      name: string;
      phone?: string | null;
      postal_code?: string | null;
      state?: string | null;
      type: "residential" | "commercial";
    } | null;
    /** The alias used to identify the fulfillment center. */
    alias: string;
    /** Fulfillment Center ID */
    id: string;
    /** Creation timestamp */
    inserted_at: string;
    /** The human readable name used identify the fulfillment center. */
    name: string;
    /** The Shipium Origin ID used to identify the fulfillment center. */
    shipium_origin_id: string;
    /** Update timestamp */
    updated_at: string;
  } | null;
  fulfillment_center_id?: string | null;
  /** The maximum weight for which this accessorial config applies */
  max_weight: number;
  /** The minimum weight for which this accessorial config applies */
  min_weight: number;
  /** Determines how the sell accessorial rate should be calculated */
  run_type: "use_buy_rate" | "use_shipper_rate" | "ignore";
  /** Service Level */
  service_level?: {
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  /** The service level ID */
  service_level_id?: string | null;
  /** Service Method */
  service_method?: {
    /** The carrier ID */
    carrier_id: string;
    /** The UI friendly name for the service method */
    display_name: string;
    /** The service method ID */
    id: string;
    inserted_at: string;
    /** The name of the service method */
    name: string;
    service_level_selection_strategy?:
      | ("priority" | "weight" | "incoterm")
      | null;
    service_levels: ServiceLevel[] | null;
    service_method_levels: ServiceMethodLevel[];
    updated_at: string;
  } | null;
  /** The service method ID */
  service_method_id?: string | null;
  /** Shipper */
  shipper?: {
    /** The enabled label providers for the shipper */
    enabled_label_providers: ("shipium" | "easy_post")[];
    /** The external reference for the shipper */
    external_reference: string | null;
    /** The shipper ID */
    id: string;
    /** Creation timestamp */
    inserted_at: string;
    /** The shipper name */
    name: string;
    /** Determines whether rating is enabled for the shipper */
    rating_enabled: boolean;
    /** Update timestamp */
    updated_at: string;
  } | null;
  /** The shipper ID */
  shipper_id?: string | null;
};
export type FuelGroup = {
  /** Accessorial */
  accessorial: {
    calculate_fuel: boolean;
    default_rate: number;
    description: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  };
  /** The accessorial ID */
  accessorial_id: string;
  calculation_type: ("published" | "discount" | "static") | null;
  /** Carrier */
  carrier: {
    /** The alternate names of the carrier */
    aliases: string[] | null;
    /** The UI friendly name for the carrier */
    display_name: string;
    /** The carrier ID */
    id: string;
    inserted_at: string;
    /** The name of the carrier */
    name: string;
    /** The tracking link template for the carrier */
    tracking_link_template: string | null;
    /** The tracking number lengths of the carrier */
    tracking_number_lengths: number[] | null;
    /** The tracking number prefixes of the carrier */
    tracking_number_prefixes: string[] | null;
    updated_at: string;
  } | null;
  /** The carrier ID */
  carrier_id: string | null;
  destination_address_type: ("residential" | "commercial") | null;
  id: string;
  inserted_at: string;
  max_weight: number;
  min_weight: number;
  name: string;
  /** The type of rate */
  rate_type: "buy" | "sell";
  /** Service Level */
  service_level: {
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  /** The service level ID */
  service_level_id: string | null;
  /** Service Method */
  service_method: {
    /** The carrier ID */
    carrier_id: string;
    /** The UI friendly name for the service method */
    display_name: string;
    /** The service method ID */
    id: string;
    inserted_at: string;
    /** The name of the service method */
    name: string;
    service_level_selection_strategy?:
      | ("priority" | "weight" | "incoterm")
      | null;
    service_levels: ServiceLevel[] | null;
    service_method_levels: ServiceMethodLevel[];
    updated_at: string;
  } | null;
  /** The service method ID */
  service_method_id: string | null;
  /** Shipper */
  shipper: {
    /** The enabled label providers for the shipper */
    enabled_label_providers: ("shipium" | "easy_post")[];
    /** The external reference for the shipper */
    external_reference: string | null;
    /** The shipper ID */
    id: string;
    /** Creation timestamp */
    inserted_at: string;
    /** The shipper name */
    name: string;
    /** Determines whether rating is enabled for the shipper */
    rating_enabled: boolean;
    /** Update timestamp */
    updated_at: string;
  } | null;
  /** The shipper ID */
  shipper_id: string | null;
  updated_at: string;
};
export type UpdateFuelGroup = {
  /** The accessorial ID */
  accessorial_id?: string;
  calculation_type?: ("published" | "discount" | "static") | null;
  /** The carrier ID */
  carrier_id?: string | null;
  destination_address_type?: ("residential" | "commercial") | null;
  max_weight?: number;
  min_weight?: number;
  name?: string;
  /** The type of rate */
  rate_type?: "buy" | "sell";
  /** The service level ID */
  service_level_id?: string | null;
  /** The service method ID */
  service_method_id?: string | null;
  /** The shipper ID */
  shipper_id?: string | null;
};
export type CreateVendor = {
  display_name: string;
  name: string;
};
export type CreateExtendedAreaSurcharge = {
  /** The accessorial ID */
  accessorial_id: string;
  /** The effective or start date of the extended area surcharge */
  effective_at: string;
  /** The expiration or end date of the extended area surcharge */
  expires_at: string;
  /** The extended area level ID */
  extended_area_level_id?: string | null;
  /** The code that identifies the zone */
  zone_code?: string | null;
};
export type DetailedTrackingEvent = {
  carrierDescription?: string;
  city?: string | null;
  country?: string | null;
  eventDate: string;
  postalCode?: (string | null) | number;
  region?: string | null;
  shipmentStatus:
    | "Delivered"
    | "Exception"
    | "In Transit"
    | "Label Printed"
    | "Out For Delivery"
    | "Registered"
    | "Return To Sender: In Transit"
    | "Return To Sender: Out For Delivery"
    | "Return To Sender: Delivered"
    | "Return To Sender: Exception"
    | "Unknown";
};
export type Tracking = {
  carrierEstimatedDeliveryDate?: string | null;
  carrierId?: string;
  carrierServiceMethodId?: string | null;
  carrierTrackingId?: string;
  deliveredAtDateTime?: string | null;
  originalCarrierEstimatedDeliveryDate?: string | null;
  /** Shipium Tracking ID */
  shipiumTrackingId: string;
  shipmentStatus?:
    | "Delivered"
    | "Exception"
    | "In Transit"
    | "Label Printed"
    | "Out For Delivery"
    | "Registered"
    | "Return To Sender: In Transit"
    | "Return To Sender: Out For Delivery"
    | "Return To Sender: Delivered"
    | "Return To Sender: Exception"
    | "Unknown";
  shippedDateTime?: string | null;
  trackingEvents: DetailedTrackingEvent[];
};
export type ShipiumEvent = {
  /** Shipium metadata */
  metadata?: {
    /** UUID identifying this event for debugging/idempotency purposes */
    eventId?: string;
    eventTimestamp?: string;
    eventType?: "tracking_updated";
    payloadSchemaVersion?: "v1";
    testEvent?: boolean;
  };
  /** The actual event data associated with this event */
  payload: {
    trackings: Tracking[];
  };
};
export type ShipiumWebhookEvent = {
  events: ShipiumEvent[];
};
export type CreateDimensionalFactor = {
  /** Carrier */
  carrier?: {
    /** The alternate names of the carrier */
    aliases: string[] | null;
    /** The UI friendly name for the carrier */
    display_name: string;
    /** The carrier ID */
    id: string;
    inserted_at: string;
    /** The name of the carrier */
    name: string;
    /** The tracking link template for the carrier */
    tracking_link_template: string | null;
    /** The tracking number lengths of the carrier */
    tracking_number_lengths: number[] | null;
    /** The tracking number prefixes of the carrier */
    tracking_number_prefixes: string[] | null;
    updated_at: string;
  } | null;
  /** The carrier ID */
  carrier_id?: string | null;
  /** The effective or start date of the dimensional factor */
  effective_at: string;
  /** The expiration or end date of the dimensional factor */
  expires_at: string;
  factor: string | number;
  minimum_threshold?: (string | null) | (number | null);
  /** Service Method */
  service_method?: {
    /** The carrier ID */
    carrier_id: string;
    /** The UI friendly name for the service method */
    display_name: string;
    /** The service method ID */
    id: string;
    inserted_at: string;
    /** The name of the service method */
    name: string;
    service_level_selection_strategy?:
      | ("priority" | "weight" | "incoterm")
      | null;
    service_levels: ServiceLevel[] | null;
    service_method_levels: ServiceMethodLevel[];
    updated_at: string;
  } | null;
  /** The service method ID */
  service_method_id?: string | null;
  /** Shipper */
  shipper?: {
    /** The enabled label providers for the shipper */
    enabled_label_providers: ("shipium" | "easy_post")[];
    /** The external reference for the shipper */
    external_reference: string | null;
    /** The shipper ID */
    id: string;
    /** Creation timestamp */
    inserted_at: string;
    /** The shipper name */
    name: string;
    /** Determines whether rating is enabled for the shipper */
    rating_enabled: boolean;
    /** Update timestamp */
    updated_at: string;
  } | null;
  /** The shipper ID */
  shipper_id?: string | null;
};
export type UpdateCarrierAccessorial = {
  /** Accessorial */
  accessorial?: {
    calculate_fuel: boolean;
    default_rate: number;
    description: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  /** The accessorial ID */
  accessorial_id?: string | null;
  carrier?: Carrier;
  /** The carrier ID */
  carrier_id?: string;
  /** A description for the purpose of the carrier accessorial */
  description?: string | null;
  /** The carrier accessorial name */
  name?: string;
};
export type UpdateCarrier = {
  /** The alternate names of the carrier */
  aliases?: string[] | null;
  /** The UI friendly name for the carrier */
  display_name?: string;
  /** The name of the carrier */
  name?: string;
  /** The tracking link template for the carrier */
  tracking_link_template?: string | null;
  /** The tracking number lengths of the carrier */
  tracking_number_lengths?: number[] | null;
  /** The tracking number prefixes of the carrier */
  tracking_number_prefixes?: string[] | null;
};
export type ExtendedAreaLevel = {
  id: string;
  inserted_at: string;
  name: string;
  updated_at: string;
};
export type UpdateExtendedAreaLevel = {
  name?: string;
};
export type CreateRateCard = {
  /** The carrier ID */
  carrier_id?: string | null;
  /** The effective or start date of the rate card */
  effective_at: string;
  /** The expiration or end date of the rate card */
  expires_at: string;
  /** The fulfillment center ID */
  fulfillment_center_id?: string | null;
  /** The type of rate */
  rate_type: "buy" | "sell";
  /** The service level ID */
  service_level_id: string;
  /** The service method ID */
  service_method_id?: string | null;
};
export type UpdatePeakSurcharge = {
  /** The accessorial ID */
  accessorial_id?: string | null;
  /** The carrier ID */
  carrier_id?: string | null;
  /** The effective or start date of the peak surcharge */
  effective_at?: string;
  /** The expiration or end date of the peak surcharge */
  expires_at?: string;
  fulfillment_center_id?: string | null;
  /** The service level ID */
  service_level_id?: string | null;
  /** The service method ID */
  service_method_id?: string | null;
  /** The shipper ID */
  shipper_id?: string | null;
  /** The code that identifies the zone */
  zone_code?: string | null;
};
export type UpdateShipper = {
  /** The enabled label providers for the shipper */
  enabled_label_providers?: ("shipium" | "easy_post")[];
  /** The external reference for the shipper */
  external_reference?: string | null;
  /** The shipper name */
  name?: string;
  /** Determines whether rating is enabled for the shipper */
  rating_enabled?: boolean;
};
export type UpdateAccessorialConfig = {
  /** Accessorial */
  accessorial?: {
    calculate_fuel: boolean;
    default_rate: number;
    description: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  };
  /** The accessorial ID */
  accessorial_id?: string;
  /** A factor that is applied to the carrier accessorial rate when `run_type` is `use_buy_rate`. The rate is multiplied by the factor to get the new rate */
  buy_rate_factor?: number | null;
  /** Carrier */
  carrier?: {
    /** The alternate names of the carrier */
    aliases: string[] | null;
    /** The UI friendly name for the carrier */
    display_name: string;
    /** The carrier ID */
    id: string;
    inserted_at: string;
    /** The name of the carrier */
    name: string;
    /** The tracking link template for the carrier */
    tracking_link_template: string | null;
    /** The tracking number lengths of the carrier */
    tracking_number_lengths: number[] | null;
    /** The tracking number prefixes of the carrier */
    tracking_number_prefixes: string[] | null;
    updated_at: string;
  } | null;
  /** The carrier ID */
  carrier_id?: string | null;
  /** The destination address type */
  destination_address_type?: ("residential" | "commercial") | null;
  fulfillment_center_id?: string | null;
  /** The maximum weight for which this accessorial config applies */
  max_weight?: number;
  /** The minimum weight for which this accessorial config applies */
  min_weight?: number;
  /** Determines how the sell accessorial rate should be calculated */
  run_type?: "use_buy_rate" | "use_shipper_rate" | "ignore";
  /** Service Level */
  service_level?: {
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  /** The service level ID */
  service_level_id?: string | null;
  /** Service Method */
  service_method?: {
    /** The carrier ID */
    carrier_id: string;
    /** The UI friendly name for the service method */
    display_name: string;
    /** The service method ID */
    id: string;
    inserted_at: string;
    /** The name of the service method */
    name: string;
    service_level_selection_strategy?:
      | ("priority" | "weight" | "incoterm")
      | null;
    service_levels: ServiceLevel[] | null;
    service_method_levels: ServiceMethodLevel[];
    updated_at: string;
  } | null;
  /** The service method ID */
  service_method_id?: string | null;
  /** Shipper */
  shipper?: {
    /** The enabled label providers for the shipper */
    enabled_label_providers: ("shipium" | "easy_post")[];
    /** The external reference for the shipper */
    external_reference: string | null;
    /** The shipper ID */
    id: string;
    /** Creation timestamp */
    inserted_at: string;
    /** The shipper name */
    name: string;
    /** Determines whether rating is enabled for the shipper */
    rating_enabled: boolean;
    /** Update timestamp */
    updated_at: string;
  } | null;
  /** The shipper ID */
  shipper_id?: string | null;
};
export type UpdateAccessorialConfigRead = {
  /** Accessorial */
  accessorial?: {
    calculate_fuel: boolean;
    default_rate: number;
    description: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  };
  /** The accessorial ID */
  accessorial_id?: string;
  /** A factor that is applied to the carrier accessorial rate when `run_type` is `use_buy_rate`. The rate is multiplied by the factor to get the new rate */
  buy_rate_factor?: number | null;
  /** Carrier */
  carrier?: {
    /** The alternate names of the carrier */
    aliases: string[] | null;
    /** The UI friendly name for the carrier */
    display_name: string;
    /** The carrier ID */
    id: string;
    inserted_at: string;
    /** The name of the carrier */
    name: string;
    /** The tracking link template for the carrier */
    tracking_link_template: string | null;
    /** The tracking number lengths of the carrier */
    tracking_number_lengths: number[] | null;
    /** The tracking number prefixes of the carrier */
    tracking_number_prefixes: string[] | null;
    updated_at: string;
  } | null;
  /** The carrier ID */
  carrier_id?: string | null;
  /** The destination address type */
  destination_address_type?: ("residential" | "commercial") | null;
  /** FulfillmentCenter */
  fulfillment_center?: {
    /** The address context used to identify the fulfillment center. */
    address: {
      city: string;
      company?: string | null;
      country: string;
      email?: string | null;
      external_location_id?: string | null;
      line1: string;
      line2?: string | null;
      line3?: string | null;
      name: string;
      phone?: string | null;
      postal_code?: string | null;
      state?: string | null;
      type: "residential" | "commercial";
    } | null;
    /** The alias used to identify the fulfillment center. */
    alias: string;
    /** Fulfillment Center ID */
    id: string;
    /** Creation timestamp */
    inserted_at: string;
    /** The human readable name used identify the fulfillment center. */
    name: string;
    /** The Shipium Origin ID used to identify the fulfillment center. */
    shipium_origin_id: string;
    /** Update timestamp */
    updated_at: string;
  } | null;
  fulfillment_center_id?: string | null;
  /** The maximum weight for which this accessorial config applies */
  max_weight?: number;
  /** The minimum weight for which this accessorial config applies */
  min_weight?: number;
  /** Determines how the sell accessorial rate should be calculated */
  run_type?: "use_buy_rate" | "use_shipper_rate" | "ignore";
  /** Service Level */
  service_level?: {
    display_name: string;
    id: string;
    inserted_at: string;
    name: string;
    updated_at: string;
  } | null;
  /** The service level ID */
  service_level_id?: string | null;
  /** Service Method */
  service_method?: {
    /** The carrier ID */
    carrier_id: string;
    /** The UI friendly name for the service method */
    display_name: string;
    /** The service method ID */
    id: string;
    inserted_at: string;
    /** The name of the service method */
    name: string;
    service_level_selection_strategy?:
      | ("priority" | "weight" | "incoterm")
      | null;
    service_levels: ServiceLevel[] | null;
    service_method_levels: ServiceMethodLevel[];
    updated_at: string;
  } | null;
  /** The service method ID */
  service_method_id?: string | null;
  /** Shipper */
  shipper?: {
    /** The enabled label providers for the shipper */
    enabled_label_providers: ("shipium" | "easy_post")[];
    /** The external reference for the shipper */
    external_reference: string | null;
    /** The shipper ID */
    id: string;
    /** Creation timestamp */
    inserted_at: string;
    /** The shipper name */
    name: string;
    /** Determines whether rating is enabled for the shipper */
    rating_enabled: boolean;
    /** Update timestamp */
    updated_at: string;
  } | null;
  /** The shipper ID */
  shipper_id?: string | null;
};
export type CreateOversizedSurcharge = {
  /** The accessorial ID */
  accessorial_id: string | null;
  /** The carrier ID */
  carrier_id?: string | null;
  /** The effective or start date of the oversized surcharge */
  effective_at: string;
  /** The expiration or end date of the oversized surcharge */
  expires_at: string;
  /** The maximum value of the oversized surcharge type */
  max_value: number;
  /** The minimum value of the oversized surcharge type */
  min_value: number;
  /** The oversized surcharge type */
  oversized_type: "weight" | "cubic_volume" | "longest_dim" | "girth";
  /** The service level ID */
  service_level_id?: string | null;
  /** The shipper ID */
  shipper_id?: string | null;
  /** The code that identifies the zone */
  zone_code?: string | null;
};
export type UpdateServiceLevel = {
  display_name?: string;
  name?: string;
};
export type CreateExtendedAreaLevel = {
  name: string;
};
export type CreateServiceLevel = {
  display_name: string;
  name: string;
};
export type CreateFuelGroup = {
  /** The accessorial ID */
  accessorial_id: string;
  calculation_type?: ("published" | "discount" | "static") | null;
  /** The carrier ID */
  carrier_id?: string | null;
  destination_address_type?: ("residential" | "commercial") | null;
  max_weight: number;
  min_weight: number;
  name: string;
  /** The type of rate */
  rate_type: "buy" | "sell";
  /** The service level ID */
  service_level_id?: string | null;
  /** The service method ID */
  service_method_id?: string | null;
  /** The shipper ID */
  shipper_id?: string | null;
};
export type DeliveryEstimate = {
  alias: string;
  /** Postal code of the destination */
  destination_country_code: string;
  /** Postal code of the destination */
  destination_postal_code: string;
  /** Time in transit */
  estimated_days_in_transit: number;
  /** Estimated delivery date */
  estimated_delivery_date: string;
  /** The date the shipment is expected to be shipped */
  expected_ship_date: string;
  /** Creation timestamp */
  inserted_at?: string;
  /** Date override for the request */
  request_date_override?: string;
  /** The service level of the shipment */
  ship_option?: "standard";
  /** Update timestamp */
  updated_at?: string;
};
export type RequestDeliveryEstimate = {
  alias: string;
  /** Postal code of the destination */
  destination_country_code: string;
  /** Postal code of the destination */
  destination_postal_code: string;
  /** The date the shipment is expected to be shipped */
  expected_ship_date: string;
  /** Date override for the request */
  request_date_override?: string;
  /** The service level of the shipment */
  ship_option?: "standard";
};
