import { useEffect, type ReactNode } from "react";
import type { HelmetProps } from "react-helmet-async";
import { Helmet } from "react-helmet-async";

/**
 * This is just a wrapper around react-helmet-async's Helmet component that is primarily useful
 * for setting the title of the page.
 */
export const SEO = (props: HelmetProps & { children?: ReactNode }) => {
  // We use an effect here in case the title includes async components
  // An example of this is when title={`${carrierQuery.data?.data.some_property} and more of the title`}
  useEffect(() => {
    if (props.title) {
      document.title = props.title;
    }
  }, [props]);

  return <Helmet {...props} />;
};
